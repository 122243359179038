const Layout = () => import("@/layouts/menu/Main");
const RecruitmentMain = () => import("@/views/recruitment/listRecruitment");

export const routes = {
  path: "/",
  name: "recruitment-route",
  component: Layout,
  children: [
    {
      name: "recruitment",
      path: "recruitment",
      component: RecruitmentMain,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Recruitment",
      },
    },
  ],
};
