import { createStore } from "vuex";
import main from "./main";
import feederpddikti from "./feederpddikti";
import sisterpddikti from "./sisterpddikti";
import sideMenuMdp from "./side-menu-mdp";
import auth from "./auth";
import userdata from "./userdata";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import LockScreenStore from "@/store/LockScreenStore";
// import mainMenus from "@/utils/mainmenus";
const ls = new SecureLS({ isCompression: false });

const store = createStore({
  modules: {
    auth,
    userdata,
    main,
    feederpddikti,
    sisterpddikti,
    sideMenuMdp,
    // mainMenus,
    LockScreenStore,
  },
  plugins: [
    createPersistedState({
      // paths: ["auth", "userdata", "main", "feederpddikti", "sisterpddikti"],
      paths: ["auth", "userdata", "main", "sisterpddikti", "LockScreenStore"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export function useStore() {
  return store;
}

export default store;
