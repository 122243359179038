<template>
  <div>
    <MobileMenu />
    <!-- BEGIN: Top Bar -->
    <div
      class="border-b border-theme-29 pb-4 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10"
    >
      <div class="top-bar-boxed flex items-center pt-3">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'dashboard' }"
          tag="a"
          class="intro-x hidden md:flex items-center pl-5"
        >
          <img
            alt="Logo"
            class="w-24"
            width="320"
            src="https://api.mdp.ac.id/public/assets/logo/logo-umdp-light.png"
          />
        </router-link>
        <BreadCrumb v-if="slideOverOpen === false" light="light" />
        <DarkSwitcher :light="'light'" />
        <!--        <SearchBar v-if="slideOverOpen === false" light="light" />-->

        <Notification light="light" />
        <AccountMenu light="light" />
      </div>
    </div>
    <!-- END: Top Bar -->
    <!-- BEGIN: Top Menu -->
    <nav class="top-nav">
      <ul>
        <!-- BEGIN: First Child -->
        <li v-for="(menu, menuKey) in formattedMenu" :key="menuKey">
          <a
            href="javascript:"
            class="top-menu"
            :class="{
              'top-menu--active': menu.active,
            }"
            @click="linkTo(menu, router)"
          >
            <div class="top-menu__icon">
              <component :is="menu.icon" />
            </div>
            <div class="top-menu__title">
              <div class="w-auto whitespace-normal">
                {{ menu.title }}
              </div>
              <ChevronDownIcon v-if="menu.subMenu" class="top-menu__sub-icon" />
            </div>
          </a>
          <!-- BEGIN: Second Child -->
          <ul v-if="menu.subMenu">
            <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
              <a
                v-if="showSubMenu(subMenu)"
                :href="getLink(subMenu, router)"
                class="top-menu"
                @click="linkTo(subMenu, router)"
              >
                <div class="top-menu__icon">
                  <component
                    :is="
                      subMenu.icon?.length > 0 ? subMenu.icon : 'ActivityIcon'
                    "
                  />
                </div>
                <div class="top-menu__title">
                  <div class="whitespace-normal">
                    {{ subMenu.title }}
                  </div>
                  <ChevronDownIcon
                    v-if="subMenu.subMenu"
                    class="top-menu__sub-icon"
                  />
                </div>
              </a>
              <!-- BEGIN: Third Child -->
              <ul v-if="subMenu.subMenu">
                <li
                  v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                  :key="lastSubMenuKey"
                >
                  <a
                    v-if="showLastSubMenu(lastSubMenu)"
                    :href="getLink(lastSubMenu, router)"
                    class="top-menu"
                    @click="linkTo(lastSubMenu, router)"
                  >
                    <div class="top-menu__icon">
                      <component
                        :is="
                          lastSubMenu.icon?.length > 0
                            ? lastSubMenu.icon
                            : 'zap-icon'
                        "
                      />
                    </div>
                    <div class="top-menu__title">
                      {{ lastSubMenu.title }}
                    </div>
                  </a>
                </li>
              </ul>
              <!-- END: Third Child -->
            </li>
          </ul>
          <!-- END: Second Child -->
        </li>
        <!-- END: First Child -->
      </ul>
    </nav>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->
    <div class="content">
      <router-view />
    </div>
    <!-- END: Content -->
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import MobileMenu from "@/components/mobile-menu-mdp/Main.vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import {
  searchDropdown,
  showSearchDropdown,
  hideSearchDropdown,
} from "./index";
import { nestedMenu, linkTo, getLink } from "@/layouts/side-menu-mdp";
import { mapActions } from "vuex";
import TopBarApp from "../../components/top-bar/TopBarApp";
import BreadCrumb from "../../components/top-bar/BreadCrumb";
// import SearchBar from "../../components/top-bar/SearchBar";
import Notification from "../../components/top-bar/Notification";
import AccountMenu from "../../components/top-bar/AccountMenu";
import DarkSwitcher from "@/components/dark-mode-switcher/DarkSwitcher";

export default defineComponent({
  components: {
    DarkSwitcher,
    AccountMenu,
    Notification,
    // SearchBar,
    BreadCrumb,
    TopBarApp,
    MobileMenu,
    DarkModeSwitcher,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const topMenu = computed(() =>
      nestedMenu(store.getters["sideMenuMdp/menu"], route)
    );
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(topMenu.value);
      }
    );

    onMounted(() => {
      cash("html").removeClass("bg-white");
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .removeClass("login2")
        .addClass("main");
      formattedMenu.value = $h.toRaw(topMenu.value);
    });

    return {
      store,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      formattedMenu,
      router,
      linkTo,
      getLink,
    };
  },
  data() {
    return {
      app_title: this.app_title,
    };
  },
  computed: {
    currentFakultas() {
      return this.$store.state.main.currentFakultas;
    },
    currentInstitusi() {
      return this.$store.state.main.currentInstitusi;
    },
    slideOverOpen() {
      return this.$store.state.main.slideOverOpen;
    },
  },
  methods: {
    ...mapActions({
      LogOut: "auth/LogOut",
    }),
    logOut: function () {
      cash("#dropdown-menu").dropdown("hide");
      this.LogOut();
    },
    showSubMenu: function (menu) {
      if (this.$store.getters["auth/isSuperAdmin"]) return true;

      const u_abs = this.$store.getters["auth/abilities"];
      if (u_abs == null) return false;
      let abl = u_abs.map((a) => a.split(":", 1)[0]);

      let finalabl = abl.filter(function (item, pos) {
        return abl.indexOf(item) == pos;
      });

      if (menu?.ability && Array.isArray(menu?.ability)) {
        let mn = menu?.ability.filter((m) => {
          return finalabl.includes(m);
        });
        return mn.length > 0;
      } else if (menu?.ability !== undefined) {
        return finalabl.includes(menu?.ability);
      }
      return false;
    },
    showLastSubMenu: function (menu) {
      if (this.$store.getters["auth/isSuperAdmin"]) return true;

      const u_abs = this.$store.getters["auth/abilities"];
      if (u_abs == null) return false;
      let abl = u_abs.map((a) => a.split(":", 1)[0]);

      let finalabl = abl.filter(function (item, pos) {
        return abl.indexOf(item) == pos;
      });

      if (menu?.ability && Array.isArray(menu?.ability)) {
        let mn = menu?.ability.filter((m) => {
          return finalabl.includes(m);
        });
        return mn.length > 0;
      } else if (menu?.ability !== undefined) {
        return finalabl.includes(menu?.ability);
      }
      return false;
    },
  },
});
</script>
