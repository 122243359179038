<template>
  <div class="intro-y box mt-5">
    <div class="p-5">
      <div class="text-lg font-medium">
        <user-check-icon class="mr-2 text-blue-700" /> Masa Kerja
      </div>
      <div class="mx-auto mt-3 w-52 border-t border-dashed sm:w-auto">
        <div v-if="isLoading" class="flex text-center mt-4">
          Loading Data...
        </div>
        <div v-else-if="listmasakerja.length > 0">
          <div
            v-for="(item, index) in listmasakerja"
            :key="index"
            class="flex items-center mt-5"
          >
            <span class="truncate font-medium">
              <div
                class="cursor-pointer hover:underline"
                @click="detailSDM(item.KODE, item.tenaga_pendidik)"
              >
                {{ item.nm }}
              </div>
              <smal class="text-gray-500 text-xs mt-0.5 font-normal"
                >Mulai Berkerja: {{ $h.tanggal(item.tgl_mulai_kerja) }}</smal
              ></span
            >
            <span class="ml-auto text-gray-500"
              ><strong class="font-medium text-primary-1 dark:text-white"
                >{{ item._year }} Tahun</strong
              >
              {{ item._month }} Bulan</span
            >
          </div>
        </div>
        <div v-else class="flex text-center mt-4 text-gray-400">
          No result found!
        </div>
      </div>
    </div>
  </div>
  <ActionPaginate
    :current-page="page"
    :paginate-detail="paginateDetail"
    @changeSize="setSize"
    @changePage="processPaginate"
  />
</template>

<script>
import ActionPaginate from "@/views/dashboard-mdp/component/ActionPaginate.vue";
import { sdm } from "@/services/models";
import router from "@/router";

export default {
  name: "MasaKerja",
  components: {
    ActionPaginate,
  },
  data() {
    return {
      listmasakerja: [],
      isLoading: false,
      paginateDetail: null,
      page: 1,
      size: 5,
      queryPath: null,
    };
  },
  computed: {},
  mounted() {
    // this.getMasakerja();
    this.fetchMasaKerja();
  },
  methods: {
    detailSDM(kode, jns_sdm) {
      if (jns_sdm == 1 || jns_sdm == 2) {
        router.push({
          name: "view-dosen",
          params: { id: kode },
        });
      } else if (jns_sdm == 3) {
        router.push({
          name: "view-tendik",
          params: { id: kode },
        });
      }
    },
    fetchMasaKerja() {
      this.isLoading = true;
      this.listMahasiswa = [];
      const path = this.queryPath
        ? `masakerja?page=${this.page}&size=${this.size}&${this.queryPath}`
        : `masakerja?page=${this.page}&size=${this.size}`;
      sdm.Rekrutmen.getCustomPath(path)
        .then((res) => {
          if (res.status === 200) {
            const { data, ...paginate } = res.data;
            this.listmasakerja = data;
            this.paginateDetail = paginate;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setSize(size) {
      this.size = size;
      this.fetchMasaKerja();
    },
    processPaginate(page) {
      this.page = page;
      this.fetchMasaKerja();
    },
  },
};
</script>
