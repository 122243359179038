<template>
  <div>
    <Tippy
      :id="tabNavId"
      tag="a"
      :content="content"
      data-toggle="tab"
      :data-target="'#' + tabNavId + '-tab'"
      href="javascript:;"
      class="w-full sm:w-48 py-4 text-center flex justify-center items-center"
      :class="activeTab"
      role="tab"
      :aria-controls="tabLabel"
      aria-selected="true"
    >
      <main>
        <slot></slot>
      </main>
    </Tippy>
  </div>
</template>

<script>
export default {
  name: "TippyTabNav",
  props: {
    tabNavId: {
      default: "",
      type: String,
    },
    tabLabel: {
      default: "",
      type: String,
    },
    content: {
      default: "",
      type: String,
    },
    activeTab: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped></style>
