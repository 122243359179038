<template>
  <MainLayout :side-title="'.'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Jabatan Fungsi (JJA)</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="ml-3 mr-1" @click="getItems()">
            <RefreshCcwIcon class="w-4 mr-2" /> Reload data
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form
            id="tabulator-html-filter-form"
            class="xl:flex sm:mr-auto"
            @submit.prevent="onFilter"
          >
            <div class="sm:flex items-center sm:mr-4">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field</label
              >
              <select
                id="tabulator-html-filter-field"
                v-model="filter.field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="sk_jabfung">No. SK</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Value</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="filter.value"
                type="text"
                class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-16"
                @click="onFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </form>
          <div class="flex mt-5 sm:mt-0">
            <a
              id="tabulator-export-xlsx"
              href="javascript:"
              class="btn btn-outline-secondary w-full sm:w-auto flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              @click="onExportXlsx"
            >
              <FileTextIcon class="w-4 h-4 mr-2" />
              {{ !downloading ? "Export XLSX" : "Downloading..." }}
            </a>
          </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <SlideOver :open="panel" @close="closePanel">
      <template #title>Jabatan Fungsional (JJA)</template>
      <div class="p-3 border-t border-gray-200 dark:border-dark-5"></div>
      <div class="p-3">
        <div v-if="!detailJabfung">Loading data...</div>
        <div v-else class="flex flex-col space-y-5">
          <div class="form-group">
            <label for="nama" class="form-label uppercase font-semibold"
              >Nama</label
            ><input
              v-model="detailJabfung.sdm.dosen.NM_DOSEN"
              type="text"
              class="form-control"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="nama" class="form-label uppercase font-semibold"
              >NIK</label
            ><input
              v-model="detailJabfung.sdm.dosen.KODE"
              type="text"
              class="form-control"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="nama" class="form-label uppercase font-semibold"
              >Jabatan Fungsional</label
            >
            <TailSelect
              v-model="detailJabfung.id_jabfung"
              :options="{
                search: false,
                classNames: 'form-control',
                deselect: false,
              }"
            >
              <option
                v-for="list in ref_jabfung"
                :key="list.value"
                :value="list.value"
                :selected="list.selected"
                :data-kredit="list.value2"
              >
                {{ list.text }}
              </option>
            </TailSelect>
          </div>
          <div class="form-group">
            <label for="nama" class="form-label uppercase font-semibold"
              >Nomor SK Jabfung</label
            ><input
              v-model="detailJabfung.sk_jabfung"
              type="text"
              class="form-control"
              placeholder="Nomor SK Jabfung"
            />
          </div>
          <div class="form-group">
            <label class="form-label uppercase font-semibold"
              >Terhitung Mulai Tanggal
              <span class="text-red-900">*</span></label
            >
            <input
              v-model="detailJabfung.tmt_sk_jabfung"
              type="date"
              class="form-control focus:ring-0 mt-2"
              autocomplete="off"
            />
          </div>
          <div class="form-group">
            <label for="nama" class="form-label uppercase font-semibold"
              >Angka Kredit</label
            ><input
              v-model="detailJabfung.jabfung.angka_kredit"
              type="text"
              class="form-control"
              placeholder="Angka Kredit"
            />
          </div>
          <div class="form-group">
            <div
              v-for="(items, index) in detailJabfung.sdm_dokumen"
              :key="index"
            >
              <div class="box px-5 py-3 flex-1">
                <div class="flex items-center">
                  <div class="file">
                    <div href="" class="w-12 file__icon file__icon--file">
                      <div class="file__icon__file-name text-xs">
                        {{ items.jenis_file ? items.jenis_file : "File" }}
                      </div>
                    </div>
                  </div>
                  <div class="ml-4">
                    <div class="font-medium">
                      <a
                        class="flex items-center cursor-pointer hover:text-blue-400 mt-4"
                        :class="
                          downloading[items.id]
                            ? 'text-gray-600'
                            : 'text-blue-600'
                        "
                        target="_blank"
                        :href="
                          download_url.slice(0, download_url.length - 4) +
                          'storage/sdm/jabfung/' +
                          items.nama_file
                        "
                        download
                      >
                        <download-icon class="w-4 h-4 mr-2"></download-icon>
                        {{
                          downloading[items.id]
                            ? downloading[items.id]
                            : items.nama_file
                        }}
                      </a>
                    </div>
                    <div class="text-gray-600 text-xs mt-0.5">
                      {{ items.keterangan }}
                    </div>
                    <div class="text-gray-400 text-xs mt-0.5">
                      Updated:
                      {{
                        $h.tanggal(
                          items.tanggal_upload,
                          "DD MMMM YYYY hh:mm:ss"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-4">
            <button
              class="btn btn-primary w-52"
              :disabled="isLoading"
              @click="submit"
            >
              {{ isLoading ? "Loading..." : "Update" }}
            </button>
          </div>
        </div>
      </div>
    </SlideOver>
    <!-- BEGIN: Modal Content -->
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="deleteRow"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <template #rightside>
      <div class="grid grid-cols-12 gap-2 mt-3">
        <div
          class="col-span-4 md:col-span-12 bg-white dark:bg-gray-800 rounded-md p-5"
        ></div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import feather from "feather-icons";
import { useStore } from "vuex";
import { tblTabulator } from "@/utils/tabulator";
import { ref as refmodel, sdm } from "@/services/models";
import MainLayout from "@/layouts/main/MainLayout.vue";
import { helper } from "@/utils/helper";
import SlideOver from "@/layouts/main/SlideOver.vue";
import router from "@/router";

export default defineComponent({
  components: {
    MainLayout,
    SlideOver,
  },
  setup() {
    let deletingRow = ref();
    let panel = ref();
    let detailJabfung = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "sk_jabfung",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    // const editRow = (e, cell) => {
    //   sdm.JabfungSdm.getCustomPath(
    //     `getDetailJabfung/${cell.getData().id_rwy_jabfung}`
    //   ).then((res) => {
    //     if (res.status === 200) {
    //       if (res.data.code === 200) {
    //         detailJabfung.value = res.data.data;
    //       }
    //     }
    //   });
    //
    //   panel.value = true;
    //
    //   // console.log(detailJabfung);
    // };
    const editRow = (e, cell) => {
      idData.value = cell.getData().id_rwy_jabfung;
      router.push({
        name: "edit-data-jja",
        params: { id: idData.value },
      });
    };
    const deleteRow = (e, cell) => {
      //e - the click event object
      cash("#delete-modal").modal("show");
      deletingRow.value = cell.getRow();
      idData.value = cell.getData().id_rwy_jabfung;
    };
    const initTabulator = () => {
      let columns = [
        {
          title: "NIK",
          headerHozAlign: "left",
          headerSort: false,
          vertAlign: "middle",
          width: 80,
          formatter(cell) {
            return cell.getData().sdm.dosen
              ? cell.getData().sdm.dosen.KODE
              : cell.getData().sdm.tendik.KODE;
          },
        },
        {
          title: "Nama",
          headerHozAlign: "left",
          headerSort: false,
          vertAlign: "middle",
          formatter(cell) {
            return cell.getData().sdm.dosen
              ? cell.getData().sdm.dosen.NM_DOSEN
              : cell.getData().sdm.tendik.NM_TENDIK;
          },
        },
        {
          title: "Jabatan Fungsional",
          field: "jabfung.nm_jabfung",
          headerHozAlign: "left",
          vertAlign: "middle",
          formatter(cell) {
            return (
              cell.getData().jabfung.nm_jabfung +
              " (" +
              cell.getData().jabfung.angka_kredit +
              ")"
            );
          },
        },
        {
          title: "Nomor SK",
          responsive: true,
          field: "sk_jabfung",
          headerHozAlign: "left",
          vertAlign: "middle",
        },
        {
          title: "Terhitung Mulai Tanggal",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return helper.tanggal(cell.getData().tmt_sk_jabfung);
          },
        },
        {
          title: "Asal Data",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let a = "";
            if (cell.getData().id) {
              a = cash(
                `<div>
                <div class="text-blue-600 text-xs whitespace-normal">Sister</div>
                <span class="text-gray-500 text-xs whitespace-normal mt-2">Data terhubung ke SISTER PDDIKTI</span>
                </span>`
              );
            } else {
              a = cash(
                `<div class="text-blue-600 text-xs whitespace-normal">Internal</div>`
              );
            }

            return a[0];
          },
        },
        {
          title: "Status Ajuan",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            let a = "";
            if (cell.getData().status_ajuan == 0) {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-6 text-white cursor-pointer font-medium">Diajukan</div>`
              );
            } else if (cell.getData().status_ajuan == 1) {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-4 text-white cursor-pointer font-medium">Disetujui</div>`
              );
            } else {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-3 text-white cursor-pointer font-medium">Ditolak</div>`
              );
            }

            return a[0];
          },
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          // print: false,
          download: false,
          headerSort: false,
          width: 65,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-success w-18 items-center" href="javascript:;">
                  <i data-feather="archive" class="w-4 h-4 mr-1"></i> Open
                </a>
              </div>`);
            return a[0];
          },
          cellClick: editRow,
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          // print: false,
          download: false,
          headerSort: false,
          width: 40,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center text-theme-6" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
              </div>`);
            return a[0];
          },
          cellClick: deleteRow,
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        sdm.JabfungSdm.endpoint,
        columns
      );
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "sk_jabfung";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Export
    var downloading = ref(0);
    const onExportXlsx = () => {
      // downloading.value = 1;
      //
      // let postData = {
      //   // id_rekrutmen: this.dataRekrutmenref.id_rekrutmen,
      //   jenis_surat: "",
      //   upah_masa_percobaan: "",
      // };
      // sdm.Tendik.postCustomPathBlob("export_tendik", postData)
      //   .then((res) => {
      //     let blobFile = new Blob([res.data], {
      //       type: res.headers["content-type"],
      //     });
      //     var fileURL = URL.createObjectURL(blobFile);
      //     var fileLink = document.createElement("a");
      //
      //     fileLink.href = fileURL;
      //     fileLink.setAttribute("download", `Tendik.xlsx`);
      //     document.body.appendChild(fileLink);
      //
      //     fileLink.click();
      //   })
      //   .finally(() => {
      //     downloading.value = 0;
      //   });
    };

    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });

    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      // onExportCsv,
      // onExportJson,
      downloading,
      onExportXlsx,
      // onExportHtml,
      // onPrint,
      accessToken,
      deletingRow,
      idData,
      detailJabfung,
      panel,
    };
  },
  data() {
    return {
      download_url: process.env.VUE_APP_API,
      deleting: false,
      editing: false,
      isLoading: false,
      ref_jabfung: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
  },
  mounted() {
    this.getJabfung();
  },
  methods: {
    resetFilter() {
      this.filterData?.prodi.data.splice(0);
    },
    getItems() {
      this.deleting = false;
      this.editing = false;
      this.tabulator.setData(
        sdm.JabfungSdm.endpoint,
        "",
        tblTabulator.ajaxConfig()
      );
    },
    deleteRow() {
      sdm.JabfungSdm.deleteData(this.idData).then((res) => {
        if (res.status === 200) {
          this.getItems();
          cash("#delete-modal").modal("hide");
        }
      });
    },
    closePanel(close) {
      this.panel = close;
    },
    async getJabfung() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Jabatan Fungsional",
        selected: true,
      };
      this.ref_jabfung.push(defaultOpt);
      refmodel.jabfung.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_jabfung.push({
              id: element.id_jabfung,
              value: element.id_jabfung,
              value2: element.angka_kredit,
              text: element.nm_jabfung + " (" + element.angka_kredit + ")",
              selected: false,
            });
          });
        }
      });
    },
    submit() {
      this.isLoading = true;
      // this.messageAlert = [];

      // console.log(fd);
      let formdata = {
        ...this.detailJabfung,
      };

      if (this.detailJabfung.id_rwy_jabfung?.length > 0) {
        sdm.JabfungSdm.postCustomPath(
          "update_jabfung/" + this.detailJabfung.id_rwy_jabfung,
          formdata
        ).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.alertSuccess = true;
              this.messageAlert = res.data.message;
            } else {
              Object.keys(res.data.message).forEach((key) => {
                this.messageAlert.push(res.data.message[key][0]);
              });
            }
          }

          this.isLoading = false;
        });
      }

      this.closePanel();
      this.getItems();
    },
  },
});
</script>
