<template>
  <div class="intro-y box px-5 pt-5 mt-5">
    <div
      class="flex flex-col sm:flex-row items-center pb-5 border-b border-gray-200 dark:border-dark-5"
    >
      <h2 class="font-medium text-base mr-auto text-2xl">Informasi Penting</h2>
    </div>
    <div class="pt-5 pb-5">
      <div v-if="pengumuman.length > 0">
        <div
          v-for="(item, index) in pengumuman"
          :key="index"
          class="flex flex-col items-center"
        >
          <div
            class="w-full border border-gray-200 dark:border-dark-5 rounded-md p-5 my-2"
          >
            <div class="text-center lg:text-left">
              <h3 class="text-xl font-medium leading-none my-3">
                {{ item.title }}
              </h3>
              <div>{{ item.content }}</div>
              <div
                class="flex items-center justify-center lg:justify-start text-gray-600 mt-5"
              >
                <CalendarIcon class="w-4 h-4 mr-1"></CalendarIcon>
                {{ $h.tanggal(item.created_at) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionPaginate
        :current-page="page"
        :paginate-detail="paginate"
        @changeSize="setSize"
        @changePage="processPaginate"
      />
    </div>
  </div>
</template>

<script>
import { sdm } from "@/services/models";
import ActionPaginate from "@/views/dashboard-mdp/component/ActionPaginate.vue";

export default {
  name: "Pengumuman",
  components: {
    ActionPaginate,
  },
  data() {
    return {
      isLoading: true,
      pengumuman: [],
      paginate: null,
      page: 1,
      size: 5,
      queryPath: null,
    };
  },
  mounted() {
    this.getPengumuman();
  },
  methods: {
    async getPengumuman() {
      this.isLoading = true;
      const path = this.queryPath
        ? `all?page=${this.page}&size=${this.size}&${this.queryPath}`
        : `all?page=${this.page}&size=${this.size}`;

      sdm.Pengumuman.getCustomPath(path)
        .then((res) => {
          if (res.status === 200) {
            const { data, ...paginate } = res.data;
            this.pengumuman = data;
            this.paginate = paginate;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setSize(size) {
      this.size = size;
      this.getPengumuman();
    },
    processPaginate(page) {
      this.page = page;
      this.getPengumuman();
    },
  },
};
</script>
