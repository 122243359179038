import { akademik } from "./akademik";
import { dosen } from "./dosen";
import { tendik } from "./tendik";
import { sdmmdp } from "./sdmmdp";
import { sdm } from "./sdm";
import { master } from "./master";
import { ref } from "./ref";
import crud from "../../store/crud";
import store from "../../store";
//register you models here ...
//...
//and export her...

const moduleList = {
  akademik: akademik,
  dosen: dosen,
  tendik: tendik,
  SdmMdp: sdmmdp,
  sdm: sdm,
  master: master,
  ref: ref,
};

const dinamicService = (store, model) => {
  for (const [key, value] of Object.entries(model)) {
    store.registerModule(
      key,
      crud({
        service: value,
      })
    );
  }
};

for (const property in moduleList) {
  dinamicService(store, moduleList[property]);
}

export { dinamicService, akademik, sdm, dosen, tendik, master, ref };
