import Layout from "@/layouts/menu/Main";
import DataPribadi from "@/views/data-pribadi/datapribadi";
import Inpassing from "@/views/inpassing/listInpassing";
import Jabfung from "@/views/jabfung/listJabfung";
import Diklat from "@/views/diklat/listDiklatStaff";
import Rekognisi from "@/views/rekognisi/listRekognisiStaff";
import TemplateSurat from "@/views/arsip/template-surat/listTemplateSuratStaff";

export const routes = {
  path: "/menu",
  name: "menu-route",
  component: Layout,
  children: [
    {
      name: "data-pribadi",
      path: "data-pribadi",
      component: DataPribadi,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tendik", "dosen"],
        name: "Data Pribadi",
      },
    },
    {
      name: "pangkat-inpassing",
      path: "pangkat-inpassing",
      component: Inpassing,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tendik", "dosen"],
        name: "Inpassing",
      },
    },
    {
      name: "jabatan-fungsional",
      path: "jabatan-fungsional",
      component: Jabfung,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tendik", "dosen"],
        name: "Jabatan Fungsional",
      },
    },
    {
      name: "pendidikan-pelatihan",
      path: "pendidikan-pelatihan",
      component: Diklat,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tendik", "dosen"],
        name: "Pelatihan",
      },
    },
    {
      name: "kegiatan-rekognisi",
      path: "kegiatan-rekognisi",
      component: Rekognisi,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tendik", "dosen"],
        name: "Kegiatan Rekognisi",
      },
    },
    {
      name: "download-template-surat",
      path: "download-template-surat",
      component: TemplateSurat,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["tendik", "dosen"],
        name: "Template Surat",
      },
    },
  ],
};
