<template>
  <div v-if="isLoading" class="flex text-center">Loading data...</div>
  <div v-else>
    <div class="pt-6 mb-4 text-base font-medium text-center">
      SDM (Dosen & Tendik) Universitas MDP
    </div>
    <div class="flex pt-1.5 mb-4">
      <CanvasJSChart
        class="mr-5"
        :options="options"
        :styles="styleOptions"
        @chart-ref="setchartRef"
      />
      <CanvasJSChart
        :options="optionstendik"
        :styles="styleOptionstendik"
        @chart-ref="setchartReftendik"
      />
    </div>
  </div>
</template>

<script>
import { wspddikti, wssister } from "@/services/wspddikti";
import { sdm } from "@/services/models";

export default {
  name: "GrafikJumlahSDM",
  components: {},
  props: {
    sister: {
      type: Object,
      default: null,
    },
    chart: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dosenInfo: [],
      tendikInfo: [],
      isLoading: true,
      options: {
        theme: "light2",
        animationEnabled: true,
        title: {
          text: "",
        },
        axisY: {
          title: "Orang",
          includeZero: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: this.toggleDataSeries,
        },
        toolTip: {
          shared: true,
          content: this.toolTipFormatter,
        },
        data: [
          {
            type: "bar",
            showInLegend: true,
            name: "Dosen Aktif",
            color: "#2196F3",
          },
          {
            type: "bar",
            showInLegend: true,
            name: "Dosen Tidak Aktif",
            color: "rgba(213,216,220,0.47)",
          },
        ],
      },
      optionstendik: {
        theme: "light2",
        animationEnabled: true,
        title: {
          text: "",
        },
        axisY: {
          title: "Orang",
          includeZero: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: this.toggleDataSeries,
        },
        toolTip: {
          shared: true,
          content: this.toolTipFormatter,
        },
        data: [
          {
            type: "bar",
            showInLegend: true,
            name: "Tendik Aktif",
            color: "#b90b17",
          },
          {
            type: "bar",
            showInLegend: true,
            name: "Tendik Tidak Aktif",
            color: "rgba(213,216,220,0.47)",
          },
        ],
      },
      styleOptions: {
        width: "100%",
        height: "180px",
      },
      styleOptionstendik: {
        width: "100%",
        height: "180px",
      },
    };
  },
  computed: {},
  watch: {
    sister: {
      handler: function () {
        wssister
          .GetToken()
          .then((result) => {
            if (result === true) {
              this.connected = true;
              this.connMsg = "Connected to Server SISTER";
            } else {
              this.connected = false;
            }
            this.isLoading = false;
          })
          .finally(() => {});
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    setchartRef(chart) {
      if (!this.isLoading && this.dosenInfo) {
        // console.log(chart.options);
        // console.log(chart.options.data[0].dataPoints);

        sdm.Dosen.getCustomPath("getInfoDosen")
          .then((res) => {
            this.dosenInfo = res.data.data;
          })
          .finally(() => {
            chart.options.data[0].dataPoints = [
              { y: this.dosenInfo.active_lb, label: "Dosen LB" },
              { y: this.dosenInfo.active_tetap, label: "Dosen Tetap" },
            ];

            chart.options.data[1].dataPoints = [
              { y: this.dosenInfo.nonActive_lb, label: "Dosen LB" },
              { y: this.dosenInfo.nonActive_tetap, label: "Dosen Tetap" },
            ];
            chart.render();
          });
      }
    },
    setchartReftendik(chart) {
      if (!this.isLoading && this.tendikInfo) {
        sdm.Tendik.getCustomPath("getInfoTendik")
          .then((res) => {
            this.tendikInfo = res.data.data;
          })
          .finally(() => {
            chart.options.data[0].dataPoints = [
              { y: this.tendikInfo.active, label: "Tendik" },
            ];

            chart.options.data[1].dataPoints = [
              { y: this.tendikInfo.nonActive, label: "Tendik" },
            ];
            chart.render();
          });
      }
    },
    toggleDataSeries(e) {
      if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    },
  },
};
</script>
