<template>
  <div v-if="loading" class="p-5">Loading data...</div>
</template>

<script>
export default {
  components: {},
};
</script>

<style></style>
