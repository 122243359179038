import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store";
const HTTP = axios.create({
  baseURL: "https://api.mdp.ac.id/sisterapi/",
  headers: {
    "Content-Type": "application/json",
  },
});

HTTP.interceptors.request.use((req) => {
  const tokensister = store.getters["sisterpddikti/sistertoken"];

  if (tokensister !== null) {
    req.headers.common["sisterToken"] = tokensister;
  }
  return req;
});

axiosRetry(HTTP, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isRetryableError,
});

export { HTTP };
