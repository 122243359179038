<template>
  <div v-if="!loading" class="intro-y col-span-12 lg:col-span-12">
    <SavingStatus :is-saving="isSaving" :is-success="isSuccess"></SavingStatus>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl
        class="col-span-12 lg:col-span-12 border-2 border-dashed dark:border-dark-5 rounded-md py-6"
      >
        <DataList
          :white="true"
          :dd="nm_sdm"
          :dt="'Nama Dosen/Tendik'"
        ></DataList>
        <DataList :white="true" :dd="nip" :dt="'NIK'"></DataList>
        <hr class="my-5" />
        <DataList
          :white="true"
          :dd="rekognisi.nm_rekognisi"
          :dt="'Nama Kegiatan Rekognisi'"
        ></DataList>
        <DataList
          :white="true"
          :dd="rekognisi?.jenis_rekognisi?.nm_jns_rekognisi"
          :dt="'Jenis Kegiatan Rekognisi'"
        ></DataList>
        <DataList
          :white="true"
          :dd="rekognisi?.tingkat_rekognisi?.nm_tinggkat_rekognisi"
          :dt="'Tingkat Kegiatan Rekognisi'"
        ></DataList>
        <DataList
          :white="true"
          :dd="rekognisi.penyelenggara"
          :dt="'Penyelenggara'"
        ></DataList>
        <DataList
          :white="true"
          :dd="rekognisi.lokasi"
          :dt="'Lokasi'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(rekognisi.tanggal_mulai)"
          :dt="'Tanggal Mulai'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(rekognisi.tanggal_selesai)"
          :dt="'Tanggal Selesai'"
        ></DataList>
        <DataList :white="true" :dd="rekognisi.tahun" :dt="'Tahun'"></DataList>
        <DataList
          :white="true"
          :dd="rekognisi.sk_penugasan"
          :dt="'Nomor SK Penugasan'"
        ></DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import DataList from "../components/DataList";
import SavingStatus from "@/components/alerts/SavingStatus.vue";

export default {
  components: {
    DataList,
    SavingStatus,
  },
  props: {
    rekognisi: {
      type: Object,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      nm_sdm: null,
      nip: null,
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    rekognisi: {
      handler: function (data) {
        if (data?.sdm !== undefined) {
          this.loading = false;
          this.nm_sdm = data?.sdm
            ? data?.sdm?.nm_sdm
            : data?.sdm_pegawai?.NM_PEGAWAI;
          this.nip = data?.sdm ? data?.sdm?.nip : data?.sdm_pegawai?.KODE;
        } else {
          this.loading = this.isLoading;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
