<template>
  <div class="p-5">
    <div v-if="loading">Loading data...</div>
    <table v-else class="table w-100">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">Jenjang</th>
          <th class="whitespace-nowrap">Gelar</th>
          <th class="whitespace-nowrap">Bidang Studi</th>
          <th class="whitespace-nowrap">Perguruan Tinggi</th>
          <th class="whitespace-nowrap">Tahun Lulus</th>
          <th class="whitespace-nowrap">#</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in PendidikanFormal" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item?.jenjang_pendidikan }}</td>
          <td>{{ item?.gelar_akademik }}</td>
          <td>{{ item?.bidang_studi }}</td>
          <td>{{ item?.nama_perguruan_tinggi }}</td>
          <td>{{ item?.tahun_lulus }}</td>
          <td>
            <span
              v-if="item?.sync"
              class="btn btn-sm btn-disable btn-secondary"
            >
              Updated
            </span>
            <button
              v-if="!item?.sync"
              class="btn btn-sm btn-success"
              @click="openPanel(item?.id)"
            >
              <refresh-ccw-icon class="w-4 h-4 mr-2"></refresh-ccw-icon>
              Sync Data
            </button>
          </td>
        </tr>
        <tr v-if="PendidikanFormal.length == 0">
          <td colspan="6" class="text-center">Belum ada riwayat</td>
        </tr>
      </tbody>
    </table>

    <SlideOver :open="panel" @close="closePanel">
      <template #title>Sync Data Lokal Jabatan Fungsional</template>
      <div class="p-5 border-t border-gray-200 dark:border-dark-5">
        <form action="" @submit.prevent="submit">
          <div v-if="loading" class="p-5">Loading data...</div>
          <div v-else class="intro-y bg-gray-200 p-5 col-span-12 lg:col-span-6">
            <div class="grid grid-cols-12 gap-x-8 p-5 py-10">
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-32">ID Sdm</label>
                <input
                  v-model="DetailPendidikanFormal.id_sdm"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 form-inline mt-4">
                <label class="form-label font-medium w-32">ID</label>
                <input
                  v-model="DetailPendidikanFormal.id"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 form-inline mt-4">
                <label class="form-label font-medium w-32"
                  >Jabatan fungsional</label
                >
                <input
                  v-model="DetailPendidikanFormal.jenjang_pendidikan"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 form-inline mt-4">
                <label class="form-label font-medium w-32">SK</label>
                <input
                  v-model="DetailPendidikanFormal.gelar_akademik"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 form-inline mt-4">
                <label class="form-label font-medium w-32">TMT jabatan</label>
                <input
                  v-model="DetailPendidikanFormal.bidang_studi"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 form-inline mt-4">
                <label class="form-label font-medium w-32"
                  >Angka kredit jabatan</label
                >
                <input
                  v-model="DetailPendidikanFormal.nama_perguruan_tinggi"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 form-inline mt-4">
                <label class="form-label font-medium w-32"
                  >Angka kredit jabatan</label
                >
                <input
                  v-model="DetailPendidikanFormal.tahun_lulus"
                  class="form-control focus:ring-0"
                  readonly="readonly"
                />
              </div>
              <div class="col-span-12 mt-4">
                <div class="flex justify-end flex-col md:flex-row gap-2 mt-5">
                  <button
                    type="submit"
                    class="btn py-3 btn-primary w-full md:w-52"
                  >
                    <RefreshCwIcon class="w-4 h-4 mr-2" /> Sync Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </SlideOver>
  </div>
</template>

<script>
import { wssister } from "@/services/wspddikti";
import SlideOver from "@/layouts/main/SlideOver.vue";
import { sdm } from "@/services/models";

export default {
  components: {
    SlideOver,
  },
  props: {
    sister: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      pendFormal: [],
      PendidikanFormal: [],
      DetailPendidikanFormal: [],
      loading: true,
      panel: false,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  mounted() {
    this.getPendidikanFormalData();
  },
  methods: {
    async getPendidikanFormalData() {
      if (this.id?.length > 0) {
        sdm.Dosen.getCustomPath(`getDetailDosen/${this.id}`).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.pendFormal = res.data.data?.pend_formal;
            }

            wssister
              .GetData("pendidikan_formal", {
                kd_dosen: this.id,
              })
              .then((res) => {
                if (res.data) {
                  this.loading = false;
                  this.PendidikanFormal = res.data;
                }
                // console.log(res);
                this.isEqual(this.PendidikanFormal, this.pendFormal);
              });
          }
        });
      }
    },
    isEqual(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        // if (arr1[i] !== arr2[i]) return false;
        for (let t = 0; t < arr2.length; t++) {
          if (
            arr1[i]["jenjang_pendidikan"] ===
              arr2[t]["jenjang_pendidikan"]["nm_jenj_didik"] &&
            arr1[i]["gelar_akademik"] ===
              arr2[t]["gelar_akademik"]["singkat_gelar"] &&
            arr1[i]["bidang_studi"] ===
              arr2[t]["bidang_studi"]["nm_bid_studi"] &&
            arr1[i]["nama_perguruan_tinggi"] === arr2[t]["nm_sp_formal"] &&
            arr1[i]["tahun_lulus"] === parseInt(arr2[t]["thn_lulus"])
          ) {
            arr1[i]["sync"] = 1;
          }
        }
      }

      // console.log("isEqual");
      // console.log(arr1);
      // return true if above checks are passed
      // console.log(arr2);
      // console.log(arr3);
      return true;
    },
    openPanel(id) {
      wssister.GetData("pendidikan_formal/" + id, {}).then((res) => {
        if (res.data) {
          // this.loading = true;
          this.DetailPendidikanFormal = res.data;
        }
      });

      this.panel = true;
    },
    closePanel(close) {
      this.panel = close;
    },
    submit() {
      this.loading = true;

      let formdata = {
        id_sdm: this.DetailPendidikanFormal.id_sdm,
        id: this.DetailPendidikanFormal.id,
        nm_sp_formal: this.DetailPendidikanFormal.nama_perguruan_tinggi,
        thn_masuk: this.DetailPendidikanFormal.tahun_masuk,
        thn_lulus: this.DetailPendidikanFormal.tahun_lulus,
        nipd: this.DetailPendidikanFormal.nomor_induk,
        smt: this.DetailPendidikanFormal.jumlah_semester,
        sks_lulus: this.DetailPendidikanFormal.jumlah_sks,
        ipk: this.DetailPendidikanFormal.ipk,
        sk_setara: this.DetailPendidikanFormal.sk_penyetaraan,
        tgl_sk_setara: this.DetailPendidikanFormal.tanggal_sk_penyetaraan,
        no_ijazah: this.DetailPendidikanFormal.nomor_ijazah,
        judul_tesis: this.DetailPendidikanFormal.judul_tugas_akhir,
        tgl_lulus: this.DetailPendidikanFormal.tanggal_lulus,
        id_bid_studi: this.DetailPendidikanFormal.id_bidang_studi,
        id_jenj_didik: this.DetailPendidikanFormal.id_jenjang_pendidikan,
        id_gelar_akad: this.DetailPendidikanFormal.id_gelar_akademik,
      };

      // if (this.id?.length > 0) {
      sdm.Dosen.postCustomPath("update_dosen_sdm_pendform/", formdata).then(
        (res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              // this.$store.dispatch("main/isSuccess", true);
              // this.$store.dispatch("main/responseMessage", res.data.message);
              // this.$emit("simpanBio", true);
              // console.log(res.data.message);
              this.getPendidikanFormalData();
              this.panel = false;
            } else {
              // Object.keys(res.data.message).forEach((key) => {
              //   this.messageAlert.push(res.data.message[key][0]);
              // });
            }
            this.loading = false;
          }
        }
      );
      // }
    },
  },
};
</script>
