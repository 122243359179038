<template>
  <Chart type="pie" :width="width" :height="height" :data="data" />
  <div class="mt-8">
    <div class="flex items-center">
      <div class="w-2 h-2 bg-red-700 rounded-full mr-3"></div>
      <span class="truncate"
        >Jumlah Status Tendik <span class="text-green-500">Aktif</span></span
      >
      <div
        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
      ></div>
      <span class="font-medium xl:ml-auto">{{ activeEmployee }}</span>
    </div>
    <div class="flex items-center mt-4">
      <div class="w-2 h-2 bg-gray-300 rounded-full mr-3"></div>
      <span class="truncate"
        >Jumlah Status Tendik
        <span class="text-red-500">Tidak Aktif</span></span
      >
      <div
        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
      ></div>
      <span class="font-medium xl:ml-auto">{{ nonActiveEmployee }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    activeEmployee: {
      type: Number,
      default: 0,
    },
    nonActiveEmployee: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.main.darkMode);

    console.log("activeEmployee: ", props.activeEmployee);
    console.log("nonActiveEmployee: ", props.nonActiveEmployee);
    const data = computed(() => {
      return {
        labels: ["Aktif", "Tidak Aktif"],
        datasets: [
          {
            data: [props.activeEmployee, props.nonActiveEmployee],
            backgroundColor: ["#b90b17", "rgba(213,216,220,0.47)"],
            hoverBackgroundColor: ["#b90b17", "rgba(213,216,220,0.47)"],
            borderWidth: 1,
            borderColor: darkMode.value ? "#303953" : "#fff",
          },
        ],
      };
    });

    return {
      data,
      props,
    };
  },
});
</script>
