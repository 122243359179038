import feather from "feather-icons";
import Tabulator from "tabulator-tables";
//import { useStore } from "vuex";
//const store = this.$store;
import store from "@/store";

const tabulators = {
  localDataTable(tableRef, columns) {
    return new Tabulator(tableRef.value, {
      reactiveData: true, //enable data reactivity
      pagination: "local",
      paginationSize: 10,
      paginationSizeSelector: [10, 20, 30, 40],
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "Data tidak ditemukan",
      columns: columns,
      renderComplete() {
        feather.replace({
          "stroke-width": 1.5,
        });
      },
    });
  },
  remoteDataTable(
    tableRef,
    url,
    columns,
    pageSize = false,
    customHeader = null
  ) {
    return new Tabulator(tableRef.value, {
      pagination: "remote", //enable remote pagination
      ajaxURL: url,
      ajaxConfig: {
        headers: customHeader !== null ? customHeader : this.setHeader(),
      },
      paginationSize: 10, //optional parameter to request a certain number of rows per page
      paginationInitialPage: 1, //optional parameter to set the initial page to load
      ajaxFiltering: true,
      ajaxSorting: true,
      layout: "fitColumns",
      responsiveLayout: "collapse",
      placeholder: "Data tidak ditemukan",
      paginationSizeSelector: pageSize ? [10, true] : false,
      columns: columns,
      renderComplete() {
        feather.replace({
          "stroke-width": 1.5,
        });
      },
    });
  },

  ajaxConfig() {
    return {
      headers: this.setHeader(),
    };
  },
  setHeader() {
    const accessToken = store.getters["auth/accessToken"];
    const tokendikti = store.getters["feederpddikti/feedertoken"];
    const institusi = store.getters["main/currentInstitusi"];
    const semester = store.getters["main/currentSemester"];
    const fakultas = store.getters["main/currentFakultas"];
    return {
      Accept: "application/json", //tell the server we need JSON back
      Authorization: "Bearer " + accessToken,
      currentFakultas: fakultas?.id,
      currentInstitusi: institusi?.id_sp,
      currentSemester: semester?.id_smt !== undefined ? semester?.id_smt : "",
      feederToken: tokendikti,
    };
  },
};

const install = (app) => {
  app.config.globalProperties.$list = tabulators;
};

export { install as default, tabulators as tblTabulator };
