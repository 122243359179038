import {
  csrfCookie,
  refreshToken,
  setTeam,
  signIn,
  signOut,
  unlock,
} from "@/services/auth";

import router from "@/router";
import store from "@/store";
import { useCookies } from "vue3-cookies";

const admteamgroup = ["1", "12"];
const staffteamgroup = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
const state = () => {
  return {
    isLoading: false,
    token: "", //token name
    accessToken: "", //token name
    expiration: "",
    isBusy: false,
    error: "",
    abilities: "",
    features: "",
    autoExtend: false,
    pickTeam: false,
    loginDone: false,
    loginas: false,
    admteam: admteamgroup,
    staffteam: staffteamgroup,
    sessionLockTime: new Date(), //time when session locked (will expired)
    sessionLock: false,
    unlockingSession: new Date(), //time when session is unlocked,
  };
};

const mutations = {
  setLoading: (state, status) => (state.isLoading = status),
  setBusy: (state) => (state.isBusy = true),
  clearBusy: (state) => (state.isBusy = false),
  setError: (state, error) => (state.error = error),
  clearError: (state) => (state.error = ""),
  setToken: (state, model) => {
    state.token = model.token_name;
    state.expiration = new Date(model.expiration).toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    });
    state.sessionLockTime = new Date(
      new Date().getTime() + process.env.VUE_APP_EXP_DUR * 60000
    );
  },
  clearToken: (state) => {
    state.token = "";
    state.expiration = new Date();
    state.abilities = null;
    state.loginas = false;
    state.sessionLockTime = new Date();
    state.sessionLock = false;
    localStorage.clear();
  },
  setAbilities: (state, model) => {
    state.abilities = model.ability;
  },
  setFeatures: (state, model) => {
    state.features = model.feature;
  },
  setAccessToken: (state, access_token) => {
    state.accessToken = access_token;
    localStorage.setItem("_bh", JSON.stringify(access_token));
  },
  pickTeam: (state, status = true) => {
    state.pickTeam = status;
  },
  loginDone: (state, status = true) => {
    state.loginDone = status;
    state.unlockingSession = new Date();
    const cookies = useCookies();
    let lt = new Date(new Date().getTime() + 3000);
    cookies.cookies.set("_at_war", lt, lt);
    cookies.cookies.set("_on_duty", new Date(), 0);
  },
  loginAs: (state, status = true) => {
    state.loginas = status;
  },
  unlock: (state) => {
    state.sessionLockTime = new Date(
      new Date().getTime() + process.env.VUE_APP_EXP_DUR * 60000
    );
    state.sessionLock = false;
    state.unlockingSession = new Date();

    const cookies = useCookies();
    let lt = new Date(
      new Date().getTime() + process.env.VUE_APP_EXP_DUR * 60000
    );
    cookies.cookies.set("_at_war", lt, lt);
    cookies.cookies.remove("_on_duty");
    cookies.cookies.set("_on_duty", new Date(), 0);
  },
  sessionLock: (state, lock = false) => {
    state.sessionLock = lock; //new Date(state.sessionLockTime) < new Date();
  },
  unlockingSession: (state, time) => {
    state.unlockingSession = time;
  },
  passwordCheck: (state, value) => {
    state.passwordCheck = value;
  },
};

const getters = {
  isAuthenticated: (state) => state.token.length > 0,
  isExpire: (state) =>
    state.expiration <
    new Date().toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    }),
  abilities: (state) => state.abilities,
  features: (state) => state.features,
  accessToken: (state) => {
    let ls = state.loginas ? "_bh_tmp" : "_bh";
    return JSON.parse(localStorage.getItem(ls));
  },
  tempAccessToken: () => JSON.parse(localStorage.getItem("_bh_temp")),
  isAutoExtend: (state) => state.autoExtend,
  error: (state) => state.error,
  admteam: (state) => state.admteam,
  staffteam: (state) => state.staffteam,
  isSuperAdmin: (state, getters) => {
    let su =
      getters.abilities?.length > 0
        ? getters.abilities?.find((a) => a === "*")
        : undefined;
    return su !== undefined;
  },
  pickTeam: (state) => state.pickTeam,
  loginDone: (state) => state.loginDone,
  tokenName: (state) => state.token,
  loginAs: (state) => state.loginas,
  isSessionLocked: (state) => {
    if (getters.isAuthenticated(state)) {
      const cookies = useCookies();
      let sesslt = cookies.cookies.get("_on_duty");
      if (sesslt) {
        let uls = state.unlockingSession;
        let lt = new Date(uls);
        let now = new Date();
        let diff = Math.abs(now - lt);
        let diffm = Math.ceil(diff / (1000 * 60));
        if (diffm > process.env.VUE_APP_EXP_DUR) {
          state.sessionLock = true;
        }
      } else {
        state.sessionLock = true;
      }
    }
    return state.sessionLock;
  },
  isClientExpire: (state) => {
    return new Date(state.expiration) < new Date();
  },
  unlockingSession: (state) => state.unlockingSession,
  lockingTime: () => {
    const cookies = useCookies();
    return cookies.cookies.get("_on_duty");
  },
  getPasswordCheck: (state) => state.passwordCheck,
};

const actions = {
  LogIn({ commit, dispatch }, user) {
    commit("setLoading", true);
    commit("setBusy");
    commit("clearError");
    commit("pickTeam", false);
    commit("loginDone", false);
    store.dispatch("main/setLoadingStatus", true);
    signIn(user)
      .then(async (result) => {
        if (result.data.status_code === 200) {
          commit("setToken", result.data);
          commit("setAbilities", result.data);
          commit("setFeatures", result.data);
          commit("setAccessToken", result.data.access_token);

          store.commit("userdata/setUserData", result.data);
          let teams = result?.data?.teams;
          if (Array.isArray(teams) && teams.length === 1) {
            commit("loginDone");
            await dispatch("TeamMetaData");
            commit("setLoading", false);
            router.go(1);
            router.push("/");
          } else {
            commit("setLoading", false);
            commit("loginDone", false);
            commit("pickTeam", true);
          }
        } else {
          commit("setLoading", false);
          commit("setError", result.data?.message);
        }
      })
      .catch((error) => {
        commit("setError", error.response.data.message);
      })
      .finally(() => {
        store.dispatch("main/setLoadingStatus", false);
      });
  },
  LoginV2({ commit, dispatch }, user) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await signIn(user);
        if (response.status === 200) {
          // console.log(response);

          const data = response?.data;
          // const passwordCheck = checkPasswordStrength(user.password);

          // commit("passwordCheck", passwordCheck);
          commit("setToken", data);
          commit("setAbilities", data);
          commit("setFeatures", data);
          commit("setAccessToken", data.access_token);
          commit("setGoogle2fa", data);

          store.commit("userdata/setUserData", data);

          const teams = response?.data?.teams ?? [];
          // console.log(teams);
          let goTo = "/";
          let pickTeam = false;

          // if (passwordCheck[0] < 2) {
          //   goTo = "/change-password";
          // }

          if (teams.length > 1) {
            commit("loginDone", false);
            pickTeam = true;
          } else {
            commit("loginDone");
            await dispatch("TeamMetaData");
          }

          resolve([goTo, pickTeam]);
        } else {
          commit("setError", response.data?.message);
        }
      } catch (error) {
        reject(error.response.data.message);
        commit("setError", error.response.data.message);
      }
    });
  },
  LogOut: ({ commit }) => {
    commit("clearToken");
    commit("pickTeam", false);
    commit("loginDone", false);
    store.dispatch("main/setLoadingStatus", true);
    store.commit("userdata/clearUserData");
    // store.commit("main/clearMainState");
    store.commit("main/clearResponse");
    router.push({ name: "login" });
  },
  async RefreshToken({ commit, state }) {
    let data = new FormData();
    data.append("apps", state.token);
    await refreshToken(data)
      .then((result) => {
        if (result.status === 200) {
          commit("setToken", result.data);
          commit("setAccessToken", result.data.access_token);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403)
          commit("setError", error.response.data.message);
        else commit("setError", "Authentication Failed");
      });
  },
  SetTeam({ commit, dispatch }, payload) {
    let data = new FormData();
    data.append("appid", "26");
    data.append("teamid", payload.id);
    store.dispatch("main/setLoadingStatus", true);
    setTeam(data)
      .then(async (result) => {
        if (result.status === 200) {
          if (result.data.status == true) {
            store.commit("userdata/setCurrentTeam", result?.data?.current_team);
            commit("setAbilities", result?.data);
            commit("setFeatures", result?.data);
          }
          await dispatch("TeamMetaData");
          commit("loginDone", true);
          commit("pickTeam", false);
          // let user = store.getters["userdata/userData"];
          // startFCM(user.uid);
          if (payload.refresh) {
            router.go(1);
            router.push("/");
          }
        } else {
          commit(
            "setError",
            "Login gagal, tidak dapat mengganti Role/Team/Level"
          );
        }
      })
      .finally(() => {
        store.dispatch("main/setLoadingStatus", false);
      });
  },
  async TeamMetaData() {
    let currentTeamId = store.getters["userdata/currentTeamId"];
    if (currentTeamId === "3") await store.dispatch("userdata/dosen");
    if (currentTeamId === "4") await store.dispatch("userdata/staff");
  },
  Unlock: ({ commit, dispatch }, user) => {
    //commit("unlockingSession", false);
    unlock(user)
      .then((res) => {
        if (res.status === 200) {
          //commit("unlockingSession", true);
          commit("unlock");
          commit("sessionLock", false);
          dispatch("RefreshToken");
        }
      })
      .catch((error) => {
        if (error.response?.status === 403)
          commit("setError", error.response.data.message);
        else if (error.response?.status === 401) {
          //commit("setError", "Anda tidak aktif lebih dari 1 jam, silakan Logout dan login kembali");
          commit(
            "setError",
            "You have been inactive for a long time, please re-login!"
          );
        } else commit("setError", "Authentication Failed");

        //commit("unlockingSession", false);
      })
      .finally(() => {});
  },
  SessionLock: ({ commit }) => {
    const cookies = useCookies();
    cookies.cookies.remove("_on_duty");
    commit("sessionLock", true);
    console.log("Session Locked", "Cookie Removed");
  },
  ExtendSessionLock: ({ commit }) => {
    commit("unlock");
  },
  RevokeToken: () => {
    signOut();
  },
  settingSession: ({ commit }, status) => {
    commit("sessionLock", status);
  },
  CSRF({ commit }) {
    csrfCookie().catch((error) => {
      commit(
        "setError",
        error.response.data.message + " (" + error.response?.status + ")"
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
