<template>
  <div v-if="!loadingStatus" class="intro-y p-0 col-span-12 lg:col-span-6">
    <div class="grid grid-cols-12 gap-2">
      <div
        v-if="tabon == 'datapribadi'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Data Pribadi</h2>
        </div>
        <DataPribadi />
      </div>
      <div v-if="tabon == 'dokumen'" class="intro-y col-span-12 lg:col-span-12">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Dokumen</h2>
        </div>
        <Dokumen />
      </div>
      <div
        v-if="tabon == 'inpassing'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Inpassing</h2>
        </div>
        <Inpassing />
      </div>
      <div
        v-if="tabon == 'jabatanfungsional'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">
            Riwayat Jabatan Fungsional
          </h2>
        </div>
        <JabatanFungsional />
      </div>
      <div
        v-if="tabon == 'kepangkatan'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">
            Riwayat Golongan/Pangkat
          </h2>
        </div>
        <Kepangkatan />
      </div>
      <div
        v-if="tabon == 'penempatan'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Penempatan</h2>
        </div>
        <Penempatan />
      </div>
      <div
        v-if="tabon == 'pendidikanformal'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">
            Riwayat Pendidikan Formal
          </h2>
        </div>
        <Pendidikanformal />
      </div>
      <div v-if="tabon == 'diklat'" class="intro-y col-span-12 lg:col-span-12">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Staff</h2>
        </div>
        <Staff />
      </div>
      <div
        v-if="tabon == 'sertifikasi'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Riwayat Sertifikasi</h2>
        </div>
        <Sertifikasi />
      </div>
      <div v-if="tabon == 'tes'" class="intro-y col-span-12 lg:col-span-12">
        <div
          class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
        >
          <h2 class="font-medium text-base mr-auto">Tes</h2>
        </div>
        <Tes />
      </div>
    </div>
  </div>
</template>

<script>
// import { wssister } from "@/services/wspddikti";
import DataPribadi from "./sister/dataPribadi";
import Dokumen from "./sister/dataDokumen";
import Inpassing from "./sister/dataInpassing";
import JabatanFungsional from "./sister/dataJabatanFungsional";
import Kepangkatan from "./sister/dataKepangkatan";
import Penempatan from "./sister/dataPenempatan";
import Pendidikanformal from "./sister/dataPendidikanformal";
import Staff from "./sister/dataDiklat";
import Sertifikasi from "./sister/dataSertifikasi";
import Tes from "./sister/dataTes";

export default {
  components: {
    DataPribadi,
    Dokumen,
    Inpassing,
    JabatanFungsional,
    Kepangkatan,
    Penempatan,
    Pendidikanformal,
    Staff,
    Sertifikasi,
    Tes,
  },
  props: {
    // sdm: {
    //   type: Object,
    //   default: null,
    // },
    tabon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // loading: true,
    };
  },
  computed: {
    sisterconnect() {
      return this.$store.state.sisterpddikti.sisterconnect;
    },
    // sisterserver() {
    //   return this.$store.state.sisterpddikti.sisterserver;
    // },
    // sistertoken() {
    //   return this.$store.state.sisterpddikti.sistertoken;
    // },
    // sistererror() {
    //   return this.$store.state.sisterpddikti.sistererror;
    // },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    // sdm: {
    //   // handler: function (data) {
    //   //   // if (data?.id_sdm !== undefined) {
    //   //   //   this.loading = false;
    //   //   //   wssister.GetToken().then((result) => {
    //   //   //     if (result === true) {
    //   //   //       this.connected = true;
    //   //   //       this.connMsg =
    //   //   //         "Connected to Server " +
    //   //   //         this.feederserver.toUpperCase() +
    //   //   //         " PDDIKTI";
    //   //   //     } else {
    //   //   //       this.connected = false;
    //   //   //     }
    //   //   //   });
    //   //   // }
    //   // },
    //   // immediate: true,
    // },
  },
};
</script>

<style></style>
