<template>
  <div class="intro-y box mt-5">
    <div class="p-5">
      <div class="flex flex-col items-center justify-center w-full h-full p-5">
        <CalendarIcon class="h-6 w-6 mb-2"></CalendarIcon>
        <div class="text-lg font-medium">Birthday Reminder (Today)</div>
        <div class="text-gray-500 mt-0.5">
          Make sure that they have a special day 🎁🎉
        </div>
      </div>
      <div class="mx-auto mt-5 w-52 border-t border-dashed sm:w-auto">
        <div v-if="isLoading" class="flex text-center mt-4">
          Loading Data...
        </div>
        <div v-else-if="listUltah.length > 0">
          <div
            v-for="(item, index) in listUltah"
            :key="index"
            class="flex items-center mt-4"
          >
            <span class="truncate font-bold"
              >{{ item.nm }}<br />
              <smal class="text-gray-500 text-xs mt-0.5 font-normal">{{
                $h.tanggal(item.tgl_lahir)
              }}</smal></span
            >
            <span class="ml-auto font-medium text-blue-600">{{
              $h.getAge(item.tgl_lahir)
            }}</span>
          </div>
        </div>
        <div v-else class="flex text-center mt-4 text-gray-400">
          No result found!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sdm } from "@/services/models";

export default {
  name: "BirthdayReminder",
  components: {},
  data() {
    return {
      listUltah: [],
      isLoading: false,
    };
  },
  computed: {},
  mounted() {
    this.getBirthday();
  },
  methods: {
    async getBirthday() {
      this.isLoading = true;
      sdm.Rekrutmen.getCustomPath("ultahpegawai").then((res) => {
        if (res.status === 200) {
          this.listUltah = res.data?.result;
          this.isLoading = false;
        }
      });
    },
  },
};
</script>
