<template>
  <div class="p-5">
    <div v-if="loading">Loading data...</div>
    <table v-else class="table w-100">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">Jenjang</th>
          <th class="whitespace-nowrap">Gelar</th>
          <th class="whitespace-nowrap">Bidang Studi</th>
          <th class="whitespace-nowrap">Perguruan Tinggi</th>
          <th class="whitespace-nowrap">Tahun Lulus</th>
          <th class="whitespace-nowrap">#</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in PendidikanFormal" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item?.jenjang_pendidikan }}</td>
          <td>{{ item?.gelar_akademik }}</td>
          <td>{{ item?.bidang_studi }}</td>
          <td>{{ item?.nama_perguruan_tinggi }}</td>
          <td>{{ item?.tahun_lulus }}</td>
          <td>#</td>
        </tr>
        <tr v-if="PendidikanFormal.length == 0">
          <td colspan="6" class="text-center">Belum ada riwayat</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { wssister } from "@/services/wspddikti";

export default {
  components: {},
  props: {
    sister: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      PendidikanFormal: [],
      loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  mounted() {
    this.getPendidikanFormalData();
  },
  methods: {
    async getPendidikanFormalData() {
      wssister
        .GetData("pendidikan_formal", {
          kd_tendik: this.id,
        })
        .then((res) => {
          if (res.data) {
            this.loading = false;
            this.PendidikanFormal = res.data;
          }
          // console.log(res);
        });
    },
  },
};
</script>
