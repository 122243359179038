<template>
  <div v-if="loading" class="p-5">Loading data...</div>
  <div v-else class="intro-y p-5 col-span-12 lg:col-span-6">
    <SavingStatus :is-saving="isSaving" :is-success="isSuccess"></SavingStatus>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-12">
        <DataList
          :white="true"
          :dd="sdm.NM_DOSEN"
          :dt="'Nama Dosen'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(sdm.TGL_LAHIR)"
          :dt="'Tanggal Lahir'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.tmpt_lahir"
          :dt="'Tempat Lahir'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.jenisKelamin(sdm.JENIS_KEL)"
          :dt="'Jenis Kelamin'"
        ></DataList>
        <DataList :white="true" :dd="sdm.ALAMAT1" :dt="'Alamat 1'"></DataList>
        <DataList :white="true" :dd="sdm.ALAMAT2" :dt="'Alamat 2'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.kel"
          :dt="'Kelurahan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.kec"
          :dt="'Kecamatan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.kode_pos"
          :dt="'Kode Pos'"
        ></DataList>
        <DataList :white="true" :dd="sdm.HP" :dt="'No HP'"></DataList>
        <DataList :white="true" :dd="sdm.EMAIL" :dt="'Email'"></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.nik"
          :dt="'NIK'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.npwp"
          :dt="'NPWP'"
        ></DataList>
        <DataList :white="true" :dd="sdm.bpjs_tk" :dt="'BPJS TK'"></DataList>
        <DataList :white="true" :dd="sdm.bpjs_ks" :dt="'BPJS KS'"></DataList>
        <DataList :white="true" :dd="sdm.NOREK" :dt="'NO. Rekening'"></DataList>
        <DataList
          :white="true"
          :dd="$h.statKawin(sdm.sdmrekrutmen?.stat_kawin)"
          :dt="'Status Perkawinan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.agama(sdm.sdmrekrutmen?.agama)"
          :dt="'Agama'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.sdmrekrutmen?.gol_darah"
          :dt="'Golongan Darah'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList :white="true" :dd="sdm.NIDN" :dt="'NIDN'"></DataList>
        <DataList
          :white="true"
          :dd="
            sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tenaga_pendidik == 1
              ? 'Dosen Tetap'
              : sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tenaga_pendidik == 2
              ? 'Dosen LB'
              : '-'
          "
          :dt="'Status Dosen'"
        ></DataList>
        <DataList :white="true" :dd="sdm.posisi" :dt="'Posisi'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.unit_kerja"
          :dt="'Unit Kerja'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.fakultas(sdm.fakultas)"
          :dt="'Fakultas'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList
          :white="true"
          :dd="$h.tanggal(sdm.TGL_MASUK)"
          :dt="'Tanggal Masuk'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_pengangkatan)
          "
          :dt="'Tanggal Pengangkatan Karyawan Tetap'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(sdm.tgl_keluar)"
          :dt="'Tanggal Keluar'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja)"
          :dt="'Tanggal Mulai Kerja'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_training)
          "
          :dt="'Tanggal Mulai Masa Percobaan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(
              sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_berakhir_training
            )
          "
          :dt="'Tanggal Berakhir Masa Percobaan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_1)
          "
          :dt="'Tanggal Mulai Kontrak 1'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(
              sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_berakhir_kontrak_1
            )
          "
          :dt="'Tanggal Berakhir Kontrak 1'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_2)
          "
          :dt="'Tanggal Mulai Kontrak 2'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            $h.tanggal(
              sdm.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_berakhir_kontrak_2
            )
          "
          :dt="'Tanggal Berakhir Kontrak 2'"
        ></DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import DataList from "../components/DataList";
import SavingStatus from "@/components/alerts/SavingStatus.vue";

export default {
  components: {
    DataList,
    SavingStatus,
  },
  props: {
    sdm: {
      type: Object,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    sdm: {
      handler: function (data) {
        if (data?.KODE !== undefined) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
