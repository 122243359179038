<template>
  <div
    class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5"
  >
    <div class="flex flex-1 items-center justify-center lg:justify-start">
      <div
        v-if="showContact"
        class="w-32 h-32 flex-none image-fit relative sm:w-24 sm:h-24 lg:w-48 lg:h-48"
      >
        <img
          alt="Icewall Tailwind HTML Admin Template"
          class="rounded-full"
          src="https://api.mdp.ac.id/public/assets/logo/logo-umdp.png"
        />
      </div>
      <div :class="showContact ? 'ml-6' : ''">
        <div
          v-if="dosen?.sdm?.nm_sdm"
          class="sm:whitespace-normal font-medium mb-2"
        >
          {{ dosen?.sdm === null ? nama : dosen?.sdm?.nm_sdm }}
        </div>
        <div v-if="nama" class="sm:whitespace-normal font-medium mb-1">
          {{ nama }} <strong>({{ kode !== null ? kode : "" }})</strong>
        </div>
        <div class="text-gray-600 mb-3">
          <div class="font-medium">Dosen {{ statusDosen }}</div>
          <div class="font-medium">
            {{ dosen?.sms?.nm_lemb }}
          </div>
        </div>

        <div class="border-t border-gray-200 pt-4 dark:border-dark-5 mt-2">
          <span
            v-if="statusAktif || dosen?.sdm"
            class="px-3 py-1 text-white rounded text-xs mr-2 bg-blue-800"
          >
            {{
              statusAktif
                ? statusAktif
                : dosen?.sdm.status_keaktifan_pegawai.nm_stat_aktif
            }}
          </span>

          <span
            v-if="dosen?.sdm?.nidn || dosen?.NIDN"
            class="px-3 py-1 mr-2 text-white rounded text-xs bg-blue-600"
          >
            {{ dosen?.NIDN ? dosen?.NIDN : dosen?.sdm?.nidn }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="flex flex-1 items-center justify-center lg:justify-start mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0 border-l border-r"
    >
      <div
        class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-r"
      >
        <div class="text-center rounded-md py-3">
          <div class="text-theme-1">Masa Kerja</div>
          <div
            v-if="masaKerja"
            class="font-medium text-blue-800 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            {{ masaKerja.years }} Tahun
            <span class="text-blue-500">{{ masaKerja.months }} Bulan</span>
          </div>
          <div
            v-else
            class="font-medium text-blue-800 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            -
          </div>
          <div
            v-if="dosen?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja"
            class="text-gray-600 text"
          >
            ({{
              $h.tanggal(
                dosen?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja
              )
            }}
            - Present)
          </div>
        </div>
      </div>
      <div class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5">
        <div class="text-center rounded-md py-3">
          <div class="text-theme-1">Teguran/Peringatan</div>
          <div
            class="font-medium text-red-500 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            -
          </div>
          <div
            class="font-medium text-theme-9 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            Tidak Ada Teguran
          </div>
          <div class="text-gray-600 text">(-)</div>
          <button
            class="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer btn-warning btn-sm font-medium w-32 py-1 px-2 mt-3 mr-2 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary dark:border-primary"
          >
            <span
              ><radio-icon class="w-4 h-4 mr-1"></radio-icon> Beri Teguran</span
            >
          </button>
        </div>
      </div>
    </div>

    <!--    <div-->
    <!--      v-if="showContact"-->
    <!--      class="mt-6 lg:mt-0 w-25 dark:text-gray-300 px-5 border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0 border-l border-r"-->
    <!--    >-->
    <!--      <div class="font-medium text-center lg:text-left lg:mt-3">Kontak</div>-->
    <!--      <div-->
    <!--        class="flex flex-col justify-center items-center lg:items-start mt-4"-->
    <!--      >-->
    <!--        <div class="truncate sm:whitespace-normal flex items-center">-->
    <!--          <MailIcon class="w-4 h-4 mr-2" />-->
    <!--          {{ dosen?.sdm?.email ? dosen?.sdm?.email : "-" }}-->
    <!--        </div>-->
    <!--        <div class="truncate sm:whitespace-normal flex items-center mt-1">-->
    <!--          <PhoneIcon class="w-4 h-4 mr-2" />-->
    <!--          {{ dosen?.sdm?.no_hp ? dosen?.sdm?.no_hp : "" }}-->
    <!--          <span class="text-gray-600 ml-2">-->
    <!--            {{ dosen?.HP == dosen?.sdm?.no_hp ? "-" : dosen?.HP }}-->
    <!--          </span>-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="dosen?.sdm?.no_tel_rmh"-->
    <!--          class="truncate sm:whitespace-normal flex items-center mt-1"-->
    <!--        >-->
    <!--          <PhoneCallIcon class="w-4 h-4 mr-2" />-->
    <!--          {{ dosen?.sdm?.no_tel_rmh ? dosen?.sdm?.no_tel_rmh : "" }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div
      v-if="showMoreInfo && !loading"
      class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0 border-r"
    >
      <CanvasJSChart
        :options="options"
        :styles="styleOptions"
        @chart-ref="setchartRef"
      />
    </div>
    <!--    <div-->
    <!--      class="mt-6 lg:mt-0 flex-1 flex text-gree items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"-->
    <!--    >-->
    <!--      <div class="p-3" :class="connected ? 'text-theme-9' : 'text-gray-400'">-->
    <!--        <BatteryChargingIcon class="w-5 h-5 mr-2" /> {{ connMsg }}-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { wssister } from "@/services/wspddikti";

export default {
  components: {},
  props: {
    showContact: {
      default: true,
      type: Boolean,
    },
    showMoreInfo: {
      default: true,
      type: Boolean,
    },
    dosen: {
      default: null,
      type: Object,
    },
  },
  // setup() {
  //   const Jabfung = ref([]);
  //
  //   // const adminCategoriesListInit = async () => {
  //   //   onJabfung(); // ERROR HERE
  //   // };
  //
  //   function onJabfung() {
  //     // wssister
  //     //   .GetToken()
  //     //   .then((result) => {
  //     //     if (result === true) {
  //     //       this.connected = true;
  //     //       this.connMsg = "Connected to Server SISTER";
  //     //     } else {
  //     //       this.connected = false;
  //     //     }
  //     //   })
  //     //   .finally(() => {
  //     //     wssister
  //     //       .GetData("jabatan_fungsional", {
  //     //         kd_dosen: this.kode,
  //     //       })
  //     //       .then((res) => {
  //     //         if (res.data) {
  //     //           let jabfung = [];
  //     //
  //     //           let index = res.data.sort(function (a, b) {
  //     //             return b - a;
  //     //           });
  //     //
  //     //           for (var i = 0; i < index.length; i++) {
  //     //             var d = new Date(index[i].tanggal_mulai);
  //     //             jabfung.push({
  //     //               label: d.getFullYear().toString(),
  //     //               y: 1,
  //     //               indexLabel: index[i].jabatan_fungsional,
  //     //               x: i,
  //     //             });
  //     //           }
  //     //           // this.JabatanFungsional = jabfung;
  //     //           // console.log(this.Jabfung);
  //     //           // Jabfung.value.push(jabfung);
  //     //         }
  //     //       });
  //     //   });
  //     return Jabfung.value.push({
  //       label: "2024",
  //       y: 1,
  //       indexLabel: "Lektor",
  //       x: 0,
  //     });
  //     // return [
  //     //   { label: "2017", y: 1, indexLabel: "Asisten Ahli" },
  //     //   { label: "2024", y: 1, indexLabel: "Lektor" },
  //     // ];
  //   }
  //
  //   const options = computed(() => {
  //     return {
  //       theme: "light2",
  //       animationEnabled: true,
  //       exportEnabled: true,
  //       title: {
  //         text: "JJA RECORDS",
  //       },
  //       // subtitles: [
  //       //   {
  //       //     text: "India",
  //       //   },
  //       // ],
  //       toolTip: {
  //         shared: true,
  //         // contentFormatter: this.toolTipFormatter,
  //       },
  //       axisY: {
  //         title: "",
  //         interval: 1,
  //       },
  //       data: [
  //         {
  //           type: "stepLine",
  //           name: "Production of Crude Steel",
  //           dataPoints: Jabfung.value,
  //         },
  //       ],
  //     };
  //   });
  //
  //   const styleOptions = computed(() => {
  //     return {
  //       width: "100%",
  //       height: "180px",
  //     };
  //   });
  //
  //   onMounted(() => {
  //     onJabfung;
  //   });
  //
  //   return {
  //     onJabfung,
  //     Jabfung,
  //     options,
  //     styleOptions,
  //   };
  // },
  // setup() {
  //   // Fake visitor data
  //   const reportBarChartData = new Array(6).fill(0).map((data, key) => {
  //     if (key % 3 == 0 || key % 5 == 0) {
  //       return Math.ceil(Math.random() * (0 - 20) + 20);
  //     } else {
  //       return Math.ceil(Math.random() * (0 - 7) + 7);
  //     }
  //   });
  //
  //   const store = useStore();
  //   const darkMode = computed(() => store.state.main.darkMode);
  //   const data = computed(() => {
  //     return {
  //       labels: ["2018", "2019", "2022", "2023"],
  //       datasets: [
  //         {
  //           label: "# of Votes",
  //           data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  //           borderWidth: 2,
  //           borderColor: "#3160D8",
  //           backgroundColor: "transparent",
  //           pointBorderColor: "transparent",
  //           tension: 0.1,
  //         },
  //         // {
  //         //   label: "# of Votes",
  //         //   data: [0, 300, 400, 560, 320, 600, 720, 850, 690, 805, 1200, 1010],
  //         //   borderWidth: 2,
  //         //   borderDash: [2, 2],
  //         //   borderColor: "#a0afbf",
  //         //   backgroundColor: "transparent",
  //         //   pointBorderColor: "transparent",
  //         // },
  //       ],
  //     };
  //   });
  //   const options = computed(() => {
  //     return {
  //       legend: {
  //         display: false,
  //       },
  //       scales: {
  //         xAxes: [
  //           {
  //             ticks: {
  //               fontSize: "12",
  //               fontColor: darkMode.value ? "#718096" : "#777777",
  //             },
  //             gridLines: {
  //               display: false,
  //             },
  //           },
  //         ],
  //         yAxes: [
  //           {
  //             ticks: {
  //               fontSize: "12",
  //               fontColor: darkMode.value ? "#718096" : "#777777",
  //             },
  //             gridLines: {
  //               color: darkMode.value ? "#718096" : "#D8D8D8",
  //               zeroLineColor: darkMode.value ? "#718096" : "#D8D8D8",
  //               borderDash: [2, 2],
  //               zeroLineBorderDash: [2, 2],
  //               drawBorder: false,
  //             },
  //           },
  //         ],
  //       },
  //     };
  //   });
  //
  //   return {
  //     data,
  //     options,
  //   };
  // },
  data() {
    return {
      chart: null,
      options: {
        theme: "light2",
        animationEnabled: false,
        exportEnabled: false,
        title: {
          text: "Riwayat Jabatan Fungsional (JJA)",
        },
        toolTip: {
          shared: true,
          contentFormatter: this.toolTipFormatter,
        },
        axisY: {
          title: "",
          interval: 1,
        },
        data: [
          {
            type: "stepLine",
            xValueFormatString: "####",
            showInLegend: true,
            name: "JJA",
          },
        ],
      },
      styleOptions: {
        width: "100%",
        height: "180px",
      },
      nama: null,
      kode: null,
      nidn: null,
      statusDosen: null,
      statusAktif: null,
      masaKerja: null,
      connected: false,
      connMsg: "Connecting SISTER...",
      JabatanFungsional: [],
      Pengabdian: 0,
      Penelitian: 0,
      loading: true,
    };
  },
  computed: {
    sisterconnect() {
      return this.$store.state.sisterpddikti.sisterconnect;
    },
    sisterserver() {
      return this.$store.state.sisterpddikti.sisterserver;
    },
    sistertoken() {
      return this.$store.state.sisterpddikti.sistertoken;
    },
    sistererror() {
      return this.$store.state.sisterpddikti.sistererror;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    dosen: {
      handler: function (data) {
        if (data?.NM_DOSEN !== undefined) {
          this.nama = data?.NM_DOSEN;
        }
        if (data?.KODE !== undefined) {
          this.kode = data?.KODE;
        }
        if (data?.STATUS_DOSEN !== undefined) {
          this.statusDosen = data?.STATUS_DOSEN === "1" ? "Tetap" : "LB";
        }

        if (data?.Aktif !== undefined) {
          this.statusAktif = data?.Aktif === "Y" ? "Aktif" : "Tidak Aktif";
        }

        if (data?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja) {
          var dateString =
            data?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja;
          var now = new Date();
          // var today = new Date(now.getYear(), now.getMonth(), now.getDate());

          var yearNow = now.getYear();
          var monthNow = now.getMonth();
          var dateNow = now.getDate();

          var dob = new Date(
            dateString.substring(4, 0),
            dateString.substring(5, 7) - 1,
            dateString.substring(4, 2)
          );

          var yearDob = dob.getYear();
          var monthDob = dob.getMonth();
          var dateDob = dob.getDate();
          var age = {};

          var yearAge = yearNow - yearDob;
          var monthAge = null;
          var dateAge = null;

          if (monthNow >= monthDob) {
            monthAge = monthNow - monthDob;
          } else {
            yearAge--;
            monthAge = 12 + monthNow - monthDob;
          }

          if (dateNow >= dateDob) {
            dateAge = dateNow - dateDob;
          } else {
            monthAge--;
            dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
              monthAge = 11;
              yearAge--;
            }
          }

          age = {
            years: yearAge,
            months: monthAge,
            days: dateAge,
          };

          this.masaKerja = age;
        }

        if (data?.sdm?.id_sdm !== undefined) {
          this.loading = true;

          if (data?.jab_fung) {
            let index = data?.jab_fung.sort().reverse();

            let arr = [];

            for (var i = 0; i < index.length; i++) {
              var n = new Date();
              var d = new Date(index[i].tanggal_mulai);
              // var nd = n.getFullYear() - d.getFullYear();

              if (d.getFullYear() < n.getFullYear()) {
                for (var x = d.getFullYear(); x <= n.getFullYear(); x++) {
                  arr.push({
                    label: x.toString(),
                    y: 1 + i,
                    indexLabel: index[i].jabatan_fungsional,
                    indexLabel2: index[i].angka_kredit,
                    x: x,
                  });
                }
              }
            }

            // removing duplicate [object]
            const obj = arr.reduce((acc, cur) => {
              acc[cur.label] = { index: cur };
              return acc;
            }, []);

            this.JabatanFungsional = Object.values(obj)
              .sort((a, b) => a.index - b.index)
              .map(({ index: val }) => val);
          }

          if (data?.jab_fung) {
            // data?.jab_fung.forEach((datas) => {
            let index = data?.jab_fung.sort();

            let arr = [];

            for (var j = 0; j < index.length; j++) {
              var f = new Date();
              var w = new Date(index[j].tmt_sk_jabfung);
              // var nd = n.getFullYear() - d.getFullYear();

              if (w.getFullYear() < f.getFullYear()) {
                for (var y = w.getFullYear(); y <= f.getFullYear(); y++) {
                  arr.push({
                    label: y.toString(),
                    y: 1 + j,
                    indexLabel: index[j].jabfung.nm_jabfung,
                    indexLabel2: index[j].jabfung.angka_kredit,
                    x: y,
                  });
                }
              }
            }

            // removing duplicate [object]
            const obj = arr.reduce((acc, cur) => {
              acc[cur.label] = { index: cur };
              return acc;
            }, []);

            this.JabatanFungsional = Object.values(obj)
              .sort((a, b) => a.index - b.index)
              .map(({ index: val }) => val);
          }
          // });
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async setchartRef(chart) {
      if (!this.loading) {
        chart.options.data[0].dataPoints = this.JabatanFungsional;
        chart.render();
      }
    },
    toolTipFormatter(e) {
      var content = "";
      content =
        "<strong>" +
        e.entries[0].dataPoint.label +
        " (" +
        e.entries[0].dataPoint.indexLabel2 +
        ")</strong> <br/>";

      return content;
    },
  },
};
</script>

<style scoped></style>
