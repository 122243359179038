<template>
  <Teleport to="#lockScreen">
    <div
      class="absolute top-0 h-screen w-screen bg-lock flex justify-center items-center"
    >
      <div
        class="bg-white rounded-2xl w-11/12 md:w-8/12 lg:w-5/12 xl:w-4/12 p-5 grid grid-cols-5 space-x-5 items-center text-dark-2"
      >
        <div class="col-span-2 overflow-hidden rounded-2xl">
          <img :src="profilePicture" class="w-full" />
        </div>
        <div class="col-span-3 flex flex-col space-y-2">
          <div>
            <h6 class="font-semibold text-2xl">
              {{ userData?.name }}
            </h6>
            <div class="font-bold text-gray-600">
              {{ currentTeam?.name }}
            </div>
          </div>
          <div class="w-full">
            <input
              v-model="model.password"
              type="password"
              class="w-full rounded-md p-2 text-center ring ring-black text-dark-1 placeholder-black focus:ring focus:ring-primary-1 focus:outline-none"
              placeholder="Masukkan kata sandi"
            />
            <div
              v-if="errorMessage"
              class="text-pink-500 mt-2 font-semibold text-center"
            >
              {{ errorMessage }}
            </div>
          </div>
          <button
            :disabled="isLoading"
            class="w-full bg-primary-1 text-white font-semibold rounded-md py-2"
            @click="signIn"
          >
            {{ isLoading ? "Loading..." : "Masuk" }}
          </button>
          <button
            :disabled="isLoading"
            class="w-full bg-gray-300 text-dark-2 font-medium rounded-md py-2"
            @click="logout"
          >
            Keluar
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { cpname } from "/src/libs/computer-name";
export default {
  data() {
    return {
      isLoading: false,
      profilePicture: "https://i.pravatar.cc/300",
      userData: this.$store.state.userdata?.userData,
      currentTeam: this.$store.state.userdata?.currentTeam,
      errorMessage: null,
      model: {
        uid: this.$store.state.userdata?.userData?.uid,
        password: null,
        apps: `${process.env.VUE_APP_NAME}@${cpname()}:${process.platform}`,
      },
    };
  },
  mounted() {
    this.subscribeLoading();
    this.profilePicture =
      this.$store.state.userdata?.userData?.profile_photo_url;
    document.getElementsByTagName("body")[0].classList.remove("main");
    document.getElementsByTagName("body")[0].classList.add("p-0");
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/LogOut");
      this.$store.dispatch("LockScreenStore/clear");
    },
    signIn() {
      this.errorMessage = null;
      this.$store.dispatch("LockScreenStore/unlockScreen", this.model);
    },
    subscribeLoading() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "LockScreenStore/isLoading") {
          this.isLoading = state.LockScreenStore.isLoading;
          this.errorMessage = state.LockScreenStore.errorMessage;
        }
      });
    },
  },
};
</script>

<style scoped>
.bg-lock {
  //background-color: #023047;
  color: white;
  z-index: 100;
}
</style>
