<template>
  <div
    class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5"
  >
    <div class="flex flex-1 items-center justify-center lg:justify-start">
      <div
        v-if="showContact"
        class="w-32 h-32 flex-none image-fit relative sm:w-24 sm:h-24 lg:w-48 lg:h-48"
      >
        <img
          alt="Icewall Tailwind HTML Admin Template"
          class="rounded-full"
          src="https://api.mdp.ac.id/public/assets/logo/logo-umdp.png"
        />
      </div>
      <div :class="showContact ? 'ml-6' : ''">
        <div
          v-if="tendik?.sdm?.nm_sdm"
          class="sm:whitespace-normal font-medium mb-1"
        >
          {{ tendik?.sdm === null ? nama : tendik?.sdm?.nm_sdm }}
        </div>
        <div v-if="nama" class="sm:whitespace-normal font-medium mb-1">
          {{ nama }} <strong>({{ kode !== null ? kode : "" }})</strong>
        </div>
        <div class="text-gray-600 mb-3">
          <div class="font-medium">
            {{ statusTendik }}
          </div>
          <div class="font-medium">
            {{ tendik?.sms?.nm_lemb }}
          </div>
        </div>

        <div class="border-t border-gray-200 pt-4 dark:border-dark-5 mt-2">
          <span
            v-if="statusAktif || tendik?.sdm"
            class="px-3 py-1 text-white rounded text-xs mr-2 bg-blue-800"
          >
            {{
              statusAktif
                ? statusAktif
                : tendik?.sdm.status_keaktifan_pegawai.nm_stat_aktif
            }}
          </span>

          <span
            v-if="tendik?.sdm?.nidn || tendik?.NITK"
            class="px-3 py-1 mr-2 text-white rounded text-xs bg-blue-600"
          >
            {{ tendik?.NITK ? tendik?.NITK : tendik?.sdm?.nidn }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="flex flex-1 items-center justify-center lg:justify-start mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0 border-l border-r"
    >
      <div
        class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-r"
      >
        <div class="text-center rounded-md py-3">
          <div class="text-theme-1 font-medium">Masa Kerja</div>
          <div
            v-if="masaKerja"
            class="font-medium text-blue-800 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            {{ masaKerja.years }} Tahun
            <span class="text-blue-500">{{ masaKerja.months }} Bulan</span>
          </div>
          <div
            v-else
            class="font-medium text-blue-800 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            -
          </div>
          <div
            v-if="tendik?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_1"
            class="text-gray-600 text"
          >
            ({{
              tendik?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_1
                ? $h.tanggal(
                    tendik?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_1
                  )
                : $h.tanggal(
                    tendik?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja
                  )
            }}
            - Present)
          </div>
        </div>
      </div>
      <div class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5">
        <div class="text-center rounded-md py-3">
          <div class="text-theme-1 font-medium">Teguran/Peringatan</div>
          <div
            class="font-medium text-red-500 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            -
          </div>
          <div
            class="font-medium text-theme-9 py-1 pb-2 dark:text-theme-10 text-2xl"
          >
            Tidak Ada Teguran
          </div>
          <div class="text-gray-600 text">(-)</div>
          <a
            data-toggle="tab"
            data-target="#data-sp-tab"
            href="javascript:"
            role="tab"
            aria-selected="false"
            class="transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer btn-warning btn-sm font-medium w-32 py-1 px-2 mt-3 mr-2 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary dark:border-primary"
          >
            <span
              ><radio-icon class="w-4 h-4 mr-1"></radio-icon> Beri Teguran</span
            >
          </a>
        </div>
      </div>
    </div>

    <!--    <div-->
    <!--      v-if="showContact"-->
    <!--      class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"-->
    <!--      :class="showMoreInfo ? 'border-l border-r' : 'border-l '"-->
    <!--    >-->
    <!--      <div class="font-medium text-center lg:text-left lg:mt-3">Kontak</div>-->
    <!--      <div-->
    <!--        class="flex flex-col justify-center items-center lg:items-start mt-4"-->
    <!--      >-->
    <!--        <div-->
    <!--          v-if="tendik?.sdm?.email || tendik?.EMAIL"-->
    <!--          class="truncate sm:whitespace-normal flex items-center mb-3"-->
    <!--        >-->
    <!--          <MailIcon class="w-4 h-4 mr-2" />-->
    <!--          {{ tendik?.sdm?.email ? tendik?.sdm?.email : "" }}-->
    <!--          <span class="text-gray-600 ml-2">-->
    <!--            {{ tendik?.EMAIL == tendik?.sdm?.no_hp ? "" : tendik?.EMAIL }}-->
    <!--          </span>-->
    <!--        </div>-->
    <!--        <div class="truncate sm:whitespace-normal flex items-center">-->
    <!--          <PhoneIcon class="w-4 h-4 mr-2" />-->
    <!--          {{ tendik?.sdm?.no_hp ? tendik?.sdm?.no_hp : "" }}-->
    <!--          <span class="text-gray-600 ml-2">-->
    <!--            {{ tendik?.HP == tendik?.sdm?.no_hp ? "-" : tendik?.HP }}-->
    <!--          </span>-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="tendik?.sdm?.no_tel_rmh"-->
    <!--          class="truncate sm:whitespace-normal flex items-center mt-3"-->
    <!--        >-->
    <!--          <PhoneCallIcon class="w-4 h-4 mr-2" />-->
    <!--          {{ tendik?.sdm?.no_tel_rmh ? tendik?.sdm?.no_tel_rmh : "" }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      v-if="showMoreInfo"-->
    <!--      class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"-->
    <!--    >-->
    <!--      <div class="text-center rounded-md w-20 py-3">-->
    <!--        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">-->
    <!--          N/A-->
    <!--        </div>-->
    <!--        <div class="text-gray-600">Jabatan</div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      class="mt-6 lg:mt-0 flex-1 flex text-gree items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"-->
    <!--    >-->
    <!--      <div class="p-3" :class="connected ? 'text-theme-9' : 'text-gray-400'">-->
    <!--        <BatteryChargingIcon class="w-5 h-5 mr-2" /> {{ connMsg }}-->
    <!--      </div>-->
    <!--    </div>-->

    <div
      v-if="showMoreInfo && !loading"
      class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0 border-r"
    >
      <CanvasJSChart
        :options="options"
        :styles="styleOptions"
        @chart-ref="setchartRef"
      />
    </div>
  </div>
</template>

<script>
import { wssister } from "@/services/wspddikti";

export default {
  props: {
    showContact: {
      default: true,
      type: Boolean,
    },
    showMoreInfo: {
      default: true,
      type: Boolean,
    },
    tendik: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
      options: {
        theme: "light2",
        animationEnabled: false,
        exportEnabled: false,
        title: {
          text: "Riwayat Jabatan Fungsional (JJA)",
        },
        toolTip: {
          shared: true,
          contentFormatter: this.toolTipFormatter,
        },
        axisY: {
          title: "",
          interval: 1,
        },
        data: [
          {
            type: "stepLine",
            xValueFormatString: "####",
            showInLegend: true,
            name: "JJA",
          },
        ],
      },
      styleOptions: {
        width: "100%",
        height: "180px",
      },
      nama: null,
      kode: null,
      nidn: null,
      statusTendik: null,
      statusAktif: null,
      masaKerja: null,
      connected: false,
      connMsg: "SISTER not connected...",
      JabatanFungsional: [],
      Pengabdian: 0,
      Penelitian: 0,
      loading: true,
    };
  },
  computed: {
    sisterconnect() {
      return this.$store.state.sisterpddikti.sisterconnect;
    },
    sisterserver() {
      return this.$store.state.sisterpddikti.sisterserver;
    },
    sistertoken() {
      return this.$store.state.sisterpddikti.sistertoken;
    },
    sistererror() {
      return this.$store.state.sisterpddikti.sistererror;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    tendik: {
      handler: function (data) {
        if (data?.NM_TENDIK !== undefined) {
          this.nama = data?.NM_TENDIK;
        }
        if (data?.KODE !== undefined) {
          this.kode = data?.KODE;
        }
        if (data?.STATUS_TENDIK !== undefined) {
          this.statusTendik =
            data?.STATUS_TENDIK === "1" ? "Karyawan Tetap" : "Karyawan Kontrak";
        }

        if (data?.Aktif !== undefined) {
          this.statusAktif = data?.Aktif === "Y" ? "Aktif" : "Tidak Aktif";
        }

        if (data?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_1) {
          var dateString =
            data?.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kontrak_1;
          var now = new Date();
          // var today = new Date(now.getYear(), now.getMonth(), now.getDate());

          var yearNow = now.getYear();
          var monthNow = now.getMonth();
          var dateNow = now.getDate();

          var dob = new Date(
            dateString.substring(4, 0),
            dateString.substring(5, 7) - 1,
            dateString.substring(4, 2)
          );

          var yearDob = dob.getYear();
          var monthDob = dob.getMonth();
          var dateDob = dob.getDate();
          var age = {};

          var yearAge = yearNow - yearDob;
          var monthAge = null;
          var dateAge = null;

          if (monthNow >= monthDob) {
            monthAge = monthNow - monthDob;
          } else {
            yearAge--;
            monthAge = 12 + monthNow - monthDob;
          }

          if (dateNow >= dateDob) {
            dateAge = dateNow - dateDob;
          } else {
            monthAge--;
            dateAge = 31 + dateNow - dateDob;

            if (monthAge < 0) {
              monthAge = 11;
              yearAge--;
            }
          }

          age = {
            years: yearAge,
            months: monthAge,
            days: dateAge,
          };

          this.masaKerja = age;
        }

        if (data?.sdm?.id_sdm !== undefined) {
          this.loading = true;

          wssister
            .GetToken()
            .then((result) => {
              if (result === true) {
                this.connected = true;
                // this.connMsg =
                //   "Connected to Server " +
                //   this.sisterserver().toUpperCase() +
                //   " SISTER";
                this.connMsg = "Connected to Server SISTER";
              } else {
                this.connected = false;
              }
            })
            .finally(() => {
              wssister
                .GetData("jabatan_fungsional", {
                  kd_tendik: this.kode,
                })
                .then((res) => {
                  if (res.data) {
                    let index = res.data.sort().reverse();

                    let arr = [];

                    for (var i = 0; i < index.length; i++) {
                      var n = new Date();
                      var d = new Date(index[i].tanggal_mulai);
                      // var nd = n.getFullYear() - d.getFullYear();

                      if (d.getFullYear() < n.getFullYear()) {
                        for (
                          var x = d.getFullYear();
                          x <= n.getFullYear();
                          x++
                        ) {
                          arr.push({
                            label: x.toString(),
                            y: 1 + i,
                            indexLabel: index[i].jabatan_fungsional,
                            x: x,
                          });
                        }
                      }
                    }

                    // removing duplicate [object]
                    const obj = arr.reduce((acc, cur) => {
                      acc[cur.label] = { index: cur };
                      return acc;
                    }, []);

                    this.JabatanFungsional = Object.values(obj)
                      .sort((a, b) => a.index - b.index)
                      .map(({ index: val }) => val);
                  }

                  this.loading = false;
                });
            });
        }
      },
      immediate: true,
    },
  },
  methods: {
    async setchartRef(chart) {
      if (!this.loading) {
        chart.options.data[0].dataPoints = this.JabatanFungsional;
        chart.render();
      }
    },
    toolTipFormatter(e) {
      var content = "";
      content =
        "<strong>" +
        e.entries[0].dataPoint.label +
        " (" +
        e.entries[0].dataPoint.indexLabel +
        ")</strong> <br/>";

      return content;
    },
    // async getmasaKerja(datestring) {
    //   console.log(dateString);
    //
    //   var now = new Date();
    //   var today = new Date(now.getYear(), now.getMonth(), now.getDate());
    //
    //   var yearNow = now.getYear();
    //   var monthNow = now.getMonth();
    //   var dateNow = now.getDate();
    //
    //   var dob = new Date(
    //     dateString.substring(6, 10),
    //     dateString.substring(0, 2) - 1,
    //     dateString.substring(3, 5)
    //   );
    //
    //   var yearDob = dob.getYear();
    //   var monthDob = dob.getMonth();
    //   var dateDob = dob.getDate();
    //   var age = {};
    // },
  },
};
</script>
