import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { abilities } from "@/utils/ability";
import { routes as recruitmentroutes } from "./recruitment";
import { routes as sdmroutes } from "./sdm";
import { routes as syncroutes } from "./sync";
import { routes as staffroutes } from "./staff";
import { viewList } from "./view-list";

import Auth from "../views/auth/Main.vue";
import DashboardMDP from "../views/dashboard-mdp/Main.vue";
import Layout from "@/layouts/menu/Main";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Auth,
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: DashboardMDP,
        meta: {
          requiresAuth: true,
          general: true,
        },
      },
    ],
  },
  recruitmentroutes,
  sdmroutes,
  syncroutes,
  staffroutes,
];

const router = createRouter({
  mode: "history",
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});
viewList.forEach((list) => {
  // let ability = list?.ability != undefined ? list.ability : list.path;

  router.addRoute(list.parent, {
    path: `/${list.path}/list`,
    name: `list-${list.path}`,
    component: list.list,
    meta: {
      requiresAuth: true,
      general: false,
      // ability: [ability + ":list"],
      name: "Lihat " + list.path.toUpperCase(),
    },
  });
  router.addRoute(list.parent, {
    path: `/${list.path}/:id/edit`,
    name: `edit-${list.path}`,
    component: list.edit,
    meta: {
      requiresAuth: true,
      general: false,
      // ability: [ability + ":update", ability + ":edit"],
      name: "Edit " + list.path.toUpperCase(),
    },
  });
  router.addRoute(list.parent, {
    path: `/${list.path}/new`,
    name: `create-${list.path}`,
    component: list.create,
    meta: {
      requiresAuth: true,
      general: false,
      // ability: [ability + ":create", ability + ":new"],
      name: "Create " + list.path.toUpperCase(),
    },
  });
  router.addRoute(list.parent, {
    path: `/${list.path}/:id/view`,
    name: `view-${list.path}`,
    component: list.view,
    meta: {
      requiresAuth: true,
      general: false,
      // ability: [ability + ":view", ability],
      name: "Lihat " + list.path.toUpperCase(),
    },
  });
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const loginDone = store.getters["auth/loginDone"];
  const u_abs = store.getters["auth/abilities"];
  if (to.matched.some((record) => record?.meta?.requiresAuth)) {
    if (!isAuthenticated)
      next({ name: "login", query: { redirect: to.fullPath } });
    else if (!loginDone)
      next({ name: "login", query: { redirect: to.fullPath } });
    else if (u_abs !== null && u_abs !== "") {
      const su = abilities.isSuperAdmin(u_abs, to);
      //const access = su ? su : abilities.cekAbility(u_abs, to, next);
      const access = su
        ? su
        : to.matched.some((r) => !r?.meta?.general) &&
          u_abs.filter((e) => to.meta?.ability?.indexOf(e) !== -1).length > 0;
      if (to.name !== "dashboard" && !access) {
        store.dispatch(
          "main/responseMessage",
          "Maaf anda tidak memiliki hak akses ke halamam/fitur " +
            (to.meta?.name ? to.meta?.name : "tersebut!")
        );
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else next();
  } else {
    if (!isAuthenticated && to.name !== "login")
      next({ name: "login", query: { redirect: to.fullPath } });
    else if (to.name !== "login" && to.name !== "dashboard" && isAuthenticated)
      next({ name: "dashboard" });
    else next();
  }
});

router.beforeResolve(async (to) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const isExpire = store.getters["auth/isExpire"];
  const isSessionLocked = store.getters["auth/isSessionLocked"];
  // const isAutoExtend = store.getters["auth/isAutoExtend"];

  if (to.name !== "login" && isAuthenticated)
    if (!isSessionLocked && isExpire) store.dispatch("auth/RefreshToken");

  store.dispatch("auth/CSRF");
});

router.afterEach(() => {});

export default router;
