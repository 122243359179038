<template>
  <form action="" @submit.prevent="submit">
    <div
      v-if="newRecruitment"
      class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-5"
    >
      <h2 class="text-lg font-medium mr-auto">Rekrutmen Pegawai Baru</h2>
      <router-link :to="{ name: 'recruitment' }" class="btn btn-success ml-auto"
        >List Rekrutmen</router-link
      >
    </div>
    <div class="intro-y">
      <div>
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div v-if="alertFailed">
          <AlertFailed
            v-for="(item, index) in messageAlert"
            :key="index"
            :message="item"
            :dismissable="true"
          />
        </div>
      </div>
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
          <div class="intro-y" :class="newRecruitment ? 'box' : ''">
            <!--            <div-->
            <!--              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"-->
            <!--            >-->
            <!--              <h2 class="font-medium text-base mr-auto">Biodata Diri</h2>-->
            <!--            </div>-->
            <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-52">Nama Lengkap</label>
                <input
                  v-model="dataRekrutmen['nm']"
                  class="form-control focus:ring-0"
                  placeholder="Nama Lengkap"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Jenis Kelamin</label>
                <div class="flex flex-col sm:flex-row">
                  <div
                    class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2"
                  >
                    <input
                      id="radio-switch-1"
                      v-model="dataRekrutmen['jk']"
                      class="form-check-input"
                      type="radio"
                      value="L"
                    />
                    <label class="form-check-label" for="radio-switch-1"
                      >Laki-Laki</label
                    >
                  </div>
                  <div
                    class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2 mt-2 sm:mt-0"
                  >
                    <input
                      id="radio-switch-2"
                      v-model="dataRekrutmen['jk']"
                      class="form-check-input"
                      type="radio"
                      value="P"
                    />
                    <label class="form-check-label" for="radio-switch-2"
                      >Perempuan</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Tanggal Lahir</label>
                <input
                  v-model="dataRekrutmen['tgl_lahir']"
                  type="date"
                  class="form-control focus:ring-0 mt-2"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Tempat Lahir</label>
                <input
                  v-model="dataRekrutmen['tmpt_lahir']"
                  class="form-control focus:ring-0"
                  placeholder="Kota Kelahiran"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52"
                  >Golongan Darah</label
                >
                <input
                  v-model="dataRekrutmen['gol_darah']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Golongan Darah"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Agama</label>
                <TailSelect
                  v-model="dataRekrutmen['agama']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_agama"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Alamat</label>
                <input
                  v-model="dataRekrutmen['alamat']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Alamat tempat tinggal"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium w-52">Kelurahan</label>
                <input
                  v-model="dataRekrutmen['kel']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Kelurahan"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium">Kecamatan</label>
                <input
                  v-model="dataRekrutmen['kec']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Kecamatan"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium">Kode Pos</label>
                <input
                  v-model="dataRekrutmen['kode_pos']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Kode Pos"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Nomor HP/Telp</label>
                <input
                  v-model="dataRekrutmen['no_hp']"
                  type="text"
                  maxlength="20"
                  class="form-control focus:ring-0"
                  placeholder="Nomor HP: 08XX"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Email</label>
                <input
                  v-model="dataRekrutmen['email']"
                  type="email"
                  class="form-control focus:ring-0"
                  placeholder="Email"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">NIK</label>
                <input
                  v-model="dataRekrutmen['nik']"
                  type="number"
                  maxlength="16"
                  class="form-control focus:ring-0"
                  placeholder="NIK"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">NPWP</label>
                <input
                  v-model="dataRekrutmen['npwp']"
                  type="number"
                  maxlength="16"
                  class="form-control focus:ring-0"
                  placeholder="NPWP"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52"
                  >Kewarganegaraan</label
                >
                <TailSelect
                  v-model="dataRekrutmen['kewarganegaraan']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_negara"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Wilayah</label>
                <TailSelect
                  v-model="dataRekrutmen['id_wil']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_wilayah"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52"
                  >Pangkat Golongan</label
                >
                <TailSelect
                  v-model="dataRekrutmen['id_pangkat_gol']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_pangkatgolongan"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <!--              <div class="col-span-6 mt-4 form-inline">-->
              <!--                <label class="form-label font-medium w-52"-->
              <!--                  >Tanggal Wawancara</label-->
              <!--                >-->
              <!--                <input-->
              <!--                  v-model="dataRekrutmen['tgl_wawancara']"-->
              <!--                  type="date"-->
              <!--                  class="form-control focus:ring-0 mt-2"-->
              <!--                  autocomplete="off"-->
              <!--                />-->
              <!--              </div>-->
              <!--              <div class="col-span-6 mt-4 form-inline">-->
              <!--                <label class="form-label font-medium w-52">Tanggal Tes</label>-->
              <!--                <input-->
              <!--                  v-model="dataRekrutmen['tgl_tes']"-->
              <!--                  type="date"-->
              <!--                  class="form-control focus:ring-0 mt-2"-->
              <!--                  autocomplete="off"-->
              <!--                />-->
              <!--              </div>-->
            </div>
          </div>
          <div class="flex justify-end flex-col md:flex-row gap-2 mt-5">
            <button
              type="button"
              class="btn py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
            >
              Cancel</button
            ><button
              v-if="newRecruitment"
              type="reset"
              class="btn py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
            >
              Reset</button
            ><button type="submit" class="btn py-3 btn-primary w-full md:w-52">
              <SaveIcon class="w-4 h-4 mr-2" /> Simpan
            </button>
          </div>
        </div>
        <!--        <div-->
        <!--          v-if="newRecruitment"-->
        <!--          class="col-span-4 2xl:col-span-4 -mb-10 pb-10"-->
        <!--          :class="newRecruitment ? '2xl:border-l ' : ''"-->
        <!--        >-->
        <!--          <div class="pl-5">-->
        <!--            <div class="intro-y box p-8">-->
        <!--              <div>-->
        <!--                <label class="form-label font-medium">Tanggal Wawancara</label>-->
        <!--                <input-->
        <!--                  v-model="dataRekrutmen['tgl_wawancara']"-->
        <!--                  type="date"-->
        <!--                  class="form-control focus:ring-0 mt-2"-->
        <!--                  autocomplete="off"-->
        <!--                />-->
        <!--                &lt;!&ndash;                <Litepicker&ndash;&gt;-->
        <!--                &lt;!&ndash;                  v-model="dataRekrutmen['tgl_wawancara']"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  :options="{&ndash;&gt;-->
        <!--                &lt;!&ndash;                    format: 'YYYY/MM/DD',&ndash;&gt;-->
        <!--                &lt;!&ndash;                    autoApply: false,&ndash;&gt;-->
        <!--                &lt;!&ndash;                    showWeekNumbers: true,&ndash;&gt;-->
        <!--                &lt;!&ndash;                    dropdowns: {&ndash;&gt;-->
        <!--                &lt;!&ndash;                      minYear: new Date().getFullYear() - 5,&ndash;&gt;-->
        <!--                &lt;!&ndash;                      maxYear: new Date().getFullYear() + 1,&ndash;&gt;-->
        <!--                &lt;!&ndash;                      months: true,&ndash;&gt;-->
        <!--                &lt;!&ndash;                      years: true,&ndash;&gt;-->
        <!--                &lt;!&ndash;                    },&ndash;&gt;-->
        <!--                &lt;!&ndash;                  }"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  class="form-control"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  placeholder="Tanggal Wawancara"&ndash;&gt;-->
        <!--                &lt;!&ndash;                />&ndash;&gt;-->
        <!--              </div>-->
        <!--              <div class="mt-4">-->
        <!--                <label class="form-label font-medium">Tanggal Tes</label>-->
        <!--                <input-->
        <!--                  v-model="dataRekrutmen['tgl_tes']"-->
        <!--                  type="date"-->
        <!--                  class="form-control focus:ring-0 mt-2"-->
        <!--                  autocomplete="off"-->
        <!--                />-->
        <!--                &lt;!&ndash;                <Litepicker&ndash;&gt;-->
        <!--                &lt;!&ndash;                  v-model="dataRekrutmen['tgl_tes']"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  :options="{&ndash;&gt;-->
        <!--                &lt;!&ndash;                    format: 'YYYY/MM/DD',&ndash;&gt;-->
        <!--                &lt;!&ndash;                    autoApply: false,&ndash;&gt;-->
        <!--                &lt;!&ndash;                    showWeekNumbers: true,&ndash;&gt;-->
        <!--                &lt;!&ndash;                    dropdowns: {&ndash;&gt;-->
        <!--                &lt;!&ndash;                      minYear: new Date().getFullYear() - 5,&ndash;&gt;-->
        <!--                &lt;!&ndash;                      maxYear: new Date().getFullYear() + 1,&ndash;&gt;-->
        <!--                &lt;!&ndash;                      months: true,&ndash;&gt;-->
        <!--                &lt;!&ndash;                      years: true,&ndash;&gt;-->
        <!--                &lt;!&ndash;                    },&ndash;&gt;-->
        <!--                &lt;!&ndash;                  }"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  class="form-control"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  placeholder="Tanggal Tes"&ndash;&gt;-->
        <!--                &lt;!&ndash;                />&ndash;&gt;-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="w-full flex mt-4 sm:w-auto">-->
        <!--              &lt;!&ndash;              <button&ndash;&gt;-->
        <!--              &lt;!&ndash;                class="btn box mr-2 flex items-center ml-auto sm:ml-0"&ndash;&gt;-->
        <!--              &lt;!&ndash;                type="button"&ndash;&gt;-->
        <!--              &lt;!&ndash;              >&ndash;&gt;-->
        <!--              &lt;!&ndash;                <Edit3Icon class="w-4 h-4 mr-2" /> Reset&ndash;&gt;-->
        <!--              &lt;!&ndash;              </button>&ndash;&gt;-->
        <!--              <button class="btn btn-primary w-24 w-full" type="submit">-->
        <!--                <SaveIcon class="w-4 h-4 mr-2" />Simpan-->
        <!--              </button>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--          <div class="col-span-12 2xl:col-span-0"></div>-->
      </div>
    </div>
  </form>
</template>

<script>
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { ref, sdm } from "@/services/models";
// import { userdata, getuserdata } from "@/utils/userdata";
import moment from "moment";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  name: "FormRecruitment",
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  props: {
    newRecruitment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      dataRekrutmen: {
        nm: null,
        jk: null,
        tgl_lahir: null,
        tmpt_lahir: null,
        nik: null,
        agama: null,
        alamat: null,
        kel: null,
        kec: null,
        kode_pos: null,
        stat_kawin: null,
        no_hp: null,
        email: null,
        npwp: null,
        kewarganegaraan: null,
        id_wil: null,
        gol_darah: null,
        id_pangkat_gol: null,
      },
      ref_pangkatgolongan: [],
      ref_agama: [],
      ref_negara: [],
      ref_wilayah: [],
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      isLoading: false,
      useState: false,
    };
  },
  mounted() {
    this.getDetailRekrutmen();
    this.getPangkatGolongan();
    this.getAgama();
    this.getNegara();
    this.getWilayah();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailRekrutmen() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.Rekrutmen.getCustomPath(`getDetailRekrutmen/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.dataRekrutmen = res.data.data;

                // beda tipe data
                this.dataRekrutmen.agama = res.data.data?.agama
                  ? res.data.data?.agama.toString()
                  : "";

                this.$emit("detailDataRekrutmen", this.dataRekrutmen);
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async getPangkatGolongan() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Pangkat Golongan",
        selected: true,
      };
      this.ref_pangkatgolongan.push(defaultOpt);
      ref.pangkatgolongan.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_pangkatgolongan.push({
              id: element.id_pangkat_gol,
              value: element.id_pangkat_gol,
              text: element.nm_pangkat + " (" + element.kode_gol + ")",
              selected: false,
            });
          });
        }
      });
    },
    async getAgama() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Agama",
        selected: true,
      };
      this.ref_agama.push(defaultOpt);
      ref.agama.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_agama.push({
              id: element.id,
              value: element.id,
              text: element.name,
              selected: false,
            });
          });
        }
      });
    },
    async getNegara() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Negara",
        selected: true,
      };
      this.ref_negara.push(defaultOpt);
      ref.negara.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_negara.push({
              id: element.id_negara,
              value: element.id_negara,
              text: element.nm_negara,
              selected: false,
            });
          });
        }
      });
    },
    async getWilayah() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Wilayah",
        selected: true,
      };
      this.ref_wilayah.push(defaultOpt);
      ref.wilayah.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_wilayah.push({
              id: element.id_wil,
              value: element.id_wil,
              text: element.nm_wil,
              selected: false,
            });
          });
        }
      });
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = [];
      this.isLoading = true;

      if (this.id?.length > 0) {
        sdm.Rekrutmen.updateData(this.id, this.dataRekrutmen).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.alertSuccess = true;
              this.messageAlert = res.data.message;
            } else {
              this.alertFailed = true;
              Object.keys(res.data.message).forEach((key) => {
                this.messageAlert.push(res.data.message[key][0]);
              });
            }
          }
          this.isLoading = false;
          this.scrollToTop();
        });
      }
      // else {
      //   sdm.Rekrutmen.insertData(this.dataRekrutmen).then((res) => {
      //     if (res.status === 200) {
      //       if (res.data.code === 200) {
      //         this.alertSuccess = true;
      //         this.messageAlert = res.data.message;
      //       } else {
      //         this.alertFailed = true;
      //         Object.keys(res.data.message).forEach((key) => {
      //           this.messageAlert.push(res.data.message[key][0]);
      //         });
      //       }
      //     }
      //     this.isLoading = false;
      //     this.scrollToTop();
      //   });
      // }
    },
  },
};
</script>
