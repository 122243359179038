<template>
  <div
    :class="
      bgClass === 'bg-gray-50'
        ? bgClass + ' dark:bg-dark-6'
        : bgClass + ' dark:bg-dark-3'
    "
    class="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
  >
    <dt class="text-sm text-gray-500 dark:text-gray-300 mr-3">
      {{ dt }}
    </dt>
    <dd class="mt-1 dark:text-white sm:mt-0 sm:col-span-2">
      <div v-if="dd?.length > 0">{{ dd }}</div>
      <slot name="dd"></slot>
    </dd>
  </div>
</template>

<script>
export default {
  name: "DataList",
  props: {
    dt: {
      type: String,
      default: "",
    },
    dd: {
      type: String,
      default: "",
    },
    white: {
      type: Boolean,
      default: true,
    },
    gray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bgClass:
        this.white === true && this.gray === false
          ? "bg-white"
          : this.white === false
          ? "bg-gray-50"
          : "bg-white",
    };
  },
};
</script>

<style scoped></style>
