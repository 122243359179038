<template>
  <div v-if="loading" class="p-5">Loading data...</div>
  <div v-else class="intro-y p-5 col-span-12 lg:col-span-6">
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-12">
        <DataList :white="true" :dd="sdm.nm_sdm" :dt="'Nama SDM'"></DataList>
        <DataList
          :white="true"
          :dd="$h.jenisKelamin(sdm.jk)"
          :dt="'Jenis Kelamin'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            sdm.jln +
            ' (RT ' +
            parseFloat(sdm.rt) +
            ', RW ' +
            parseFloat(sdm.rw) +
            ')'
          "
          :dt="'Alamat'"
        >
        </DataList>
        <DataList :white="true" :dd="sdm.nm_dsn" :dt="'Dusun'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.ds_kel"
          :dt="'Desa/Kelurahan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.nm_wil"
          :dt="'Kota/Kabupaten'"
        ></DataList>
        <DataList :white="true" :dd="sdm.nm_wil" :dt="'Provinsi'"></DataList>
        <DataList :white="true" :dd="sdm.kode_pos" :dt="'KODE POS'"></DataList>
        <DataList :white="true" :dd="sdm.no_hp" :dt="'No HP'"></DataList>
        <DataList :white="true" :dd="sdm.no_tel_rmh" :dt="'No Telp'"></DataList>
        <DataList :white="true" :dd="sdm.email" :dt="'Email'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.tmpt_lahir + ', ' + $h.tanggal(sdm.tgl_lahir)"
          :dt="'Tempat/Tanggal Lahir'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.nm_ibu_kandung"
          :dt="'Nama Ibu Kandung'"
        ></DataList>
        <DataList :white="true" :dd="sdm.nik" :dt="'NIK'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.agama?.nm_agama"
          :dt="'Agama'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.negara?.nm_negara"
          :dt="'Kewarganegaran'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList
          :white="true"
          :dd="$h.statKawin(sdm.stat_kawin)"
          :dt="'Status Perkawinan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.nm_suami_istri"
          :dt="'Nama Suami/Istri'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.nip_suami_istri"
          :dt="'NIP Suami/Istri'"
        ></DataList>
        <DataList
          :white="true"
          :dd="sdm.pekerjaan_suami_istri?.nm_pekerjaan"
          :dt="'Pekerjaan Suami/Istri'"
        ></DataList>
        <DataList
          :white="true"
          dd="-"
          :dt="'Terhitung Mulai Tanggal PNS Suami/Istri'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList :white="true" :dd="sdm.nidn" :dt="'NIDN'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.nip"
          :dt="'NIP (khusus PNS)'"
        ></DataList>
        <DataList :white="true" dd="-" :dt="'Status Kepegawaian'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.status_keaktifan_pegawai?.nm_stat_aktif"
          :dt="'Status Keaktifan'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList :white="true" :dd="sdm.npwp" :dt="'NPWP'"></DataList>
        <DataList
          :white="true"
          :dd="sdm.nm_wp"
          :dt="'Nama Wajib Pajak'"
        ></DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import DataList from "../components/DataList";

export default {
  components: {
    DataList,
  },
  props: {
    sdm: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    sdm: {
      handler: function (data) {
        if (data?.id_sdm !== undefined) {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
