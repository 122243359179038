<template>
  <form action="" @submit.prevent="submit">
    <div>
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <div v-if="alertFailed">
        <AlertFailed
          v-for="(item, index) in messageAlert"
          :key="index"
          :message="item"
          :dismissable="true"
        />
      </div>
    </div>
    <div v-if="isLoading" class="p-5">Loading data...</div>
    <div v-else>
      <div class="intro-y">
        <div class="grid grid-cols-12 gap-x-8">
          <div class="bg-blue-50 col-span-12 lg:col-span-12 mt-2 mb-6 py-5">
            <h2 class="font-medium text-base mx-6 mb-3">Status Ajuan</h2>
            <div class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <div class="text-gray-500 dark:text-gray-300">Status</div>
              <div class="mt-2 dark:text-white sm:mt-0 sm:col-span-2">
                <span
                  v-if="status_ajuan == 0"
                  class="font-medium text-yellow-500"
                  >DIAJUKAN</span
                >
                <span
                  v-else-if="status_ajuan == 1"
                  class="font-medium text-green-500"
                  >DISETUJUI</span
                >
                <span
                  v-else-if="status_ajuan == 2"
                  class="font-medium text-red-500"
                  >DITOLAK</span
                >
              </div>
            </div>
            <div class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <div class="text-gray-500 dark:text-gray-300">Riwayat Ajuan</div>
              <div class="mt-2 dark:text-white sm:mt-0 sm:col-span-2">
                <div class="mb-1">
                  <b>DIAJUKAN</b> pada tanggal
                  <b>{{ $h.tanggal(datapribadi.tgl_create) }}</b>
                </div>
                <div
                  v-if="status_ajuan > 0 && datapribadi.tgl_ajuan"
                  class="mb-1"
                >
                  Staus
                  <b>{{ status_ajuan == 1 ? "DISETUJUI" : "DITOLAK" }}</b>
                  diperbarui pada tanggal
                  <b>{{ $h.tanggal(datapribadi.tgl_ajuan) }}</b>
                </div>
              </div>
            </div>
            <div class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <div class="text-gray-500 dark:text-gray-300">
                Keterangan Periksa
              </div>
              <div class="mt-2 dark:text-white sm:mt-0 sm:col-span-2">
                {{ keterangan_ajuan }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y bg-gray-50 col-span-12 lg:col-span-6 px-6 py-4 pb-5">
        <div class="grid grid-cols-12 gap-x-8">
          <div class="col-span-12 mt-4 form-group">
            <label class="form-label font-medium">Status</label>
            <select v-model="datapribadi.status_ajuan" class="form-select">
              <option
                v-for="list in listStatusAjuan"
                :key="list.value"
                :value="list.value"
                :selected="list.selected"
              >
                {{ list.text }}
              </option>
            </select>
          </div>
          <div class="col-span-12 mt-4 form-group">
            <label class="form-label font-medium">Keterangan Periksa</label>
            <textarea
              v-model="datapribadi.keterangan_ajuan"
              class="form-control focus:ring-0"
            ></textarea>
            <div class="form-help">Dibuat uraian maks.250 Kata</div>
          </div>
        </div>

        <hr class="mt-6" />

        <div class="flex justify-end flex-col md:flex-row gap-2 mt-6">
          <button type="submit" class="btn py-3 btn-primary w-full md:w-52">
            <SaveIcon class="w-4 h-4 mr-2" /> Simpan Prubahan
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { sdm } from "@/services/models";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
// import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  components: {
    AlertSuccess,
    AlertFailed,
    // LoadingSpinner,
  },
  props: {},
  data() {
    return {
      messageAlert: [],
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      status_ajuan: null,
      keterangan_ajuan: null,
      datapribadi: [],
      listStatusAjuan: [
        {
          id: 0,
          value: "",
          text: "Pilih Status Ajuan",
          selected: false,
        },
        {
          id: 1,
          value: "1",
          text: "Disetujui",
          selected: false,
        },
        {
          id: 2,
          value: "2",
          text: "Ditolak",
          selected: false,
        },
      ],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {},
  mounted() {
    this.getDetailDatapribadi();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailDatapribadi() {
      this.isLoading = true;
      if (this.id?.length > 0) {
        sdm.SDM.getCustomPath(`get_ajuan_perubahan_data_pribadi/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.datapribadi = res.data.status;
                this.datapribadi.update = res.data.data;
                this.status_ajuan = this.datapribadi.status_ajuan;
                this.keterangan_ajuan = this.datapribadi.keterangan_ajuan;
                this.$emit("detailDataDatapribadi", this.datapribadi);
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submit() {
      this.isLoading = true;
      this.messageAlert = [];

      // console.log(fd);
      let formdata = {
        ...this.datapribadi,
      };

      if (this.id?.length > 0) {
        sdm.SDM.postCustomPath(
          "update_ajuan_datapribadi/" + this.id,
          formdata
        ).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.alertSuccess = true;
              this.messageAlert = res.data.message;

              this.$store.dispatch("main/isSuccess", true);
              this.$store.dispatch("main/responseMessage", res.data.message);

              this.getDetailDatapribadi();
            } else {
              Object.keys(res.data.message).forEach((key) => {
                this.messageAlert.push(res.data.message[key][0]);
              });
            }
          }

          this.isLoading = false;
          this.scrollToTop();
        });
      }
    },
  },
};
</script>
