import store from "@/store";

const userdata = {
  isSdmTeam() {
    let sdmteam = store.state.auth.sdmteam;
    let cid = store.getters["userdata/currentTeamId"];
    return sdmteam.includes(cid?.toString());
  },
  isStaffTeam() {
    let staffteam = store.state.auth.staffteam;
    let cid = store.getters["userdata/currentTeamId"];
    return staffteam.includes(cid?.toString());
  },
  isSuperAdmin() {
    let cid = store.getters["userdata/currentTeamId"];
    return cid == 1;
  },
  currentTeam() {
    return store.getters["userdata/currentTeam"];
  },
  currentTeamId() {
    return store.getters["userdata/currentTeamId"];
  },
  // currentTeamName() {
  //   return store.getters["userdata/currentTeamName"];
  // },
  //
  // team() {
  //   return store.getters["userdata/teams"];
  // },
  //
  // dosenprodi() {
  //   return store.getters["userdata/dosenProdi"];
  // },
  // kaprodi() {
  //   return store.getters["userdata/dosenKaprodi"];
  // },
  // dekan() {
  //   return store.getters["userdata/dosenDekan"];
  // },
  // rektor() {
  //   return store.getters["userdata/dosenRektor"];
  // },
  isAuthenticated() {
    return store.getters["auth/isAuthenticated"];
  },
};

const getuserdata = () => {
  return store.getters["userdata/userData"];
};
const isSuperAdmin = () => {
  return userdata.isSuperAdmin();
};
const install = (app) => {
  app.config.globalProperties.$u = userdata;
  app.config.globalProperties.isSuperAdmin = userdata.isSuperAdmin;
};

export { install as default, getuserdata, userdata, isSuperAdmin };
