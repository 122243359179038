<template>
  <div v-if="isLoading" class="flex text-center">Loading data...</div>
  <div v-else>
    <div class="mt-3 mb-4 text-base font-medium text-center">
      JJA Dosen Universitas MDP
    </div>
    <CanvasJSChart
      class="mr-5"
      :options="options"
      :styles="styleOptions"
      @chart-ref="getstatistikjja"
    />
  </div>
</template>

<script>
// import { wssister } from "@/services/wspddikti";
// import { sdm } from "@/services/models";

import { sdm } from "@/services/models";

export default {
  name: "GrafikJumlahSDM",
  components: {},
  props: {
    sister: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chart: null,
      dosenInfo: [],
      tendikInfo: [],
      isLoading: true,
      jja_data: [],
      options: {
        animationEnabled: true,
        exportEnabled: false,
        // theme: "light2",
        // title: {
        //   text: "",
        //   fontColor: "#FF6F00",
        //   fontWeight: "bold",
        // },
        // subtitles: [
        //   {
        //     text: "NUMBER OF SOCIAL MEDIA USERS (IN MILLIONS) AND YEAR-ON-YEAR CHANGE",
        //   },
        // ],
        axisX: {
          lineThickness: 0,
          tickLength: 0,
          labelTextAlign: "center",
        },
        axisY: {
          includeZero: true,
          // lineThickness: 0,
          // gridThickness: 0,
          // tickLength: 0,
          // maximum: 5.5,
          // labelFormatter: function () {
          //   return "";
          // },
        },
        data: [
          {
            type: "column",
            indexLabel: "{y}",
            // color: "#FF6F00",
            yValueFormatString: "# Orang",
            indexLabelOrientation: "horizontal",
            // dataPoints: [
            //   { label: "Tenaga Pengajar", y: 22 },
            //   { label: "Asisten Ahli (100.00)", y: 12 },
            //   { label: "Asisten Ahli (150.00)", y: 15 },
            //   { label: "Lektor (200.00)", y: 8 },
            //   { label: "Lektor (300.00)", y: 4 },
            //   { label: "Lektor Kepala (400.00)", y: 3 },
            //   { label: "Lektor Kepala (550.00)", y: 5 },
            //   { label: "Lektor Kepala (700.00)", y: 2 },
            //   { label: "Profesor (850.00)", y: 2 },
            //   { label: "Profesor (1050.00)", y: 1 },
            // ],
          },
        ],
      },
      styleOptions: {
        width: "100%",
        height: "280px",
      },
    };
  },
  watch: {
    getstatistikjja: {
      handler: function () {
        sdm.SDM.get("statistik_jja").then((res) => {
          res.data.data.forEach((element) => {
            this.jja_data.push({
              label: element.nm_jabfung + " (" + element.angka_kredit + ")",
              y: parseInt(element.jumlah),
            });
          });

          this.isLoading = false;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.getstatistikjja();
  },
  methods: {
    async getstatistikjja(chart) {
      if (!this.isLoading) {
        chart.options.data[0].dataPoints = this.jja_data;
        chart.render();
      }
    },
  },
};
</script>
