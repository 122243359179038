<template>
  <form action="" @submit.prevent="submit">
    <div>
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <div v-if="alertFailed">
        <AlertFailed
          v-for="(item, index) in messageAlert"
          :key="index"
          :message="item"
          :dismissable="true"
        />
      </div>
    </div>
    <div v-if="isLoading" class="p-5">Loading data...</div>
    <div v-else class="intro-y col-span-12 lg:col-span-6">
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-x-8">
        <div
          class="col-span-12 mb-4 py-6 bg-gray-50 border-2 border-dashed dark:border-dark-5 rounded-md"
        >
          <div class="col-span-12 mt-2 form-inline">
            <label class="form-label font-medium w-52">Nama Dosen/Tendik</label>
            {{
              diklat.sdm ? diklat.sdm?.nm_sdm : diklat.sdm_pegawai?.NM_PEGAWAI
            }}
          </div>
          <div class="col-span-12 mt-2 form-inline">
            <label class="form-label font-medium w-52">NIK</label>
            {{ diklat.sdm ? diklat.sdm?.nip : diklat.sdm_pegawai?.KODE }}
          </div>
        </div>
        <div class="col-span-12 mt-4"><hr /></div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Nama Pelatihan <span class="text-red-900">*</span></label
          >
          <input v-model="diklat['nama']" class="form-control focus:ring-0" />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Penyelenggara <span class="text-red-900">*</span></label
          >
          <input
            v-model="diklat['penyelenggara']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">Peran</label>
          <input v-model="diklat['peran']" class="form-control focus:ring-0" />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tingkat Diklat <span class="text-red-900">*</span></label
          >
          <TailSelect
            v-model="diklat['tingkat']"
            :options="{
              search: true,
              classNames: 'form-control',
              deselect: false,
            }"
          >
            <option
              v-for="list in listTingkatDiklat"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">Jumlah Jam</label>
          <input
            v-model="diklat['jumlah_jam']"
            class="form-control focus:ring-0"
            type="number"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">No. Sertifikat</label>
          <input
            v-model="diklat['no_sertifikat']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal Sertifikat <span class="text-red-900">*</span></label
          >
          <input
            v-model="diklat['tanggal_sertifikat']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tahun Penyelenggara <span class="text-red-900">*</span></label
          >
          <input
            v-model="diklat['tahun']"
            class="form-control focus:ring-0"
            type="number"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">Tempat</label>
          <input v-model="diklat['lokasi']" class="form-control focus:ring-0" />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal Mulai <span class="text-red-900">*</span></label
          >
          <input
            v-model="diklat['tanggal_mulai']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal Selesai <span class="text-red-900">*</span></label
          >
          <input
            v-model="diklat['tanggal_selesai']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">Nomor SK Penugasan</label>
          <input
            v-model="diklat['sk_penugasan']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal SK Penugasan</label
          >
          <input
            v-model="diklat['tanggal_sk_penugasan']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Nama Pengajar/Pengajar</label
          >
          <input
            v-model="diklat['pengajar']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Keterangan Keahlian Pengajar/Pengajar</label
          >
          <input
            v-model="diklat['keahlian_pengajar']"
            class="form-control focus:ring-0"
          />
        </div>
      </div>

      <hr class="mt-6" />

      <div class="p-10 py-10">
        <label class="form-label font-medium"
          >Urain Hasil dan Manfaat Pelatihan / Pengembangan</label
        >
        <div class="w-100">
          <textarea
            v-model="diklat['uraian']"
            class="form-control focus:ring-0"
          ></textarea>
          <div class="form-help">Dibuat uraian maks.250 Kata</div>
        </div>
      </div>

      <hr />

      <div class="flex justify-end flex-col md:flex-row gap-2 mt-6">
        <button type="submit" class="btn py-3 btn-primary w-full md:w-52">
          <SaveIcon class="w-4 h-4 mr-2" /> Simpan Prubahan
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { sdm } from "@/services/models";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import LoadingSpinner from "@/components/loading-spinner/Main";
import moment from "moment";

export default {
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      messageAlert: [],
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      diklat: [],
      onUploadProgress: [],
      listTingkatDiklat: [
        {
          id: 0,
          value: "",
          text: "Pilih Tingkat Diklat",
          selected: false,
        },
        {
          id: 1,
          value: "1",
          text: "Lokal",
          selected: false,
        },
        {
          id: 2,
          value: "2",
          text: "Regional",
          selected: false,
        },
        {
          id: 3,
          value: "3",
          text: "Nasional",
          selected: false,
        },
        {
          id: 4,
          value: "4",
          text: "Internasional",
          selected: false,
        },
      ],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {},
  mounted() {
    this.getDetailDiklat();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailDiklat() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.Diklat.getCustomPath(`getDetailDiklat/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.diklat = res.data.data;

                this.$emit("detailDataDiklat", this.diklat);
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submit() {
      this.isLoading = true;
      this.messageAlert = [];

      // console.log(fd);
      let formdata = {
        ...this.diklat,
      };

      if (this.id?.length > 0) {
        sdm.Diklat.postCustomPath("update_diklat/" + this.id, formdata).then(
          (res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.alertSuccess = true;
                this.messageAlert = res.data.message;

                this.$store.dispatch("main/isSuccess", true);
                this.$store.dispatch("main/responseMessage", res.data.message);
              } else {
                Object.keys(res.data.message).forEach((key) => {
                  this.messageAlert.push(res.data.message[key][0]);
                });
              }
            }

            this.isLoading = false;
            this.scrollToTop();
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
