<template>
  <div class="grid grid-cols-12 gap-3">
    <div
      class="col-span-12 xl:col-span-9"
      :class="
        ($slots.rightside || $slots.filterside) && !toggledFilter
          ? 'xl:col-span-9'
          : leftSideClass
      "
    >
      <main
        class="relative"
        :class="
          ($slots.rightside || $slots.filterside) && !toggledFilter
            ? 'xl:border-r border-theme-5 pr-6'
            : mainClass
        "
      >
        <slot></slot>
      </main>
    </div>

    <div
      v-if="toggledFilter"
      class="absolute border rounded-full top-32 right-5 z-50 bg-white p-1"
    >
      <div
        class="cursor-pointer text-red-500 hover:text-red-800 pl-2"
        @click="toggleFilter"
      >
        <FilterIcon class="w-4 h-4 mr-1" />
        <span class="text-xs mr-2">Filter Data</span>
      </div>
    </div>

    <div
      v-if="$slots.filterside || $slots.rightside"
      :class="!toggledFilter ? 'col-span-12 xl:col-span-3 ml-3' : rightSideClass"
    >
      <div
        v-if="$slots.filterside"
        class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-8"
      >
        <h2 class="text-lg font-medium mr-auto">Filter</h2>
      </div>
      <slot name="filterside"></slot>
      <div
        v-if="$slots.rightside && sideTitle"
        class="intro-y flex flex-col sm:flex-row items-center"
        :class="$slots.filterside ? 'mt-5' : 'mt-8'"
      >
        <h2 v-if="sideTitle" class="text-lg font-medium mr-auto">
          {{ sideTitle }}
        </h2>

        <div v-if="showSync" class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link :to="{ name: 'sync-feeder' }" class="btn-default btn-sm">
            Sync FEEDER
          </router-link>
        </div>
      </div>
      <slot name="rightside"></slot>
      <SlideOverModal id="sync-feeder" :modal-size="modalSize">
        <template #title>Sinkronisasi Data</template>
        <SyncFeeder v-if="showSync">
          <slot name="syncmenu"></slot>
        </SyncFeeder>
      </SlideOverModal>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import SyncFeeder from "../../components/utility/SyncFeeder";
import SlideOverModal from "./SlideOverModal";
export default {
  name: "MainLayout",
  components: { SlideOverModal, SyncFeeder },
  props: {
    sideTitle: {
      default: "",
      type: String,
    },
    showSync: {
      default: false,
      type: Boolean,
    },
    toggledFilter: {
      default: false,
      type: Boolean,
    },
    modalSize: {
      type: String,
      default: "modal-lg",
    },
  },
  setup() {
    let modalOpen = ref(false);
    let panel = reactive({ modalOpen });
    return {
      panel,
      modalOpen,
    };
  },
  data() {
    return {
      toggle: false,
      rightSideClass: "hidden",
      leftSideClass: "xl:col-span-12",
      mainClass: "",
    };
  },
  methods: {
    closePanel(close) {
      this.panel.modalOpen = close;
    },
    openPanel() {
      this.modalOpen = true;
    },
    toggleFilter() {
      this.toggle = !this.toggle;
      if (this.toggle) {
        this.leftSideClass = "xl:col-span-9";
        this.rightSideClass = "col-span-12 xl:col-span-3";
        this.mainClass = "xl:border-r border-theme-5 pr-3";
      } else {
        this.leftSideClass = "xl:col-span-12";
        this.rightSideClass = "hidden";
        this.mainClass = "";
      }
    },
  },
};
</script>

<style scoped></style>
