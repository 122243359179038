<template>
  <!-- BEGIN: Breadcrumb -->
  <div
    :class="
      light === 'light'
        ? '-intro-x breadcrumb breadcrumb--light mr-auto hidden sm:flex'
        : '-intro-x breadcrumb mr-auto hidden sm:flex'
    "
  >
    <div>
      <div
        class="font-semibold text-sm md:text-xl dark:text-white"
        :class="light === 'light' ? 'text-white' : 'text-primary-1'"
      >
        {{ app_title }}
      </div>

      <span class="hidden lg:block text-sm font-normal"
        ><b>Universitas Multi Data Palembang</b>
      </span>
    </div>
  </div>
  <!-- END: Breadcrumb -->
  <span
    class="font-normal mr-5"
    :class="light === 'light' ? 'text-white' : 'text-gray-600'"
    >Last updated: {{ $h.tanggal(new Date().toLocaleString()) }}</span
  >
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BreadCrumb",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      app_title: this.app_title,
    };
  },
  computed: {},
});
</script>

<style scoped></style>
