<template>
  <div
    class="grid grid-cols-1 space-y-2 bg-slate-50 py-2 lg:h-screen lg:grid-cols-2 lg:bg-slate-50 lg:py-0"
  >
    <div class="login-left hidden lg:block">
      <div class="h-full flex flex-col items-center justify-center">
        <img
          src="https://api.mdp.ac.id/public/assets/logo/logo-umdp-light.png"
          class="w-7/12"
        />
        <div class="text-left text-white">
          <div class="font-semibold md:text-xl lg:text-2xl xl:text-3xl">
            {{ title }}
          </div>
          <div class="font-bold md:text-2xl lg:text-3xl xl:text-4xl">
            Universitas Multi Data Palembang
          </div>
        </div>
      </div>
    </div>
    <div class="flex h-full items-center justify-center">
      <div
        class="w-9/12 rounded-2xl border bg-white md:w-7/12 lg:w-8/12 lg:bg-slate-50/80 xl:w-6/12 2xl:w-5/12"
      >
        <div class="border-b p-5">
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-3xl font-bold">Sign In</h3>
              <span class="font-semibold text-gray-700">
                Login dengan akun LDAP (PANDA)
              </span>
            </div>
            <img
              alt="Univ MDP"
              src="https://api.mdp.ac.id/public/assets/logo/logo-umdp-origin.png"
              class="w-3/12 lg:hidden"
            />
          </div>
        </div>

        <div class="flex flex-col space-y-3 p-5 pb-6">
          <div class="form-group">
            <label for="" class="form-label font-semibold uppercase">
              User Id
            </label>
            <input
              v-model="model.uid"
              type="text"
              class="form-control rounded-lg border-2 font-semibold shadow-none focus:border-blue-600 focus:outline-none focus:ring-0"
              placeholder="User Id"
              required
            />
          </div>
          <div class="form-group">
            <label for="" class="form-label font-semibold uppercase">
              Password
            </label>
            <div class="relative">
              <input
                v-model="model.password"
                :type="showPassword ? 'text' : 'password'"
                class="form-control rounded-lg border-2 font-semibold shadow-none focus:border-blue-600 focus:outline-none focus:ring-0"
                placeholder="Password"
                required
              />
              <button
                type="button"
                class="absolute inset-y-0 right-0 flex items-center p-3 text-sm leading-5"
                @click="togglePassword"
              >
                <eye
                  v-if="!showPassword"
                  class="h-4 w-4 text-slate-500 hover:text-primary-1"
                />
                <eyeSlash
                  v-else
                  class="h-4 w-4 text-slate-500 hover:text-primary-1"
                />
              </button>
            </div>
          </div>
          <button
            class="btn btn-primary rounded-lg font-semibold"
            :disabled="isLoading || disabled"
            @click="submit"
          >
            {{ isLoading ? "Loading..." : "Masuk" }}
          </button>
          <div v-if="showError" class="intro-x">
            <AlertFailed :message="loginError" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="pickteam-modal"
    class="modal"
    :class="showPick ? 'overflow-y-auto show' : 'hide'"
    tabindex="-1"
    data-backdrop="static"
    aria-hidden="false"
    :style="
      showPick
        ? 'margin-top: 0px; margin-left: 0px; padding-left: 0px; z-index: 10000;'
        : ''
    "
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
            <div class="text-3xl mt-5">Silakan Login Sebagai</div>
          </div>
          <div class="px-5 pb-8 text-center">
            <div class="grid grid-cols-1 gap-2">
              <div v-for="team in teams" :key="team.id" class="col-span-1">
                <button
                  type="button"
                  class="btn btn-success w-full"
                  @click="setTeam(team.id)"
                >
                  {{ team?.name }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="p-5 text-center border-t border-gray-200 dark:border-dark-5"
          >
            <button class="text-theme-1 dark:text-theme-10" @click="cancelPick">
              Batal dan Login sebagain user lain
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eye, eyeSlash } from "@/assets/icons/customIcons";
import { defineComponent, onMounted } from "vue";
import { mapActions } from "vuex";
import router from "@/router";
import AlertFailed from "../../components/alerts/AlertFailed";
import { cpname } from "/src/libs/computer-name";
// import LoadingSpinner from "@/components/loading-spinner/Main";

export default defineComponent({
  components: {
    eye,
    eyeSlash,
    // LoadingSpinner,
    AlertFailed,
  },
  setup() {
    onMounted(() => {
      cash("html").addClass("bg-white");
      cash("body")
        .removeClass("login")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login2");
    });
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      showPassword: false,
      disabled: true,
      isLoading: false,
      model: {
        uid: "",
        password: "",
        apps:
          process.env.VUE_APP_NAME + "@" + cpname() + ":" + process.platform,
      },
      showError: false,
      showPick: false,
    };
  },
  computed: {
    loginError() {
      return this.$store.state.auth.error;
    },
    pickTeam() {
      return this.$store.getters["auth/pickTeam"];
    },
    teams() {
      return this.$store.getters["userdata/teams"];
    },
    // loadingStatus() {
    //   return this.$store.state.main.loadingStatus;
    // },
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.model.uid && this.model.password) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
    },
    loginError() {
      this.showError = true;
    },
    pickTeam: {
      handler(nv) {
        //if (nv == true) cash("#pickteam-modal").modal("show");
        if (nv) this.showPick = true;
      },
      immediate: true,
    },
  },
  mounted() {
    this.showPick = false;
  },
  methods: {
    ...mapActions({
      LoginV2: "auth/LoginV2",
      SetTeam: "auth/SetTeam",
    }),
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async submit() {
      this.isLoading = true;
      console.log(this.isLoading);
      this.LoginV2(this.model)
        .then((response) => {
          if (!response[1]) {
            router.push(response[0]);
          } else {
            this.showPick = true;
          }

          this.showError = false;
        })
        .finally(() => {
          this.isLoading = false;
          console.log(this.isLoading);
          // this.$store.dispatch("main/setLoadingStatus", false);
        });
    },
    setTeam(id) {
      this.SetTeam({ id: id, refresh: true });
    },
    cancelPick() {
      this.showPick = false;
    },
  },
});
</script>
