<template>
  <div class="intro-y box p-5">
    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
      <form
        id="tabulator-html-filter-form"
        class="xl:flex sm:mr-auto"
        @submit.prevent="onFilter"
      >
        <div class="sm:flex items-center sm:mr-4">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Field</label
          >
          <select
            id="tabulator-html-filter-field"
            v-model="filter.field"
            class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
          >
            <option value="nm_berkas">Nama Berkas</option>
          </select>
        </div>
        <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Type</label
          >
          <select
            id="tabulator-html-filter-type"
            v-model="filter.type"
            class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
          >
            <option value="like" selected>like</option>
            <option value="=">=</option>
            <option value="<">&lt;</option>
            <option value="<=">&lt;=</option>
            <option value=">">></option>
            <option value=">=">>=</option>
            <option value="!=">!=</option>
          </select>
        </div>
        <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Value</label
          >
          <input
            id="tabulator-html-filter-value"
            v-model="filter.value"
            type="text"
            class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
            placeholder="Search..."
          />
        </div>
        <div class="mt-2 xl:mt-0">
          <button
            id="tabulator-html-filter-go"
            type="button"
            class="btn btn-primary w-full sm:w-16"
            @click="onFilter"
          >
            Go
          </button>
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
        </div>
      </form>
    </div>
    <div class="overflow-x-auto scrollbar-hidden">
      <div
        id="tabulator"
        ref="tableRef"
        class="mt-5 table-report table-report--tabulator"
      ></div>
    </div>
  </div>
  <!--    <table class="table w-100">-->
  <!--      <thead>-->
  <!--        <tr class="bg-gray-700 dark:bg-dark-1 text-white">-->
  <!--          <th class="whitespace-nowrap">#</th>-->
  <!--          <th class="whitespace-nowrap">Nama Diklat</th>-->
  <!--          <th class="whitespace-nowrap">Jenis Diklat</th>-->
  <!--          <th class="whitespace-nowrap">Penyelenggara</th>-->
  <!--          <th class="whitespace-nowrap">Tahun</th>-->
  <!--          <th class="whitespace-nowrap">#</th>-->
  <!--        </tr>-->
  <!--      </thead>-->
  <!--      <tbody>-->
  <!--        <tr v-for="(item, index) in BerkasPendataan" :key="index">-->
  <!--          <td>{{ index + 1 }}</td>-->
  <!--          <td>{{ item?.No_Surat }}</td>-->
  <!--          <td>{{ item?.Catatan }}</td>-->
  <!--          <td>{{ item?.Tgl_Berlaku }}</td>-->
  <!--          <td>{{ item?.Tgl_Entry }}</td>-->
  <!--          <td>#</td>-->
  <!--        </tr>-->
  <!--        <tr v-if="BerkasPendataan.length == 0">-->
  <!--          <td colspan="6" class="text-center">Belum ada riwayat</td>-->
  <!--        </tr>-->
  <!--      </tbody>-->
  <!--    </table>-->
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { sdm } from "@/services/models";
import xlsx from "xlsx";
import feather from "feather-icons";
import { useStore } from "vuex";
// import router from "@/router";
import { tblTabulator } from "@/utils/tabulator";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    // let deletingRow = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)
    const filter = reactive({
      field: "nm_berkas",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          vertAlign: "middle",
          resizable: false,
          headerSort: false,
        },
        {
          title: "No. Surat",
          minWidth: 40,
          responsive: 0,
          headerHozAlign: "left",
          vertAlign: "middle",
          field: "no_berkas",
          print: false,
          download: false,
        },
        {
          title: "Nama Berkas",
          minWidth: 40,
          responsive: 0,
          headerHozAlign: "left",
          vertAlign: "middle",
          field: "nm_berkas",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().nm_berkas === null) {
              return `<div>
                <i data-feather="x-square" class="w-4 h-4 mr-1"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-nowrap">Tidak Terhubung</div>
              </div>`;
            } else {
              return `<div>
                <div class="text-gray-500 text-xs whitespace-normal mt-2">${
                  cell.getData().nm_berkas
                }
                </div>
                </div>
              </div>`;
            }
          },
        },
        {
          title: "Tanggal Input",
          width: 160,
          headerHozAlign: "left",
          field: "tgl_input",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            if (cell.getData().tgl_input === null) {
              return `<div class="text-gray-600 text-xs whitespace-nowrap">-</div>`;
            } else {
              return `<div>
                <div class="text-gray-500 text-xs whitespace-normal mt-2">${
                  cell.getData().tgl_input
                }
                </div>
                </div>
              </div>`;
            }
          },
        },
        {
          title: "File",
          width: 160,
          headerHozAlign: "left",
          field: "File1",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().berkas_file === null) {
              return `<div>
                <i data-feather="x-square" class="w-4 h-4 mr-1"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-nowrap">Tidak Terhubung</div>
              </div>`;
            } else {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-success w-18 items-center" target="_blank" href="https://apps2.mdp.ac.id/simarta/berkas_dosen/${
                  cell.getData().NIK
                }/${
                cell.getData().berkas_file
              }"><i data-feather="archive" class="w-4 h-4 mr-1"></i> Download
                </a>
                </div>
              </div>`;
            }
          },
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        // sdm.Simarta.getCustomPath("berkaspendataan/141097"),
        sdm.Simarta.endpoint + "/berkaspribadi/" + id,
        columns
      );
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "nm_berkas";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };

    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };

    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };

    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };

    // Print
    const onPrint = () => {
      tabulator.value.print();
    };

    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });

    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      accessToken,
      // deletingRow,
      // id,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  mounted() {
    // this.getBerkasPendataan();
  },
  methods: {
    // async getBerkasPendataan() {
    //   sdm.Simarta.getCustomPath(`berkaspendataan/${this.id}`).then((res) => {
    //     if (res.status === 200) {
    //       this.BerkasPendataan = res.data;
    //       console.log(res.data);
    //     }
    //   });
    // },
  },
});
</script>
