<template>
  <div
    :id="tabId + '-tab'"
    class="tab-pane p-5 max-w-full"
    :class="activeTab"
    role="tabpanel"
    :aria-labelledby="tabId + '-tab'"
  >
    <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
      <main>
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabContent3",
  props: {
    tabId: {
      default: "",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    activeTab: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped></style>
