<template>
  <div v-if="loading" class="p-5">Loading data...</div>
  <div v-else class="intro-y p-5 col-span-12 lg:col-span-6">
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-6">
        <DataList
          :white="true"
          :dd="DataPribadi.nama_dosen"
          :dt="'Nama Dosen'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.jenisKelamin(DataPribadi.jenis_kelamin)"
          :dt="'Jenis Kelamin'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            DataPribadi.alamat +
            ' (RT ' +
            parseFloat(DataPribadi.rt) +
            ', RW ' +
            parseFloat(DataPribadi.rw) +
            ')'
          "
          :dt="'Alamat'"
        >
        </DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.dusun"
          :dt="'Dusun'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.kelurahan"
          :dt="'Desa/Kelurahan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.kota_kabupaten"
          :dt="'Kota/Kabupaten'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.provinsi"
          :dt="'Provinsi'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.kode_pos"
          :dt="'KODE POS'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.telepon_hp"
          :dt="'No HP'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.telepon_rumah"
          :dt="'No Telp'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.email"
          :dt="'Email'"
        ></DataList>
        <DataList
          :white="true"
          :dd="
            DataPribadi.tempat_lahir +
            ', ' +
            $h.tanggal(DataPribadi.tanggal_lahir)
          "
          :dt="'Tempat/Tanggal Lahir'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nama_ibu_kandung"
          :dt="'Nama Ibu Kandung'"
        ></DataList>
        <DataList :white="true" :dd="DataPribadi.nik" :dt="'NIK'"></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nama_agama"
          :dt="'Agama'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.kewarganegaraan"
          :dt="'Kewarganegaran'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList
          :white="true"
          :dd="$h.statKawin(DataPribadi.status_perkawinan)"
          :dt="'Status Perkawinan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nama_suami_istri"
          :dt="'Nama Suami/Istri'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nip_suami_atau_istri"
          :dt="'NIP Suami/Istri'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.pekerjaan"
          :dt="'Pekerjaan Suami/Istri'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.menjadi_pns_terhitung_mulai_tanggal"
          :dt="'Terhitung Mulai Tanggal PNS Suami/Istri'"
        ></DataList>
      </dl>
      <dl class="col-span-12 lg:col-span-6">
        <DataList :white="true" :dd="DataPribadi.nidn" :dt="'NIDN'"></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nama_program_studi"
          :dt="'Program Studi'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.jurusan"
          :dt="'Jurusan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.fakultas"
          :dt="'Fakultas'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nip"
          :dt="'NIP (khusus PNS)'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.status_kepegawaian"
          :dt="'Status Kepegawaian'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.status_aktif_sekarang"
          :dt="'Status Keaktifan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.no_sk_cpns"
          :dt="'Nomor SK CPNS'"
        ></DataList>
        <DataList
          :white="true"
          dd="-"
          :dt="'SK CPNS Terhitung Mulai Tanggal'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.no_sk_pengangkatan"
          :dt="'Nomor SK Pengangkatan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.sk_pengangkatan_terhitung_mulai_tanggal"
          :dt="'Tanggal SK Pengangkatan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nama_golongan"
          :dt="'Pangkat/Golongan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.sumber_gaji"
          :dt="'Sumber Gaji'"
        ></DataList>
        <div
          class="w-full border-t border-slate-200/60 dark:border-darkmode-400 my-2"
        ></div>
        <DataList :white="true" :dd="DataPribadi.npwp" :dt="'NPWP'"></DataList>
        <DataList
          :white="true"
          :dd="DataPribadi.nama_wajib_pajak"
          :dt="'Nama Wajib Pajak'"
        ></DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import { wssister } from "@/services/wspddikti";
import DataList from "../../components/DataList";

export default {
  components: {
    DataList,
  },
  props: {
    sister: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      DataPribadi: [],
      loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  mounted() {
    this.getDataPribadiData();
  },
  methods: {
    async getDataPribadiData() {
      wssister
        .GetData("data_pribadi/alamat", {
          kd_dosen: this.id,
          path: true,
        })
        .then((res) => {
          if (res.data) {
            this.loading = false;
            this.DataPribadi = res.data;
          }
          // console.log(res);
        });
    },
  },
};
</script>

<style></style>
