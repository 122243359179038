<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Data Rekrutmen Pegawai</h2>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-3 2xl:col-span-3 -mb-10 pb-10">
        <div class="post intro-y overflow-hidden box mt-5">
          <infoPegawai :static-info="detailDataRekrutmen"></infoPegawai>
        </div>
      </div>
      <div class="col-span-9 2xl:col-span-9 -mb-10 pb-10">
        <div class="post intro-y overflow-hidden box mt-5">
          <div
            class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
            role="tablist"
          >
            <TippyTabNav
              :tab-nav-id="'general'"
              :content="'Biodata Diri Pelamar'"
              :active-tab="
                activeTab == '' || activeTab === 'general' ? 'active' : ''
              "
              :tab-label="'general'"
              @click="activeTab = 'general'"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Biodata Diri
            </TippyTabNav>
            <TippyTabNav
              :tab-nav-id="'rwy-pendidikan'"
              :content="'Riwayat Pendidikan'"
              :active-tab="activeTab === 'rwy-pendidikan' ? 'active' : ''"
              :tab-label="'rwy-pendidikan'"
              @click="activeTab = 'rwy-pendidikan'"
            >
              <AlignLeftIcon class="w-4 h-4 mr-2" /> Riwayat Pendidikan
            </TippyTabNav>
            <TippyTabNav
              :tab-nav-id="'berkas'"
              :content="'Berkas Lamaran'"
              :active-tab="activeTab === 'berkas' ? 'active' : ''"
              :tab-label="'berkas'"
              @click="activeTab = 'berkas'"
            >
              <AlignLeftIcon class="w-4 h-4 mr-2" /> Berkas Lamaran
            </TippyTabNav>
            <TippyTabNav
              :tab-nav-id="'jadwal'"
              :content="'Tes & Wawancara'"
              :active-tab="activeTab === 'jadwal' ? 'active' : ''"
              :tab-label="'jadwal'"
              @click="activeTab = 'jadwal'"
            >
              <AlignLeftIcon class="w-4 h-4 mr-2" /> Hasil Tes & Wawancara
            </TippyTabNav>
            <TippyTabNav
              :tab-nav-id="'kontrak'"
              :content="'Kontrak Kerja'"
              :active-tab="activeTab === 'kontrak' ? 'active' : ''"
              :tab-label="'kontrak'"
              @click="activeTab = 'kontrak'"
            >
              <AlignLeftIcon class="w-4 h-4 mr-2" /> Kontrak Kerja
            </TippyTabNav>
          </div>
          <div class="tab-content">
            <TabContent3
              :tab-id="'general'"
              :active-tab="
                activeTab == '' || activeTab === 'general' ? 'active' : ''
              "
            >
              <formRecruitment
                v-if="activeTab == '' || activeTab === 'general'"
                :new-recruitment="false"
                @detailDataRekrutmen="setDetailDataRekrutmen"
              />
            </TabContent3>
            <TabContent3
              :tab-id="'rwy-pendidikan'"
              :active-tab="activeTab === 'rwy-pendidikan' ? 'active' : ''"
            >
              <formRwyPendidikan v-if="activeTab === 'rwy-pendidikan'" />
            </TabContent3>
            <TabContent3
              :tab-id="'berkas'"
              :active-tab="activeTab === 'berkas' ? 'active' : ''"
            >
              <berkasPegawai v-if="activeTab === 'berkas'" />
            </TabContent3>
            <TabContent3
              :tab-id="'jadwal'"
              :active-tab="activeTab === 'jadwal' ? 'active' : ''"
            >
              <div v-if="activeTab === 'jadwal'">
                <formTeswawancara
                  v-if="activeTab === 'jadwal'"
                  @detailDataRekrutmen="setDetailDataRekrutmen"
                />
              </div>
            </TabContent3>
            <TabContent3
              :tab-id="'kontrak'"
              :active-tab="activeTab === 'kontrak' ? 'active' : ''"
            >
              <kontrakPegawai v-if="activeTab === 'kontrak'" />
            </TabContent3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import infoPegawai from "./components/infoPegawai";
import berkasPegawai from "./components/formBerkas";
import formRwyPendidikan from "./components/formRwyPendidikan";
import formRecruitment from "./components/formRecruitment";
import formTeswawancara from "./components/formTeswawancara";
import kontrakPegawai from "./components/formKontrak";
import TippyTabNav from "@/components/tabs/TippyTabNav";
import TabContent3 from "@/components/tabs/TabContent3";

export default {
  components: {
    infoPegawai,
    berkasPegawai,
    formRwyPendidikan,
    formRecruitment,
    formTeswawancara,
    kontrakPegawai,
    TippyTabNav,
    TabContent3,
  },
  data() {
    return {
      detailDataRekrutmen: [],
      attrDosen: null,
      staticInfo: null,
      activeTab: null,
      tabOnStudy: "formal",
    };
  },
  mounted() {
    this.activeTab = this.$route.params?.tab;
    if (this.activeTab === undefined) this.activeTab = "";
  },
  methods: {
    setDetailDataRekrutmen(val) {
      this.detailDataRekrutmen = val;
      if (this.detailDataRekrutmen["id_sdm"] == null) return 0;
    },
  },
};
</script>
