import { createApp } from "vue";
import VueCookies from "vue3-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import globalComponents from "./global-components";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import utils from "./utils";
import "./libs";

// SASS Theme
import "./assets/sass/app.scss";

const app = createApp(App).use(store).use(router).use(Toaster).use(VueCookies);

globalComponents(app);
utils(app);
app.config.globalProperties.app_title = process.env.VUE_APP_TITLE;
app.config.globalProperties.app_name = process.env.VUE_APP_NAME;
app.config.productionTip = false;
app.mount("#app");
