<template>
  <div class="box p-5 rounded-md">
    <div
      class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5"
    >
      <div class="font-medium text-base truncate">User Details</div>
      <!--      <a href="" class="flex items-center ml-auto text-primary"-->
      <!--        ><ArchiveIcon class="w-4 h-4 mr-2" /> More Details-->
      <!--      </a>-->
    </div>
    <!--    <div class="flex items-center">-->
    <!--      <Edit3Icon class="w-4 h-4 mr-2" />-->
    <!--      <span class="font-medium mr-2">ID:</span>-->
    <!--      <span-->
    <!--        v-if="staticInfo?.id_rekrutmen"-->
    <!--        class="underline decoration-dotted ml-1"-->
    <!--        >{{ staticInfo?.id_rekrutmen }}</span-->
    <!--      >-->
    <!--    </div>-->
    <div class="flex items-center mt-3">
      <UserIcon class="w-4 h-4 mr-2" />
      <span class="font-medium mr-2">Nama:</span>
      <span v-if="staticInfo?.nm" class="underline decoration-dotted ml-1">{{
        staticInfo?.nm
      }}</span>
    </div>
    <div class="flex items-center mt-3">
      <PhoneIcon class="w-4 h-4 mr-2" />
      <span class="font-medium mr-2">Telepon/HP:</span>
      <span v-if="staticInfo?.no_hp" class="underline decoration-dotted ml-1">{{
        staticInfo?.no_hp
      }}</span>
    </div>
    <div v-if="staticInfo?.email" class="flex items-center mt-3">
      <AtSignIcon class="w-4 h-4 mr-2" />
      <span class="font-medium mr-2">Alamat Email:</span>
      {{ staticInfo?.email }}
    </div>
    <div
      v-if="staticInfo?.sdm_rekrutmen_ref"
      class="border-t border-slate-200/60 dark:border-darkmode-400 pt-5 mt-5"
    >
      <div
        v-if="staticInfo?.sdm_rekrutmen_ref?.tgl_wawancara"
        class="flex items-center mt-3"
      >
        <CalendarIcon class="w-4 h-4 mr-2" />
        <span class="font-medium mr-2">Tanggal Wawancara: </span>
        {{
          moment(staticInfo?.sdm_rekrutmen_ref?.tgl_wawancara).format(
            "Do MMMM, YYYY"
          )
        }}
      </div>
      <div
        v-if="staticInfo?.sdm_rekrutmen_ref?.tgl_tes"
        class="flex items-center mt-3"
      >
        <CalendarIcon class="w-4 h-4 mr-2" />
        <span class="font-medium mr-2">Tanggal Tes: </span>
        {{
          moment(staticInfo?.sdm_rekrutmen_ref?.tgl_tes).format("Do MMMM, YYYY")
        }}
      </div>
      <div
        v-if="staticInfo?.sdm_rekrutmen_ref?.status_rekrutmen"
        class="flex items-center mt-3"
      >
        <CalendarIcon class="w-4 h-4 mr-2" />
        <span class="font-medium mr-2">Status Rekrutmen: </span>
        {{
          staticInfo?.sdm_rekrutmen_ref?.status_rekrutmen == 0
            ? "Ditolak"
            : "Diterima"
        }}
      </div>
      <div
        v-if="staticInfo?.sdm_rekrutmen_ref?.tenaga_pendidik"
        class="flex items-center mt-3"
      >
        <CalendarIcon class="w-4 h-4 mr-2" />
        <span class="font-medium mr-2">Divisi: </span>
        {{
          staticInfo?.sdm_rekrutmen_ref?.tenaga_pendidik == 1
            ? "Dosen"
            : staticInfo?.sdm_rekrutmen_ref?.tenaga_pendidik == 2
            ? "Dosen LB"
            : "Tenaga Pendidik (Karyawan)"
        }}
      </div>
    </div>
    <div
      class="flex items-center border-t border-slate-200/60 dark:border-darkmode-400 pt-5 mt-5 font-medium"
    ></div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "InfoPegawai",
  props: {
    staticInfo: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      id: null,
      nm: null,
      hp: null,
      email: null,
      tgl_wawancara: null,
      tgl_tes: null,
      status_rekrutmen: null,
    };
  },
  mounted() {
    this.getRekruter();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getRekruter(data) {
      if (data?.nm !== undefined) {
        this.nm = data?.nm;
      }
      if (data?.no_hp !== undefined) {
        this.hp = data?.no_hp;
      }
      if (data?.email !== undefined) {
        this.email = data?.email;
      }
      if (data?.sdm_rekrutmen_ref?.tgl_wawancara !== undefined) {
        this.tgl_wawancara = data?.sdm_rekrutmen_ref?.tgl_wawancara;
      }
      if (data?.sdm_rekrutmen_ref?.tgl_tes !== undefined) {
        this.tgl_tes = data?.sdm_rekrutmen_ref?.tgl_tes;
      }
      if (data?.sdm_rekrutmen_ref?.status_rekrutmen !== undefined) {
        this.status_rekrutmen = data?.sdm_rekrutmen_ref?.status_rekrutmen;
      }
    },
  },
};
</script>
