<template>
  <div class="intro-y box mt-5">
    <div class="p-5">
      <div class="text-lg font-medium">
        <user-plus-icon class="mr-2 text-purple-700" /> Durasi Kontrak Kerja
      </div>
      <div class="mx-auto mt-3 w-52 border-t border-dashed sm:w-auto">
        <div v-if="isLoading" class="flex text-center mt-4">
          Loading Data...
        </div>
        <div v-else-if="kontrakKerja.length > 0">
          <div
            v-for="(item, index) in kontrakKerja"
            :key="index"
            class="flex items-center mt-5"
          >
            <span class="truncate font-medium"
              >{{ item.nm }}<br />
              <small class="text-gray-500 text-xs mt-0.5 font-normal">
                Berakhir: {{ item?.tgl_kontrak }}</small
              >
            </span>
            <strong
              class="ml-auto font-medium text-primary-1 dark:text-white"
              >{{ item?.kode }}</strong
            >
          </div>
        </div>
        <div v-else class="flex text-center mt-4 text-gray-400">
          No result found!
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="mt-5 p-5 bg-gray-100 dark:bg-dark-2">-->
  <!--    <div class="grid grid-cols-12 gap-x-6 2xl:gap-x-0">-->
  <!--      &lt;!&ndash; BEGIN: Transactions &ndash;&gt;-->
  <!--      <div-->
  <!--        class="col-span-12 mt-0 md:col-span-6 xl:col-span-4 2xl:col-span-12 2xl:mt-0"-->
  <!--      >-->
  <!--        <div class="flex items-center h-10 intro-x">-->
  <!--          <h2 class="text-lg font-medium truncate">-->
  <!--            Daftar Pegawai Masa Percobaan-->
  <!--          </h2>-->
  <!--        </div>-->
  <!--        <div class="mt-2">-->
  <!--          <div class="intro-x">-->
  <!--            <div v-if="listPegawaiMasaPercobaan.length > 0">-->
  <!--              <div-->
  <!--                v-for="(item, index) in listPegawaiMasaPercobaan"-->
  <!--                :key="index"-->
  <!--                class="flex items-center px-5 py-3 mb-3 box zoom-in"-->
  <!--              >-->
  <!--                <div-->
  <!--                  class="flex-none w-10 h-10 overflow-hidden rounded-full image-fit"-->
  <!--                >-->
  <!--                  <img-->
  <!--                    alt="Midone Tailwind HTML Admin Template"-->
  <!--                    src="https://api.mdp.ac.id/public/assets/logo/logo-umdp.png"-->
  <!--                  />-->
  <!--                </div>-->
  <!--                <div class="ml-4 mr-auto">-->
  <!--                  <div class="font-medium">{{ item.nm }}</div>-->
  <!--                  <div class="text-gray-500 text-xs mt-0.5">-->
  <!--                    Berakhir:-->
  <!--                    {{-->
  <!--                      $h.tanggal(item.sdm_rekrutmen_ref?.tgl_berakhir_training)-->
  <!--                    }}-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="px-2 py-1 text-xs font-medium text-primary-1">-->
  <!--                  {{ item.sdm_rekrutmen_ref?.KODE }}-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div v-else class="flex text-center mt-4 text-gray-400">-->
  <!--              No result found!-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; END: Transactions &ndash;&gt;-->
  <!--      &lt;!&ndash; BEGIN: Transactions &ndash;&gt;-->
  <!--      <div-->
  <!--        class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12 2xl:mt-3"-->
  <!--      >-->
  <!--        <div class="flex items-center h-10 intro-x">-->
  <!--          <h2 class="mr-5 text-lg font-medium truncate">-->
  <!--            Daftar Pegawai Kontrak #1-->
  <!--          </h2>-->
  <!--        </div>-->
  <!--        <div class="mt-2">-->
  <!--          <div class="intro-x">-->
  <!--            <div v-if="listPegawaiKontrak1.length > 0">-->
  <!--              <div-->
  <!--                v-for="(item, index) in listPegawaiKontrak1"-->
  <!--                :key="index"-->
  <!--                class="flex items-center px-5 py-3 mb-3 box zoom-in"-->
  <!--              >-->
  <!--                <div-->
  <!--                  class="flex-none w-10 h-10 overflow-hidden rounded-full image-fit"-->
  <!--                >-->
  <!--                  <img-->
  <!--                    alt="Midone Tailwind HTML Admin Template"-->
  <!--                    src="https://api.mdp.ac.id/public/assets/logo/logo-umdp.png"-->
  <!--                  />-->
  <!--                </div>-->
  <!--                <div class="ml-4 mr-auto">-->
  <!--                  <div class="font-medium">{{ item.nm }}</div>-->
  <!--                  <div class="text-gray-500 text-xs mt-0.5">-->
  <!--                    Berakhir:-->
  <!--                    {{-->
  <!--                      $h.tanggal(item.sdm_rekrutmen_ref?.tgl_berakhir_kontrak_1)-->
  <!--                    }}-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="px-2 py-1 text-xs font-medium text-primary-1">-->
  <!--                  {{ item.sdm_rekrutmen_ref?.KODE }}-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div v-else class="flex text-center mt-4 text-gray-400">-->
  <!--              No result found!-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; END: Transactions &ndash;&gt;-->
  <!--      &lt;!&ndash; BEGIN: Transactions &ndash;&gt;-->
  <!--      <div-->
  <!--        class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12 2xl:mt-3"-->
  <!--      >-->
  <!--        <div class="flex items-center h-10 intro-x">-->
  <!--          <h2 class="mr-5 text-lg font-medium truncate">-->
  <!--            Daftar Pegawai Kontrak #2-->
  <!--          </h2>-->
  <!--        </div>-->
  <!--        <div class="mt-2">-->
  <!--          <div class="intro-x">-->
  <!--            <div v-if="listPegawaiKontrak2.length > 0">-->
  <!--              <div-->
  <!--                v-for="(item, index) in listPegawaiKontrak2"-->
  <!--                :key="index"-->
  <!--                class="flex items-center px-5 py-3 mb-3 box zoom-in"-->
  <!--              >-->
  <!--                <div-->
  <!--                  class="flex-none w-10 h-10 overflow-hidden rounded-full image-fit"-->
  <!--                >-->
  <!--                  <img-->
  <!--                    alt="Midone Tailwind HTML Admin Template"-->
  <!--                    src="https://api.mdp.ac.id/public/assets/logo/logo-umdp.png"-->
  <!--                  />-->
  <!--                </div>-->
  <!--                <div class="ml-4 mr-auto">-->
  <!--                  <div class="font-medium">{{ item.nm }}</div>-->
  <!--                  <div class="text-gray-500 text-xs mt-0.5">-->
  <!--                    Berakhir:-->
  <!--                    {{-->
  <!--                      $h.tanggal(item.sdm_rekrutmen_ref?.tgl_berakhir_kontrak_2)-->
  <!--                    }}-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="px-2 py-1 text-xs font-medium text-primary-1">-->
  <!--                  {{ item.sdm_rekrutmen_ref?.KODE }}-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div v-else class="flex text-center mt-4 text-gray-400">-->
  <!--              No result found!-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; END: Transactions &ndash;&gt;-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
// import { wssister } from "@/services/wspddikti";

import { sdm } from "@/services/models";
import { helper } from "@/utils/helper";

export default {
  name: "KontrakPegawai",
  components: {},
  props: {},
  data() {
    return {
      // listPegawaiMasaPercobaan: [],
      // listPegawaiKontrak1: [],
      // listPegawaiKontrak2: [],
      isLoading: false,
      listKontrakKerja: [],
    };
  },
  computed: {
    kontrakKerja() {
      return this.listKontrakKerja.map((item) => {
        return {
          nm: item.nm,
          kode: item.KODE,
          tgl_kontrak:
            item.tgl_berakhir_kontrak_2 !== null
              ? helper.tanggal(item.tgl_berakhir_kontrak_2) + " (Kontrak 2)"
              : item.tgl_berakhir_kontrak_1 !== null
              ? helper.tanggal(item.tgl_berakhir_kontrak_1) + " (Kontrak 1)"
              : helper.tanggal(item.tgl_berakhir_training) +
                " (Masa Percobaan)",
        };
      });
    },
  },
  watch: {},
  mounted() {
    // this.getPegawaiMasaPercobaan();
    // this.getPegawaiKontrak1();
    // this.getPegawaiKontrak2();
    this.getKontrakKerja();
  },
  methods: {
    // async getPegawaiMasaPercobaan() {
    //   sdm.RekrutmenRef.getCustomPath("kontrakPegawai").then((res) => {
    //     if (res.status === 200) {
    //       this.listPegawaiMasaPercobaan = res.data?.result;
    //     }
    //   });
    // },
    // async getPegawaiKontrak1() {
    //   sdm.RekrutmenRef.getCustomPath("kontrakPegawai1").then((res) => {
    //     if (res.status === 200) {
    //       this.listPegawaiKontrak1 = res.data?.result;
    //     }
    //   });
    // },
    // async getPegawaiKontrak2() {
    //   sdm.RekrutmenRef.getCustomPath("kontrakPegawai2").then((res) => {
    //     if (res.status === 200) {
    //       this.listPegawaiKontrak2 = res.data?.result;
    //     }
    //   });
    // },
    async getKontrakKerja() {
      this.isLoading = true;
      sdm.RekrutmenRef.getCustomPath("kontrakkerja").then((res) => {
        if (res.status === 200) {
          this.listKontrakKerja = res.data?.result;
          this.isLoading = false;
        }
      });
    },
  },
};
</script>
