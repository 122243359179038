<template>
  <div>
    <div v-if="isSdmTeam()" class="grid grid-cols-12 gap-6">
      <div class="col-span-12 2xl:col-span-6">
        <main>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8 ml-2">
            <div
              class="flex items-start"
              :class="
                $store.state.sisterpddikti.sisterconnect
                  ? 'text-theme-9'
                  : 'text-gray-400'
              "
            >
              <BatteryChargingIcon class="w-5 h-5 mr-2" />
              Server SISTER Status: {{ $store.state.sisterpddikti.sistererror }}
            </div>
            <button
              class="flex items-end ml-auto text-gray-500 hover:text-gray-900"
              onclick="window.location.reload()"
            >
              <RefreshCcwIcon class="w-5 h-5 mr-2" />
              Reload Data
            </button>
          </div>
          <div class="intro-y box mt-5">
            <LayoutCari v-if="hasMainFeatures('search')"></LayoutCari>

            <div
              class="grid grid-cols-12 box mt-5 relative"
              style="z-index: -1"
            >
              <div
                class="col-span-12 p-8 border-t border-dashed lg:col-span-12 lg:border-t-0 lg:border-l border-slate-200 dark:border-darkmode-300"
              >
                <GrafikJJA class="mb-6"></GrafikJJA>
                <GrafikJumlahSDM></GrafikJumlahSDM>
              </div>
              <!--              <div-->
              <!--                class="flex flex-col justify-center col-span-12 px-8 py-12 lg:col-span-3"-->
              <!--              ></div>-->
            </div>
          </div>
        </main>
      </div>
      <div class="col-span-12 2xl:col-span-3 xl:border-r border-theme-5 pr-6">
        <MasaPensiun></MasaPensiun>
        <MasaKerja></MasaKerja>
      </div>
      <div class="col-span-12 2xl:col-span-3">
        <BirthdayReminder></BirthdayReminder>
        <KontrakPegawai></KontrakPegawai>
      </div>
    </div>
    <div v-else>
      <Profile></Profile>
    </div>
  </div>
</template>
<script>
import LayoutCari from "./component/LayoutCari";
import GrafikJJA from "./component/GrafikJJA";
import GrafikJumlahSDM from "./component/GrafikJumlahSDM";
import store from "@/store";
import KontrakPegawai from "@/views/dashboard-mdp/component/KontrakPegawai";
import BirthdayReminder from "@/views/dashboard-mdp/component/BirthdayReminder";
import MasaPensiun from "@/views/dashboard-mdp/component/MasaPensiun";
import MasaKerja from "@/views/dashboard-mdp/component/MasaKerja";
import Profile from "@/views/dashboard-mdp/Profile";
import { isSdmTeam } from "@/utils/menus";

// import { wspddikti } from "@/services/wspddikti";
export default {
  components: {
    GrafikJJA,
    GrafikJumlahSDM,
    LayoutCari,
    KontrakPegawai,
    BirthdayReminder,
    MasaPensiun,
    MasaKerja,
    Profile,
  },
  data() {},
  computed: {
    // feederconnect() {
    //   return this.$store.state.feederpddikti.feederconnect;
    // },
    // feederserver() {
    //   return this.$store.state.feederpddikti.feederserver;
    // },
    // feedertoken() {
    //   return this.$store.state.feederpddikti.feedertoken;
    // },
    // feedererror() {
    //   return this.$store.state.feederpddikti.feedererror;
    // },
    responseMessage() {
      return this.$store.state.main.responseMessage;
    },
  },
  watch: {
    responseMessage: {
      handler: function () {},
      immediate: true,
    },
  },
  mounted() {
    // this.getSdm();
    // this.getDosen();
  },
  methods: {
    isSdmTeam,
    closeAndClear() {
      store.commit("main/clearResponse");
    },
    // async getSdm() {
    //   store.dispatch("feederpddikti/feederserver", "univ");
    //   wspddikti
    //     .GetToken("dorie.umdp021037", "PDDIKTI.umdp021037")
    //     .then((result) => {
    //       if (result === true) {
    //         this.connected = true;
    //         this.connMsg =
    //           "Connected to Server " +
    //           this.feederserver.toUpperCase() +
    //           " PDDIKTI";
    //       } else {
    //         this.connected = false;
    //       }
    //     });
    // },
    // async getDosen() {
    //   wspddikti
    //     .GetData({
    //       act: "GetJabfung",
    //       // filter: "id_dosen='06270695-a4fc-4fe4-b6d6-69ed1abe5598'",
    //     })
    //     .then((res) => {
    //       console.log(res);
    //     });
    // },
  },
};
</script>
