<template>
  <form action="" @submit.prevent="submit">
    <div v-if="isLoading" class="p-5">Loading data...</div>
    <div v-else class="intro-y bg-gray-200 p-5 col-span-12 lg:col-span-6">
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
        <div class="col-span-12 form-inline">
          <label class="form-label font-medium w-32">Nama Lengkap</label>
          <input
            v-model="dosen['nm']"
            class="form-control focus:ring-0"
            placeholder="Nama Lengkap"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Jenis Kelamin</label>
          <div class="flex flex-col sm:flex-row">
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2"
            >
              <input
                id="radio-switch-1"
                v-model="dosen['jk']"
                class="form-check-input bg-white"
                type="radio"
                value="L"
              />
              <label class="form-check-label" for="radio-switch-1"
                >Laki-Laki</label
              >
            </div>
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2 mt-2 sm:mt-0"
            >
              <input
                id="radio-switch-2"
                v-model="dosen['jk']"
                class="form-check-input bg-white"
                type="radio"
                value="P"
              />
              <label class="form-check-label" for="radio-switch-2"
                >Perempuan</label
              >
            </div>
          </div>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Tanggal Lahir</label>
          <input
            v-model="dosen['tgl_lahir']"
            type="date"
            class="form-control focus:ring-0"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Tempat Lahir</label>
          <input
            v-model="dosen['tmpt_lahir']"
            class="form-control focus:ring-0"
            placeholder="Kota Kelahiran"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Tanggal Masuk</label>
          <input
            v-model="dosen['tgl_mulai_kerja']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32"
            >Tanggal Pengangkatan Karyawan Tetap</label
          >
          <input
            v-model="dosen['tgl_pengangkatan']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Tanggal Keluar</label>
          <input
            v-model="dosen['tgl_keluar']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Alamat</label>
          <input
            v-model="dosen['alamat']"
            class="form-control focus:ring-0"
            placeholder="Alamat"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Kelurahan</label>
          <input
            v-model="dosen['kel']"
            class="form-control focus:ring-0"
            placeholder="Kelurahan"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Kecamatan</label>
          <input
            v-model="dosen['kec']"
            class="form-control focus:ring-0"
            placeholder="Kecamatan"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Kode Pos</label>
          <input
            v-model="dosen['kode_pos']"
            class="form-control focus:ring-0"
            placeholder="Kode Pos"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Agama</label>
          <TailSelect
            v-model="dosen['agama']"
            :options="{
              search: true,
              classNames: 'form-control',
              deselect: false,
            }"
          >
            <option
              v-for="list in ref_agama"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Status Perkawinan</label>
          <TailSelect
            v-model="dosen['statkawin']"
            :options="{
              search: true,
              classNames: 'form-control',
              deselect: false,
            }"
          >
            <option
              v-for="list in ref_statkawin"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">HP</label>
          <input
            v-model="dosen['no_hp']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="HP"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Email</label>
          <input
            v-model="dosen['email']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="Email"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Status Dosen</label>
          <div class="flex flex-col sm:flex-row">
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2"
            >
              <input
                id="radio-status_dosen-1"
                v-model="dosen['tenaga_pendidik']"
                class="form-check-input bg-white"
                type="radio"
                value="1"
              />
              <label
                class="form-check-label text-purple-700 w-24"
                for="radio-status_dosen-1"
                >Dosen Tetap</label
              >
            </div>
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2 mt-2 sm:mt-0"
            >
              <input
                id="radio-status_dosen-2"
                v-model="dosen['tenaga_pendidik']"
                class="form-check-input bg-white"
                type="radio"
                value="2"
              />
              <label
                class="form-check-label text-blue-900 w-auto"
                for="radio-status_dosen-2"
                >Dosen LB</label
              >
            </div>
          </div>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">No. Telepon</label>
          <input
            v-model="dosen['telepon']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="No. Telepon"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Posisi</label>
          <input
            v-model="dosen['posisi']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="Posisi"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Unit kerja</label>
          <input
            v-model="dosen['unit_kerja']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="Unit kerja"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Fakultas</label>
          <TailSelect
            v-model="dosen['fakultas']"
            :options="{
              search: true,
              classNames: 'form-control',
              deselect: false,
            }"
          >
            <option
              v-for="list in ref_fakultas"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">NIK</label>
          <input
            v-model="dosen['nik']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="NIK (KTP)"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">NPWP</label>
          <input
            v-model="dosen['npwp']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="NPWP"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">BPJS TK</label>
          <input
            v-model="dosen['bpjs_tk']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="No. BPJS TK"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">BPJS KS</label>
          <input
            v-model="dosen['bpjs_ks']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="No. BPJS KS"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">No. Rekening</label>
          <input
            v-model="dosen['norek']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="No. Rekening"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">NIDN</label>
          <input
            v-model="dosen['nidn']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="NIDN"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Golongan Darah</label>
          <input
            v-model="dosen['gol_darah']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="Golongan Darah"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">rfid_id</label>
          <input
            v-model="dosen['rfid_id']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="rfid_id"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">uhf_id</label>
          <input
            v-model="dosen['uhf_id']"
            type="text"
            class="form-control focus:ring-0"
            placeholder="uhf_id"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-32">Status Keaktifan</label>
          <div class="flex flex-col sm:flex-row">
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2"
            >
              <input
                id="radio-status_aktif-1"
                v-model="dosen['status_aktif']"
                class="form-check-input bg-white"
                type="radio"
                value="Y"
              />
              <label
                class="form-check-label text-green-700 w-24"
                for="radio-status_aktif-1"
                >Aktif</label
              >
            </div>
            <div
              class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2 mt-2 sm:mt-0"
            >
              <input
                id="radio-status_aktif-2"
                v-model="dosen['status_aktif']"
                class="form-check-input bg-white"
                type="radio"
                value="T"
              />
              <label
                class="form-check-label text-red-900 w-auto"
                for="radio-status_aktif-2"
                >Tidak Aktif</label
              >
            </div>
          </div>
        </div>
        <div class="col-span-12 mt-4">
          <div class="flex justify-end flex-col md:flex-row gap-2 mt-5">
            <button type="submit" class="btn py-3 btn-primary w-full md:w-52">
              <SaveIcon class="w-4 h-4 mr-2" /> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import LoadingSpinner from "@/components/loading-spinner/Main";
import { ref, sdm } from "@/services/models";
// import { sdm } from "@/services/models";

export default {
  components: {
    LoadingSpinner,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    datasrc: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dosen: {
        KODE: this.datasrc.KODE,
        id_rekrutmen: this.datasrc.sdmrekrutmen?.id_rekrutmen,
        nm: this.datasrc.sdmrekrutmen?.nm, // this.datasrc.NM_DOSEN,
        jk: this.datasrc?.JENIS_KEL
          ? this.datasrc?.JENIS_KEL
          : this.datasrc.sdmrekrutmen?.jk, // this.datasrc.JENIS_KEL,
        tgl_lahir: this.datasrc?.TGL_LAHIR
          ? this.datasrc?.TGL_LAHIR
          : this.datasrc.sdmrekrutmen?.tgl_lahir, // this.datasrc.TGL_LAHIR,
        tmpt_lahir: this.datasrc.sdmrekrutmen?.tmpt_lahir, // sdmrekrutmen,
        nik: this.datasrc?.ktp
          ? this.datasrc?.ktp
          : this.datasrc.sdmrekrutmen?.nik, // this.datasrc.ktp,
        agama: this.datasrc?.AGAMA
          ? this.datasrc?.AGAMA
          : this.datasrc.sdmrekrutmen?.agama, // sdmrekrutmen,
        alamat: this.datasrc?.ALAMAT1
          ? this.datasrc?.ALAMAT1
          : this.datasrc.sdmrekrutmen?.alamat, // this.datasrc.ALAMAT1,
        kel: this.datasrc.sdmrekrutmen?.kel, // sdmrekrutmen,
        kec: this.datasrc.sdmrekrutmen?.kec, // sdmrekrutmen,
        kode_pos: this.datasrc.sdmrekrutmen?.kode_pos, // sdmrekrutmen,
        statkawin: this.datasrc.sdmrekrutmen?.stat_kawin, // this.datasrc.STATUS_PERKAWINAN,
        no_hp: this.datasrc?.HP
          ? this.datasrc?.HP
          : this.datasrc.sdmrekrutmen?.no_hp, // this.datasrc.HP,
        email: this.datasrc?.EMAIL
          ? this.datasrc?.EMAIL
          : this.datasrc.sdmrekrutmen?.email, // this.datasrc.EMAIL,
        posisi: this.datasrc.posisi, // this.datasrc.EMAIL,
        unit_kerja: this.datasrc.unit_kerja, // this.datasrc.EMAIL,
        fakultas: this.datasrc.fakultas, // this.datasrc.EMAIL,
        npwp: this.datasrc.sdmrekrutmen?.npwp, // sdmrekrutmen,
        bpjs_tk: this.datasrc.bpjs_tk, // sdmrekrutmen,
        bpjs_ks: this.datasrc.bpjs_ks, // sdmrekrutmen,
        kewarganegaraan: this.datasrc.sdmrekrutmen?.kewarganegaraan, // sdmrekrutmen,
        id_wil: this.datasrc.sdmrekrutmen?.id_wil, // sdmrekrutmen,
        gol_darah: this.datasrc?.gol_darah
          ? this.datasrc?.gol_darah
          : this.datasrc.sdmrekrutmen?.gol_darah, // this.datasrc.gol_darah,
        status_aktif: this.datasrc.sdmrekrutmen?.status_aktif, // this.datasrc.Aktif,
        tgl_mulai_kerja:
          this.datasrc.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_mulai_kerja, // sdmrekrutmenref // this.datasrc.TGL_MASUK,
        tgl_pengangkatan:
          this.datasrc.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_pengangkatan, // sdmrekrutmenref // this.datasrc.sdmrekrutmen?.sdm_rekrutmen_ref?.tgl_pengangkatan,
        tenaga_pendidik:
          this.datasrc.sdmrekrutmen?.sdm_rekrutmen_ref?.tenaga_pendidik, // sdmrekrutmenref // this.datasrc.STATUS_DOSEN,
        j_anak: this.datasrc.J_ANAK,
        telepon: this.datasrc.TELPON,
        tgl_keluar: this.datasrc.tgl_keluar,
        norek: this.datasrc.NOREK,
        nidn: this.datasrc.NIDN,
        rfid_id: this.datasrc.rfid_id,
        uhf_id: this.datasrc.uhf_id,
      },
      ref_agama: [],
      ref_statkawin: [
        {
          id: 0,
          value: "B",
          text: "Belum Kawin",
          selected: false,
        },
        {
          id: 1,
          value: "N",
          text: "Kawin",
          selected: false,
        },
      ],
      ref_fakultas: [
        {
          id: 0,
          value: "",
          text: "Pilih Fakultas",
          selected: false,
        },
        {
          id: 1,
          value: "fikr",
          text: "Fakultas Ilmu Komputer dan Rekayasa",
          selected: false,
        },
        {
          id: 2,
          value: "feb",
          text: "Fakultas Ekonomi dan Bisnis",
          selected: false,
        },
      ],
      isLoading: false,
      useState: false,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    datasrc: {
      handler: function (data) {
        if (data?.KODE !== undefined) {
          this.isLoading = false;
          this.$store.dispatch("main/responseMessage", "");
          this.$store.dispatch("main/isSuccess", null);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAgama();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getAgama() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Agama",
        selected: true,
      };
      this.ref_agama.push(defaultOpt);
      ref.agama.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_agama.push({
              id: element.id,
              value: element.id,
              text: element.name,
              selected: false,
            });
          });
        }
      });
    },
    submit() {
      this.isLoading = true;

      let formdata = Object.assign({}, this.dosen);

      if (this.id?.length > 0) {
        sdm.Dosen.postCustomPath("update_dosen_sdm/" + this.id, formdata).then(
          (res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$store.dispatch("main/isSuccess", true);
                this.$store.dispatch("main/responseMessage", res.data.message);
                this.$emit("simpanBio", true);
              } else {
                // Object.keys(res.data.message).forEach((key) => {
                //   this.messageAlert.push(res.data.message[key][0]);
                // });
              }
            }

            this.isLoading = false;
            this.scrollToTop();
          }
        );
      }
    },
  },
};
</script>

<style></style>
