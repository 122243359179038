<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Data Dosen
        <br />
        <span class="text-sm font-medium"
          >Profil Dosen - Pendidik dan Tenaga Kependidikan</span
        >
      </h2>
      <div
        v-if="hasFeatures('search:dosen')"
        class="w-full sm:w-auto flex mt-4 sm:mt-0"
      >
        <CariDosen />
      </div>
    </div>
    <div class="intro-y box px-5 pt-5 mt-5">
      <InfoDosen :dosen="dataDosen"></InfoDosen>
      <div
        class="nav nav-tabs flex-col flex-wrap sm:flex-row justify-start"
        role="tablist"
      >
        <a
          id="data-pribadi"
          data-toggle="tab"
          data-target="#data-pribadi-tab"
          href="javascript:"
          class="py-4 sm:mr-8 flex items-center active"
          role="tab"
          aria-controls="profile"
          aria-selected="true"
        >
          <UserIcon class="w-4 h-4 mr-2" /> Data Pribadi
        </a>
        <a
          v-if="dataDosen?.sdm"
          id="riwayat-pendidikan"
          data-toggle="tab"
          data-target="#riwayat-pendidikan-tab"
          href="javascript:"
          class="py-4 sm:mr-8 flex items-center"
          role="tab"
          aria-controls="pendidikan"
          aria-selected="true"
        >
          <BookIcon class="w-4 h-4 mr-2" /> Riwayat Pendidikan
        </a>
        <a
          v-if="dataDosen?.sdm"
          id="data-sister"
          data-toggle="tab"
          data-target="#data-sister-tab"
          href="javascript:"
          class="py-4 sm:mr-8 flex items-center"
          role="tab"
          aria-selected="false"
        >
          <DatabaseIcon class="w-4 h-4 mr-2" /> Data Sister
        </a>
        <a
          id="data-simarta"
          data-toggle="tab"
          data-target="#data-simarta-tab"
          href="javascript:"
          class="py-4 sm:mr-8 flex items-center"
          role="tab"
          aria-selected="false"
        >
          <DatabaseIcon class="w-4 h-4 mr-2" /> Data SIMARTA
        </a>
        <a
          id="data-sp"
          data-toggle="tab"
          data-target="#data-sp-tab"
          href="javascript:"
          class="py-4 sm:mr-8 flex items-center"
          role="tab"
          aria-selected="false"
        >
          <BriefcaseIcon class="w-4 h-4 mr-2" /> Reward & Punishment
        </a>
      </div>
    </div>

    <div class="tab-content mt-5">
      <div
        id="data-pribadi-tab"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y box col-span-6 lg:col-span-6">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">
                Data Pribadi (Internal Server)
              </h2>
              <button
                class="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary dark:border-primary w-32 mb-2 mr-2"
                @click="editmode = !editmode"
              >
                <span v-if="editmode">Cancel Edit</span>
                <span v-else
                  ><edit-icon class="w-4 h-4 mr-1"></edit-icon> Edit Data</span
                >
              </button>
            </div>
            <div class="tab-content">
              <formdataPribadiMDP
                v-if="editmode"
                :id="id"
                :datasrc="dataDosen"
                @simpanBio="onSimpanBio"
              />
              <dataPribadiMDP v-else :sdm="dataDosen" :is-saving="isSaving" />
            </div>
          </div>
          <div class="col-span-6 lg:col-span-6">
            <div class="intro-y box">
              <div
                class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Data Pribadi (PDDIKTI Server)
                </h2>
              </div>
              <div class="tab-content">
                <dataPribadiPDDIKTI :sdm="dataDosen?.sdm" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="riwayat-pendidikan-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="pendidikan-tab"
      >
        <!--        <div class="grid grid-cols-12 gap-6">-->
        <!--          <div class="intro-y box col-span-6 lg:col-span-6">-->
        <!--            <div-->
        <!--              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"-->
        <!--            >-->
        <!--              <h2 class="font-medium text-base mr-auto">-->
        <!--                Riwayat Pendidikan Formal-->
        <!--              </h2>-->
        <!--            </div>-->

        <!--            <div class="tab-content">-->
        <!--              <div class="intro-y p-5 col-span-6 lg:col-span-6">-->
        <!--                <dataRwyPendidikan :sdm="dataTendik?.sdmrekrutmen" />-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="intro-y box col-span-6 lg:col-span-6">-->
        <!--            <div-->
        <!--              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"-->
        <!--            >-->
        <!--              <h2 class="font-medium text-base mr-auto">-->
        <!--                Riwayat Pendidikan Non-Formal-->
        <!--              </h2>-->
        <!--            </div>-->

        <!--            <div class="tab-content">-->
        <!--              <div class="intro-y p-5 col-span-6 lg:col-span-6"></div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="box">
              <div class="px-4 pb-3 pt-5">
                <div class="font-medium text-base px-2 py-2">
                  Riwayat Pendidikan
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnRwyPendidikan == 'RwyPendidikanFormal'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnRwyPendidikan = 'RwyPendidikanFormal'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Riwayat Pendidikan
                    Formal</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnRwyPendidikan == 'RwyPendidikanNonFormal'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnRwyPendidikan = 'RwyPendidikanNonFormal'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Riwayat Pendidikan
                    Non-Formal</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
            <dataRwyPendidikan
              :key="componentRwyPendidikanKey"
              :sdm="dataDosen?.sdmrekrutmen"
              :tabon="tabOnRwyPendidikan"
              @onChange="onChangeRwyPendidikan"
            />
          </div>
        </div>
      </div>

      <div
        v-if="dataDosen?.sdm"
        id="data-sister-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="data-sister-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="box">
              <div class="px-4 pb-3 pt-5">
                <div class="font-medium text-base px-2 py-2">Profil</div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'datapribadi'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'datapribadi'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Data Pribadi</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'dokumen'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'dokumen'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Dokumen</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'inpassing'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'inpassing'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Inpassing</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 mt-1 cursor-pointer"
                  :class="
                    tabOnSister == 'jabatanfungsional'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'jabatanfungsional'"
                >
                  <span class="flex-1 truncate">
                    <CodepenIcon class="w-4 h-4 mr-2" /> Jabatan Fungsional
                  </span>
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 mt-1 cursor-pointer"
                  :class="
                    tabOnSister == 'kepangkatan'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'kepangkatan'"
                >
                  <div class="flex-1 truncate">
                    <CodepenIcon class="w-4 h-4 mr-2" /> Kepangkatan
                  </div>
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 mt-1 cursor-pointer"
                  :class="
                    tabOnSister == 'penempatan'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'penempatan'"
                >
                  <div class="flex-1 truncate">
                    <CodepenIcon class="w-4 h-4 mr-2" /> Penempatan
                  </div>
                </div>
              </div>
              <div
                class="px-4 py-3 border-t border-slate-200/60 dark:border-darkmode-400"
              >
                <div class="font-medium text-base px-2 py-2">Kualifikasi</div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'pendidikanformal'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'pendidikanformal'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Pendidikan
                    Formal</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'diklat'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'diklat'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Staff</span
                  >
                </div>
              </div>
              <div
                class="px-4 py-3 border-t border-slate-200/60 dark:border-darkmode-400"
              >
                <div class="font-medium text-base px-2 py-2">Kompetensi</div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'sertifikasi'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'sertifikasi'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Sertifikasi Profesi</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'sertifikasi_dosen'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'sertifikasi_dosen'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Sertifikasi Dosen</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'tes'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'tes'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Tes</span
                  >
                </div>
              </div>
              <div
                class="px-4 py-3 border-t border-slate-200/60 dark:border-darkmode-400"
              >
                <div class="font-medium text-base px-2 py-2">
                  Pelaks. Penelitian
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'penelitian'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'penelitian'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Penelitian</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'publikasikarya'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'publikasikarya'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Publikasi Karya</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'paten'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'paten'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Paten/HKI</span
                  >
                </div>
              </div>
              <div
                class="px-4 py-3 border-t border-slate-200/60 dark:border-darkmode-400"
              >
                <div class="font-medium text-base px-2 py-2">
                  Pelaks. Pengabdian
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'pengabdian'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'pengabdian'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Pengabdian</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'pengelolajurnal'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'pengelolajurnal'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Pengelola Jurnal</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'pembicara'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'pembicara'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Pembicara</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSister == 'jabatanstruktural'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSister = 'jabatanstruktural'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Jabatan
                    Struktural</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
            <div class="intro-y box">
              <dataSister :sdm="dataDosen?.sdm" :tabon="tabOnSister" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="data-simarta-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="data-simarta-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="box">
              <div class="px-4 pb-3 pt-5">
                <div class="font-medium text-base px-2 py-2">
                  Berkas SIMARTA
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSimarta == 'berkaspendataan'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSimarta = 'berkaspendataan'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Berkas Pendataan</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnSimarta == 'berkaspribadi'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnSimarta = 'berkaspribadi'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Berkas Pribadi</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
            <dataSimarta :sdm="dataDosen?.sdm" :tabon="tabOnSimarta" />
          </div>
        </div>
      </div>
      <div
        id="data-sp-tab"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="data-sp-tab"
      >
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="box">
              <div class="px-4 pb-3 pt-5">
                <div class="font-medium text-base px-2 py-2">
                  Reward & Punishment
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnRewardsp == 'reward'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnRewardsp = 'reward'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> Reward</span
                  >
                </div>
                <div
                  class="flex rounded-lg items-center px-4 py-2 cursor-pointer"
                  :class="
                    tabOnRewardsp == 'sp'
                      ? 'bg-primary-1 text-white font-medium'
                      : ''
                  "
                  @click="tabOnRewardsp = 'sp'"
                >
                  <span class="flex-1 truncate"
                    ><CodepenIcon class="w-4 h-4 mr-2" /> SP</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
            <dataRewardSp :sdm="dataDosen?.sdm" :tabon="tabOnRewardsp" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sdm } from "@/services/models";
import InfoDosen from "./partial/infoDosen";
import CariDosen from "@/components/form/CariDosen";
import formdataPribadiMDP from "./partial/formdataPribadiMDP.vue";
import dataPribadiMDP from "./partial/dataPribadiMDP.vue";
import dataPribadiPDDIKTI from "./partial/dataPribadiPDDIKTI.vue";
import dataSister from "./partial/dataSister";
import dataSimarta from "./partial/dataSimarta";
import dataRewardSp from "./partial/dataRewardsp";
import dataRwyPendidikan from "@/views/tendik/partial/dataRwyPendidikan.vue";

export default {
  components: {
    InfoDosen,
    CariDosen,
    dataRwyPendidikan,
    dataPribadiPDDIKTI,
    formdataPribadiMDP,
    dataPribadiMDP,
    dataSister,
    dataSimarta,
    dataRewardSp,
  },
  data() {
    return {
      editmode: false,
      id: this.$route.params.id,
      dataDosen: [],
      tabOnSister: "",
      tabOnRwyPendidikan: "",
      tabOnSimarta: "",
      tabOnRewardsp: "",
      tabactive: null,
      isSaving: false,
      componentRwyPendidikanKey: 0,
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
  },
  mounted() {
    this.getDetailDosen();
  },
  methods: {
    async getDetailDosen() {
      if (this.id?.length > 0) {
        sdm.Dosen.getCustomPath(`getDetailDosen/${this.id}`).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.dataDosen = res.data.data;
            }
          }
        });
      }
    },
    onChangeRwyPendidikan(value) {
      if (value == true) {
        this.getDetailDosen();
        this.componentRwyPendidikanKey += 1;
      }
    },
    onSimpanBio(value) {
      if (value == true) {
        this.editmode = false;
        this.isSaving = true;
        this.getDetailDosen();
      }
    },
  },
};
</script>
