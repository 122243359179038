<template>
  <form action="" @submit.prevent="submit">
    <div
      v-if="newRecruitment"
      class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-5"
    >
      <h2 class="text-lg font-medium mr-auto">Rekrutmen Pegawai Baru</h2>
      <router-link :to="{ name: 'recruitment' }" class="btn btn-success ml-auto"
        >List Rekrutmen</router-link
      >
    </div>
    <div class="intro-y">
      <div>
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div v-if="alertFailed">
          <AlertFailed
            v-for="(item, index) in messageAlert"
            :key="index"
            :message="item"
            :dismissable="true"
          />
        </div>
      </div>
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
          <div class="intro-y" :class="newRecruitment ? 'box' : ''">
            <div
              class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">Data Kepegawaian</h2>
            </div>
            <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-52">Nama Lengkap</label>
                <input
                  v-model="dataRekrutmen['nm']"
                  class="form-control focus:ring-0"
                  placeholder="Nama Lengkap"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Jenis Kelamin</label>
                <div class="flex flex-col sm:flex-row">
                  <div
                    class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2"
                  >
                    <input
                      id="radio-switch-1"
                      v-model="dataRekrutmen['jk']"
                      class="form-check-input"
                      type="radio"
                      value="L"
                    />
                    <label class="form-check-label" for="radio-switch-1"
                      >Laki-Laki</label
                    >
                  </div>
                  <div
                    class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2 mt-2 sm:mt-0"
                  >
                    <input
                      id="radio-switch-2"
                      v-model="dataRekrutmen['jk']"
                      class="form-check-input"
                      type="radio"
                      value="P"
                    />
                    <label class="form-check-label" for="radio-switch-2"
                      >Perempuan</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium w-52">Tanggal Lahir</label>
                <input
                  v-model="dataRekrutmen['tgl_lahir']"
                  type="date"
                  class="form-control focus:ring-0 mt-2"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium w-52">Tempat Lahir</label>
                <input
                  v-model="dataRekrutmen['tmpt_lahir']"
                  class="form-control focus:ring-0"
                  placeholder="Kota Kelahiran"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium w-52"
                  >Golongan Darah</label
                >
                <input
                  v-model="dataRekrutmen['gol_darah']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Golongan Darah"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Agama</label>
                <TailSelect
                  v-model="dataRekrutmen['agama']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_agama"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Alamat</label>
                <input
                  v-model="dataRekrutmen['alamat']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Alamat tempat tinggal"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium w-52">Kelurahan</label>
                <input
                  v-model="dataRekrutmen['kel']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Kelurahan"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium">Kecamatan</label>
                <input
                  v-model="dataRekrutmen['kec']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Kecamatan"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-4 mt-4 form-inline">
                <label class="form-label font-medium">Kode Pos</label>
                <input
                  v-model="dataRekrutmen['kode_pos']"
                  type="text"
                  class="form-control focus:ring-0"
                  placeholder="Kode Pos"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Nomor HP/Telp</label>
                <input
                  v-model="dataRekrutmen['no_hp']"
                  type="text"
                  maxlength="20"
                  class="form-control focus:ring-0"
                  placeholder="Nomor HP: 08XX"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">Email</label>
                <input
                  v-model="dataRekrutmen['email']"
                  type="email"
                  class="form-control focus:ring-0"
                  placeholder="Email"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">NIK</label>
                <input
                  v-model="dataRekrutmen['nik']"
                  type="number"
                  maxlength="16"
                  class="form-control focus:ring-0"
                  placeholder="NIK"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52">NPWP</label>
                <input
                  v-model="dataRekrutmen['npwp']"
                  type="number"
                  maxlength="16"
                  class="form-control focus:ring-0"
                  placeholder="NPWP"
                  autocomplete="off"
                />
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium w-52"
                  >Kewarganegaraan</label
                >
                <TailSelect
                  v-model="dataRekrutmen['kewarganegaraan']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_negara"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <!--              <div class="col-span-12 mt-4 form-inline">-->
              <!--                <label class="form-label font-medium w-52">Wilayah</label>-->
              <!--                <TailSelect-->
              <!--                  v-model="dataRekrutmen['id_wil']"-->
              <!--                  :options="{-->
              <!--                    search: true,-->
              <!--                    classNames: 'form-control',-->
              <!--                    deselect: false,-->
              <!--                  }"-->
              <!--                >-->
              <!--                  <option-->
              <!--                    v-for="list in ref_wilayah"-->
              <!--                    :key="list.value"-->
              <!--                    :value="list.value"-->
              <!--                    :selected="list.selected"-->
              <!--                  >-->
              <!--                    {{ list.text }}-->
              <!--                  </option>-->
              <!--                </TailSelect>-->
              <!--              </div>-->
              <!--              <div class="col-span-12 mt-4 form-inline">-->
              <!--                <label class="form-label font-medium w-52"-->
              <!--                  >Pangkat Golongan</label-->
              <!--                >-->
              <!--                <TailSelect-->
              <!--                  v-model="dataRekrutmen['id_pangkat_gol']"-->
              <!--                  :options="{-->
              <!--                    search: true,-->
              <!--                    classNames: 'form-control',-->
              <!--                    deselect: false,-->
              <!--                  }"-->
              <!--                >-->
              <!--                  <option-->
              <!--                    v-for="list in ref_pangkatgolongan"-->
              <!--                    :key="list.value"-->
              <!--                    :value="list.value"-->
              <!--                    :selected="list.selected"-->
              <!--                  >-->
              <!--                    {{ list.text }}-->
              <!--                  </option>-->
              <!--                </TailSelect>-->
              <!--              </div>-->
              <!--              <div class="col-span-6 mt-4 form-inline">-->
              <!--                <label class="form-label font-medium w-52"-->
              <!--                  >Tanggal Wawancara</label-->
              <!--                >-->
              <!--                <input-->
              <!--                  v-model="dataRekrutmen['tgl_wawancara']"-->
              <!--                  type="date"-->
              <!--                  class="form-control focus:ring-0 mt-2"-->
              <!--                  autocomplete="off"-->
              <!--                />-->
              <!--              </div>-->
              <!--              <div class="col-span-6 mt-4 form-inline">-->
              <!--                <label class="form-label font-medium w-52">Tanggal Tes</label>-->
              <!--                <input-->
              <!--                  v-model="dataRekrutmen['tgl_tes']"-->
              <!--                  type="date"-->
              <!--                  class="form-control focus:ring-0 mt-2"-->
              <!--                  autocomplete="off"-->
              <!--                />-->
              <!--              </div>-->
            </div>

            <hr />

            <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-52"
                  >Tanggal Wawancara</label
                >
                <div class="mr-4 w-64">
                  <input
                    v-model="dataRekrutmenref['tgl_wawancara']"
                    type="date"
                    class="form-control focus:ring-0 mt-2"
                    autocomplete="off"
                  />
                </div>
                <div class="leading-relaxed text-gray-600 text-xs mt-2">
                  {{
                    moment(dataRekrutmenref["tgl_wawancara"]).format(
                      "Do MMMM, YYYY"
                    )
                  }}
                </div>
              </div>
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Tanggal Tes</label
                >
                <div class="mr-4 w-64">
                  <input
                    v-model="dataRekrutmenref['tgl_tes']"
                    type="date"
                    class="form-control focus:ring-0 mt-2"
                    autocomplete="off"
                  />
                </div>
                <div class="leading-relaxed text-gray-600 text-xs mt-2">
                  {{
                    moment(dataRekrutmenref["tgl_tes"]).format("Do MMMM, YYYY")
                  }}
                </div>
              </div>
              <div class="col-span-12 mt-5 form-inline">
                <label class="form-label font-medium font-medium w-52 mt-2"
                  >Jenis SDM</label
                >
                <TailSelect
                  v-model="dataRekrutmenref['id_jns_sdm']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_jenis_sdm"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-12 mt-2 form-inline">
                <div
                  class="w-full border-t border-slate-200/60 dark:border-darkmode-400 mt-5"
                ></div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52">Nilai TPA</label>
                <input
                  v-model="dataRekrutmenref['nilai_tpa']"
                  type="number"
                  class="form-control focus:ring-0 w-48"
                  placeholder="Nilai TPA"
                />
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2">TPA</label>
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-tpa-1"
                      v-model="dataRekrutmenref['tes_tpa']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-tpa-1"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-tpa-2"
                      v-model="dataRekrutmenref['tes_tpa']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-tpa-2"
                      >Tidak Lulus</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Tes Wawancara</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-3"
                      v-model="dataRekrutmenref['tes_wawancara']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-3"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-4"
                      v-model="dataRekrutmenref['tes_wawancara']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-4"
                      >Tidak Lulus</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Micro Teaching (Dosen)</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-5"
                      v-model="dataRekrutmenref['micro_teaching']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-5"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-6"
                      v-model="dataRekrutmenref['micro_teaching']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-6"
                      >Tidak Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-61"
                      v-model="dataRekrutmenref['micro_teaching']"
                      class="form-check-input"
                      type="radio"
                      value=""
                    />
                    <label class="form-check-label" for="radio-switch-61"
                      >Tidak Ada</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Tes Psikologi</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-8"
                      v-model="dataRekrutmenref['tes_psikologi']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-8"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-9"
                      v-model="dataRekrutmenref['tes_psikologi']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-9"
                      >Tidak Lulus</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-2 form-inline">
                <div
                  class="w-full border-t border-slate-200/60 dark:border-darkmode-400 mt-5"
                ></div>
              </div>
              <div class="col-span-12 mt-5 form-inline">
                <label class="form-label font-medium font-medium w-52 mt-2"
                  >Status Rekrutmen</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-10"
                      v-model="dataRekrutmenref['status_rekrutmen']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-10"
                      >Diterima</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-11"
                      v-model="dataRekrutmenref['status_rekrutmen']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-11"
                      >Ditolak</label
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="statusRekrutmen == 1"
                class="col-span-12 mt-5 p-5 intro-y box bg-gray-200"
              >
                <div class="col-span-12 form-inline">
                  <label class="form-label font-medium font-medium w-52"
                    >Tanggal Mulai Kerja</label
                  >
                  <div class="flex flex-col sm:flex-row">
                    <div class="mr-4 w-64 mt-3">
                      <input
                        v-model="dataRekrutmenref['tgl_mulai_kerja']"
                        type="date"
                        class="form-control focus:ring-0 mt-2"
                        autocomplete="off"
                        :readonly="statusRekrutmen < 1"
                      />
                      <div class="leading-relaxed text-gray-600 text-xs mt-2">
                        Tanggal Mulai Bekerja ({{
                          moment(dataRekrutmenref["tgl_mulai_kerja"]).format(
                            "Do MMMM, YYYY"
                          )
                        }})
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 my-4 form-inline">
                  <label class="form-label font-medium font-medium mt-5 w-52"
                    >NIK Pegawai</label
                  >
                  <div class="flex flex-col sm:flex-row">
                    <div class="mr-4 w-52 mt-5">
                      <TailSelect
                        v-model="dataRekrutmenref['tenaga_pendidik']"
                        :options="{
                          search: true,
                          classNames: 'form-control',
                          deselect: false,
                        }"
                      >
                        <option disabled selected>Pilih Posisi</option>
                        <option value="1">Dosen Tetap</option>
                        <option value="2">Dosen LB</option>
                        <option value="3">Tenaga Pendidik (Karyawan)</option>
                      </TailSelect>
                    </div>
                    <div class="mr-4 w-64 mt-3">
                      <input
                        v-model="dataRekrutmenref['KODE']"
                        type="text"
                        class="form-control mt-2 bg-slate-100 focus:ring-0"
                        :class="nikcreated ? 'ring-1 ring-primary-1' : ''"
                        placeholder="NIK"
                        readonly="readonly"
                      />
                    </div>
                    <div class="mr-4 w-64 mt-3 w-1/2 sm:w-auto">
                      <button
                        :disabled="!dataRekrutmenref['tenaga_pendidik']"
                        type="button"
                        class="btn btn-primary mt-2 w-full sm:w-auto"
                        @click="generateNik()"
                      >
                        <RepeatIcon class="mr-2 mr-2 w-4 h-4" /> BUAT NIK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end flex-col md:flex-row gap-2 mt-5">
            <router-link
              :to="{ name: 'recruitment' }"
              class="btn py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
              >Cancel</router-link
            >
            <button
              v-if="newRecruitment"
              type="reset"
              class="btn btn-warning py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
            >
              Reset</button
            ><button type="submit" class="btn py-3 btn-primary w-full md:w-52">
              <SaveIcon class="w-4 h-4 mr-2" /> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { ref, sdm } from "@/services/models";
// import { userdata, getuserdata } from "@/utils/userdata";
import moment from "moment";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  name: "AddRecruitment",
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  props: {
    newRecruitment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      dataRekrutmen: {
        nm: null,
        jk: null,
        tgl_lahir: null,
        tmpt_lahir: null,
        nik: null,
        agama: null,
        alamat: null,
        kel: null,
        kec: null,
        kode_pos: null,
        stat_kawin: null,
        no_hp: null,
        email: null,
        npwp: null,
        kewarganegaraan: null,
        id_wil: null,
        gol_darah: null,
        id_pangkat_gol: null,
      },
      dataRekrutmenref: {
        tgl_wawancara: null,
        tgl_tes: null,
        tenaga_pendidik: null,
        id_rekrutmen: null,
        nilai_tpa: null,
        tes_tpa: 0,
        tes_wawancara: 0,
        micro_teaching: null,
        tes_psikologi: 0,
        status_rekrutmen: null,
        tgl_mulai_kerja: null,
        KODE: null,
      },
      ref_pangkatgolongan: [],
      ref_agama: [],
      ref_negara: [],
      ref_wilayah: [],
      ref_jenis_sdm: [],
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      isLoading: false,
      useState: false,
      nikcreated: false,
    };
  },
  computed: {
    statusRekrutmen: function () {
      return this.dataRekrutmenref["status_rekrutmen"];
    },
  },
  watch: {
    statusRekrutmen: {
      handler: function (val) {
        if (val == 0) {
          this.dataRekrutmenref["tgl_mulai_kerja"] = null;
          this.dataRekrutmenref["KODE"] = null;
        }
      },
    },
  },
  mounted() {
    // this.getDetailRekrutmen();
    // this.getPangkatGolongan();
    this.getAgama();
    this.getNegara();
    // this.getWilayah();
    this.getJenisSdm();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    // async getDetailRekrutmen() {
    //   if (this.id?.length > 0) {
    //     this.isLoading = true;
    //     sdm.Rekrutmen.getCustomPath(`getDetailRekrutmen/${this.id}`)
    //       .then((res) => {
    //         if (res.status === 200) {
    //           if (res.data.code === 200) {
    //             this.dataRekrutmen = res.data.data;
    //
    //             // beda tipe data
    //             this.dataRekrutmen.idagama = res.data.data?.idagama
    //               ? res.data.data?.idagama.toString()
    //               : "";
    //
    //             this.$emit("detailDataRekrutmen", this.dataRekrutmen);
    //           }
    //         }
    //       })
    //       .finally(() => {
    //         this.isLoading = false;
    //       });
    //   }
    // },
    // async getPangkatGolongan() {
    //   let defaultOpt = {
    //     id: 0,
    //     value: "",
    //     text: "Pilih Pangkat Golongan",
    //     selected: true,
    //   };
    //   this.ref_pangkatgolongan.push(defaultOpt);
    //   ref.pangkatgolongan.getAll().then((res) => {
    //     if (res.status === 200) {
    //       res.data.data.forEach((element) => {
    //         this.ref_pangkatgolongan.push({
    //           id: element.id_pangkat_gol,
    //           value: element.id_pangkat_gol,
    //           text: element.nm_pangkat + " (" + element.kode_gol + ")",
    //           selected: false,
    //         });
    //       });
    //     }
    //   });
    // },
    async getAgama() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Agama",
        selected: true,
      };
      this.ref_agama.push(defaultOpt);
      ref.agama.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_agama.push({
              id: element.id,
              value: element.id,
              text: element.name,
              selected: false,
            });
          });
        }
      });
    },
    async getNegara() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Negara",
        selected: true,
      };
      this.ref_negara.push(defaultOpt);
      ref.negara.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_negara.push({
              id: element.id_negara,
              value: element.id_negara,
              text: element.nm_negara,
              selected: false,
            });
          });
        }
      });
    },
    // async getWilayah() {
    //   let defaultOpt = {
    //     id: 0,
    //     value: "",
    //     text: "Pilih Wilayah",
    //     selected: true,
    //   };
    //   this.ref_wilayah.push(defaultOpt);
    //   ref.wilayah.getAll().then((res) => {
    //     if (res.status === 200) {
    //       res.data.data.forEach((element) => {
    //         this.ref_wilayah.push({
    //           id: element.id_wil,
    //           value: element.id_wil,
    //           text: element.nm_wil,
    //           selected: false,
    //         });
    //       });
    //     }
    //   });
    // },
    async getJenisSdm() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Jenis SDM",
        selected: true,
      };
      this.ref_jenis_sdm.push(defaultOpt);
      ref.jenissdm.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_jenis_sdm.push({
              id: element.id,
              value: element.id_jns_sdm,
              text: element.nm_jns_sdm,
              selected: false,
            });
          });
        }
      });
    },
    generateNik() {
      sdm.RekrutmenRef.getCustomPath(
        `generateNik/${this.dataRekrutmenref["tenaga_pendidik"]}`
      ).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            this.dataRekrutmenref["KODE"] = res.data.result;
            this.nikcreated = true;
          }
        }
      });
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = [];
      this.isLoading = true;

      // if (this.id?.length > 0) {
      //   sdm.Rekrutmen.updateData(this.id, this.dataRekrutmen).then((res) => {
      //     if (res.status === 200) {
      //       if (res.data.code === 200) {
      //         this.alertSuccess = true;
      //         this.messageAlert = res.data.message;
      //       } else {
      //         this.alertFailed = true;
      //         Object.keys(res.data.message).forEach((key) => {
      //           this.messageAlert.push(res.data.message[key][0]);
      //         });
      //       }
      //     }
      //     this.isLoading = false;
      //     this.scrollToTop();
      //   });
      // } else {
      let formdata = {
        ...this.dataRekrutmen,
        ...this.dataRekrutmenref,
      };

      sdm.Rekrutmen.insertData(formdata).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;

            this.$router.push({
              name: "recruitment",
              params: {
                messageAlert: this.messageAlert,
                alertSuccess: true,
              },
            });
          } else {
            this.alertFailed = true;
            Object.keys(res.data.message).forEach((key) => {
              this.messageAlert.push(res.data.message[key][0]);
            });
          }
        }

        this.isLoading = false;
        this.scrollToTop();
      });
      // }
    },
  },
};
</script>
