<template>
  <Chart type="pie" :width="width" :height="height" :data="data" />
  <div class="mt-8">
    <div class="flex items-center">
      <div class="w-2 h-2 bg-red-500 rounded-full mr-3"></div>
      <span class="truncate"
        ><span class="text-red-500">Jumlah Pelatihan Internal</span></span
      >
      <div
        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
      ></div>
      <span class="font-medium xl:ml-auto">{{ pelatihanInternal }}</span>
    </div>
    <div class="flex items-center mt-4">
      <div class="w-2 h-2 bg-blue-500 rounded-full mr-3"></div>
      <span class="truncate"
        ><span class="text-blue-500">Jumlah Pelatihan External</span></span
      >
      <div
        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
      ></div>
      <span class="font-medium xl:ml-auto">{{ pelatihanEksternal }}</span>
    </div>
    <div class="flex items-center mt-4">
      <div class="w-2 h-2 bg-green-500 rounded-full mr-3"></div>
      <span class="truncate"
        ><span class="text-green-500">Jumlah Pengembangan Internal</span></span
      >
      <div
        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
      ></div>
      <span class="font-medium xl:ml-auto">{{ pengembanganInternal }}</span>
    </div>
    <div class="flex items-center mt-4">
      <div class="w-2 h-2 bg-yellow-500 rounded-full mr-3"></div>
      <span class="truncate"
        ><span class="text-yellow-500">Jumlah Pengembangan External</span></span
      >
      <div
        class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
      ></div>
      <span class="font-medium xl:ml-auto">{{ pengembanganEksternal }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    pelatihanInternal: {
      type: Number,
      default: 0,
    },
    pelatihanEksternal: {
      type: Number,
      default: 0,
    },
    pengembanganInternal: {
      type: Number,
      default: 0,
    },
    pengembanganEksternal: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.main.darkMode);

    const data = computed(() => {
      return {
        labels: [
          "Pelatihan Internal",
          "Pelatihan Eksternal",
          "Pengembangan Internal",
          "Pengembangan Eksternal",
        ],
        datasets: [
          {
            data: [
              props.pelatihanInternal,
              props.pelatihanEksternal,
              props.pengembanganInternal,
              props.pengembanganEksternal,
            ],
            backgroundColor: ["#ef4444", "#0b51b9", "#088f0a", "#dc5d0e"],
            hoverBackgroundColor: ["#ef4444", "#0b51b9", "#088f0a", "#dc5d0e"],
            borderWidth: 1,
            borderColor: darkMode.value ? "#fff" : "#fff",
          },
        ],
      };
    });

    return {
      data,
      props,
    };
  },
});
</script>
