<template>
  <div v-if="isLoading" class="h-full flex items-center">
    <div class="mx-auto text-center p-5">Loading data...</div>
  </div>
  <div v-else>
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div
            class="w-32 h-32 flex-none image-fit relative sm:w-24 sm:h-24 lg:w-48 lg:h-48"
          >
            <img
              alt="SDM Universitas MDP"
              class="rounded-full"
              :src="userData?.profile_photo_url"
            />
          </div>
          <div class="ml-6">
            <h2 class="text-3xl font-medium leading-none mb-2">
              Selamat Datang
            </h2>
            <h1 class="text-5xl font-medium mb-3 turncate">
              {{
                user?.sdm
                  ? user?.sdm?.nm_sdm
                  : user?.NM_DOSEN
                  ? user?.NM_DOSEN
                  : user?.NM_TENDIK
              }}
            </h1>
            <p class="text-xl text-gray-600">
              {{
                user?.STATUS_DOSEN == 2
                  ? "Dosen LB"
                  : user?.sdm
                  ? user?.sdm?.jenis_sdm?.nm_jns_sdm + " • " + user.unit_kerja
                  : user.unit_kerja
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <Pengumuman></Pengumuman>
  </div>
</template>

<script>
import { sdm } from "@/services/models";
import Pengumuman from "@/views/dashboard-mdp/component/Pengumuman";

export default {
  name: "Profile",
  components: {
    Pengumuman,
  },
  props: {
    sister: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      userData: this.$store.state.userdata?.userData,
      currentTeam: this.$store.state.userdata?.currentTeam,
      user: null,
    };
  },
  watch: {
    getProfile: {
      handler: function () {
        if (this.currentTeam?.id == 3) {
          sdm.Dosen.getCustomPath(`getDetailDosen/${this.userData?.uid}`).then(
            (res) => {
              if (res.status === 200) {
                if (res.data.code === 200) {
                  this.user = res.data.data;
                  this.isLoading = false;
                }
              }
            }
          );
        } else if (this.currentTeam?.id == 4) {
          sdm.Tendik.getCustomPath(
            `getDetailTendik/${this.userData?.uid}`
          ).then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.user = res.data.data;
                this.isLoading = false;
              }
            }
          });
        }
      },
      immediate: true,
    },
  },
  // mounted() {
  //   this.getProfile();
  // },
  // methods: {
  //   async getProfile() {
  //     if (!this.isLoading) {
  //       // chart.options.data[0].dataPoints = this.jja_data;
  //       // chart.render();
  //     }
  //   },
  // },
};
</script>
