<template>
  <Layout v-if="isSdmTeam()">
    <TopMenuMdp></TopMenuMdp>
  </Layout>
  <Layout v-else>
    <SideMenuMdp></SideMenuMdp>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main/Main";
import TopMenuMdp from "@/layouts/top-menu-mdp/Main";
import SideMenuMdp from "@/layouts/side-menu-mdp/Main";
import { isSdmTeam } from "@/utils/menus";

export default {
  name: "Main",
  components: {
    Layout,
    TopMenuMdp,
    SideMenuMdp,
  },
  mounted() {
    console.log(isSdmTeam());
  },
};
</script>

<style scoped></style>
