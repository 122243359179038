<template>
  <MainLayout>
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Sync PDDikti</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="btn btn-success ml-2" @click="GetDetailBiodataDosen">
            <BatteryChargingIcon class="h-4" /> Get PTK
          </button>
          <button
            v-if="countdosen > 0 && loading === false"
            class="btn btn-default ml-2"
            @click="SyncData"
          >
            <SaveIcon class="h-4" /> Sync Data
          </button>
        </div>
      </div>

      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div>
          <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
          <AlertFailed v-if="alertFailed" :message="messageAlert" />
        </div>
        <div
          v-if="loading"
          class="relative bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="loading" :use-state="useState" />
          </div>
        </div>

        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start"
        ></div>
        <div class="overflow-x-auto scrollbar-hidden"></div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <template #rightside>
      <SyncFeeder class="mt-8" />
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent } from "vue";
import MainLayout from "@/layouts/main/MainLayout";
import { wspddikti, wssister } from "@/services/wspddikti";
import SyncFeeder from "@/components/utility/SyncFeeder";
import { dosen } from "@/services/models";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import LoadingSpinner from "@/components/loading-spinner/Main";
export default defineComponent({
  components: {
    MainLayout,
    SyncFeeder,
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  data() {
    return {
      datadosen: null,
      countdosen: null,
      statuscek: [],
      loading: false,
      syncMsg: "",
      syncStatus: null,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      syncResult: [],
      useState: false,
    };
  },
  methods: {
    SyncData() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.syncStatus = true;
      this.loading = true;

      dosen.SDM.syncData("import_jabfung", { ...this.datadosen }).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status_code === 200) {
              this.alertSuccess = true;
              this.messageAlert = response.data?.message;
              this.syncStatus = response.data?.status;
              if (response?.data?.data) {
                this.syncResult = response?.data?.data;
              }
            } else {
              this.alertFailed = true;
              this.messageAlert = response.data?.message;
            }
          }
        }
      );

      this.loading = false;
    },
  },
});
</script>
