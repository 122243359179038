<template>
  <!-- BEGIN: Notifications -->
  <div class="intro-x dropdown mr-auto sm:mr-6">
    <div
      :class="
        light === 'light'
          ? 'dropdown-toggle notification notification--light notification--bullet cursor-pointer'
          : 'dropdown-toggle notification notification--bullet cursor-pointer'
      "
      role="button"
      aria-expanded="false"
    >
      <BellIcon class="notification__icon dark:text-gray-300" />
    </div>
    <div class="notification-content pt-2 dropdown-menu">
      <div
        class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
      >
        <div class="notification-content__title">Notifications</div>
      </div>
    </div>
  </div>
  <!-- END: Notifications -->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Notification",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped></style>
