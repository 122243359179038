<template>
  <div v-if="!loading" class="intro-y col-span-12 lg:col-span-12">
    <SavingStatus :is-saving="isSaving" :is-success="isSuccess"></SavingStatus>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl
        class="col-span-12 lg:col-span-12 border-2 border-dashed dark:border-dark-5 rounded-md py-6"
      >
        <DataList
          :white="true"
          :dd="nm_sdm"
          :dt="'Nama Dosen/Tendik'"
        ></DataList>
        <DataList :white="true" :dd="nip" :dt="'NIK'"></DataList>
        <hr class="my-5" />
        <DataList
          :white="true"
          :dd="diklat.nama"
          :dt="'Nama Pelatihan'"
        ></DataList>
        <!--        <DataList-->
        <!--          :white="true"-->
        <!--          :dd="diklat.kategori_kegiatan.nm_kat"-->
        <!--          :dt="'Kategori Kegiatan'"-->
        <!--        ></DataList>-->
        <!--        <DataList-->
        <!--          :white="true"-->
        <!--          :dd="diklat.jenis_diklat.nm_jns_diklat"-->
        <!--          :dt="'Jenis Diklat'"-->
        <!--        ></DataList>-->
        <DataList
          :white="true"
          :dd="diklat.penyelenggara"
          :dt="'Penyelenggara'"
        ></DataList>
        <DataList :white="true" :dd="diklat.peran" :dt="'Peran'"></DataList>
        <DataList
          :white="true"
          :dd="
            diklat.tingkat == 1
              ? 'Lokal'
              : diklat.tingkat == 2
              ? 'Regional'
              : diklat.tingkat == 3
              ? 'Nasional'
              : 'Internasional'
          "
          :dt="'Tingkat Diklat'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.jumlah_jam"
          :dt="'Jumlah Jam'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.no_sertifikat"
          :dt="'Nomor Sertifikat'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(diklat.tanggal_sertifikat)"
          :dt="'Tanggal Sertifikat'"
        ></DataList>
        <DataList :white="true" :dd="diklat.lokasi" :dt="'Lokasi'"></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(diklat.tanggal_mulai)"
          :dt="'Tanggal Mulai'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(diklat.tanggal_selesai)"
          :dt="'Tanggal Selesai'"
        ></DataList>
        <DataList :white="true" :dd="diklat.tahun" :dt="'Tahun'"></DataList>
        <DataList
          :white="true"
          :dd="diklat.sk_penugasan"
          :dt="'Nomor SK Penugasan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(diklat.tanggal_sk_penugasan)"
          :dt="'Tanggal SK Penugasan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.pengajar"
          :dt="'Nama Pengajar/Pengajar'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.keahlian_pengajar"
          :dt="'Keterangan Keahlian Pengajar/Pengajar'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.uraian"
          :dt="'Urain Hasil dan Manfaat Pelatihan / Pengembangan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.jenis_kegiatan"
          :dt="'Jenis Kegiatan'"
        ></DataList>
        <DataList
          :white="true"
          :dd="diklat.lingkup_kegiatan"
          :dt="'Lingkup Kegiatan'"
        ></DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import DataList from "../components/DataList";
import SavingStatus from "@/components/alerts/SavingStatus.vue";

export default {
  components: {
    DataList,
    SavingStatus,
  },
  props: {
    diklat: {
      type: Object,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      nm_sdm: null,
      nip: null,
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    diklat: {
      handler: function (data) {
        if (data?.sdm !== undefined) {
          this.loading = false;
          this.nm_sdm = data?.sdm
            ? data?.sdm?.nm_sdm
            : data?.sdm_pegawai?.NM_PEGAWAI;
          this.nip = data?.sdm ? data?.sdm?.nip : data?.sdm_pegawai?.KODE;
        } else {
          this.loading = this.isLoading;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
