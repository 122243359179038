<template>
  <div class="mt-5 flex space-x-5">
    <div v-if="Sizes">
      <div>
        <select v-model="sizeSelected" class="form-select bg-white">
          <option
            v-for="(item, index) in optionSize"
            :key="index"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex space-x-2 items-center">
      <div class="flex space-x-2 items-center">
        <div v-for="(item, index) in paginateDetail?.links" :key="index">
          <button
            v-if="item.label == 'pagination.previous'"
            class="transition duration-200 border rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 dark:text-slate-300"
            @click="prevPage"
          >
            <ChevronsLeftIcon />
          </button>
          <button
            v-else-if="item.label == 'pagination.next'"
            class="transition duration-200 border rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex items-center justify-center border-transparent text-slate-800 dark:text-slate-300"
            @click="nextPage"
          >
            <ChevronsRightIcon />
          </button>
          <div
            v-else
            class="transition duration-200 border py-2 rounded-md cursor-pointer hover:bg-primary focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none flex items-center justify-center border-transparent text-slate-800 dark:text-slate-300 px-1 sm:px-4 !box font-medium dark:bg-darkmode-400"
            :class="item?.active ? 'bg-white' : ''"
            @click="setPage(item.label)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paginateDetail: {
      type: Object,
      default: () => {},
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    Sizes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionSize: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
      sizeSelected: 5,
    };
  },
  watch: {
    sizeSelected() {
      this.$emit("changeSize", this.sizeSelected);
    },
  },
  methods: {
    nextPage() {
      this.$emit("changePage", this.currentPage + 1);
    },
    prevPage() {
      this.$emit("changePage", this.currentPage - 1);
    },
    setPage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>
