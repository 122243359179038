const Layout = () =>
  import(/* webpackChunkName: "group-dosen" */ "@/layouts/menu/Main");
const feederMain = () =>
  import(/* webpackChunkName: "group-dosen"*/ "@/views/sync/syncFeeder");

export const routes = {
  path: "/sync",
  name: "sync-route",
  component: Layout,
  children: [
    {
      name: "sync-feeder",
      path: "feeder",
      component: feederMain,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Sync FEEDER PDDIKTI",
      },
    },
  ],
};
