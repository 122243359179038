//import axios from "axios";
// import { HTTP as FEEDER } from "./feeder";
import { HTTP as FEEDER } from "./feeder";
import { HTTP as SISTER } from "./sister";
import store from "../store";
const wspddikti = {
  GetToken(username, password) {
    var data = JSON.stringify({
      act: "GetToken",
      username: username,
      password: password,
    });
    return FEEDER.post(`/wspddikti`, data)
      .then(function (response) {
        if (parseInt(response.data?.error_code) === 0) {
          store.dispatch(
            "feederpddikti/feedertoken",
            response.data?.data?.token
          );

          store.dispatch("feederpddikti/feederconnect", true);
          return true;
        } else {
          store.dispatch("feederpddikti/feederconnect", false);
          store.dispatch(
            "feederpddikti/feedererror",
            response.data?.error_desc
          );
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  },
  GetData(request) {
    let tokendata = { token: store.getters["feederpddikti/feedertoken"] };
    var data = JSON.stringify({ ...tokendata, ...request });
    return FEEDER.post(`/wspddikti`, data).catch((error) => {
      console.log(error);
    });
  },
  UpdateData(record, key) {
    let tokendata = { token: store.getters["feederpddikti/feedertoken"] };
    let keyparam = { key: key };
    let recordparam = { record: record };
    var data = JSON.stringify({ ...tokendata, ...recordparam, ...keyparam });
    return FEEDER.post(`/wspddikti`, data).catch((error) => {
      console.log(error);
    });
  },
  InsertData(record) {
    let tokendata = { token: store.getters["feederpddikti/feedertoken"] };
    let recordparam = { record: record };
    var data = JSON.stringify({ ...tokendata, ...recordparam });
    return FEEDER.post(`/wspddikti`, data).catch((error) => {
      console.log(error);
    });
  },
  DeleteData(key) {
    let tokendata = { token: store.getters["feederpddikti/feedertoken"] };
    let keyparam = { key: key };
    var data = JSON.stringify({ ...tokendata, ...keyparam });
    return FEEDER.post(`/wspddikti`, data).catch((error) => {
      console.log(error);
    });
  },
  CekFeeder(url) {
    return FEEDER.get(`/` + url).catch((error) => {
      console.log(error);
    });
  },
};

const wssister = {
  GetToken() {
    return SISTER.post(`/ws/login`)
      .then(function (response) {
        if (parseInt(response.data?.error_code) === 1) {
          store.dispatch("sisterpddikti/sisterconnect", false);
          store.dispatch("sisterpddikti/sistererror", response.data?.message);
          return false;
        } else {
          store.dispatch(
            "sisterpddikti/sistertoken",
            "Bearer " + response.data?.token
          );

          store.dispatch("sisterpddikti/sisterconnect", true);
          store.dispatch("sisterpddikti/sistererror", "Connected");
          return true;
        }
      })
      .catch(() => {
        store.dispatch("sisterpddikti/sisterconnect", false);
        store.dispatch("sisterpddikti/sistererror", "conntecting...");
        return false;
      });
  },
  GetData(ws, request) {
    request.token = store.getters["sisterpddikti/sistertoken"];
    var data = JSON.stringify({ ws, request });
    return SISTER.post(`/ws`, data).catch((error) => {
      console.log("sister_error" + error);
      store.dispatch("sisterpddikti/sistererror", error);
    });
  },
  GetDataBlob(ws, request) {
    request.token = store.getters["sisterpddikti/sistertoken"];
    var data = JSON.stringify({ ws, request });
    return SISTER.post(`/ws`, data, {
      responseType: "arraybuffer",
    }).catch((error) => {
      console.log("sister_error" + error);
      store.dispatch("sisterpddikti/sistererror", error);
    });
  },
};
// export { wspddikti };
export { wssister, wspddikti };
