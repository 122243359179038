<template>
  <MainLayout>
    <form action="" @submit.prevent="submit">
      <div class="intro-y mt-5 mr-4">
        <div>
          <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
          <div v-if="alertFailed">
            <AlertFailed
              v-for="(item, index) in messageAlert"
              :key="index"
              :message="item"
              :dismissable="true"
            />
          </div>
        </div>
        <div
          v-if="isLoading"
          class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
            <div class="intro-y box pb-6">
              <div
                class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Form Tambah Template Surat
                </h2>
              </div>
              <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Upload Dokumen</label
                  >
                  <div class="pb-4">
                    <div class="flex flex-row items-center">
                      <div v-if="!dokumen['nama_file']">
                        <div
                          class="btn btn-secondary relative cursor-pointer mr-3"
                        >
                          <LoadingIcon
                            v-if="onUploadProgress"
                            icon="oval"
                            class="w-4 h-4 mr-2"
                          />
                          <UploadIcon
                            v-else-if="!onUploadProgress"
                            class="w-4 h-4 mr-2"
                          />
                          Upload File
                          <input
                            v-bind="dokumen['file']"
                            type="file"
                            class="inputfile"
                            @change="onChangeFile($event)"
                          />
                        </div>
                        <label class="underline m-0 mr-1 pb-2 text-gray-500"
                          >No file selected</label
                        >
                      </div>
                      <div v-else>
                        <FileIcon class="w-4 h-4 mr-1" />
                        <label class="underline m-0 mr-1">{{
                          dokumen["nama_file"]
                        }}</label>
                        <div
                          class="text-blue-600 inline-block truncate cursor-pointer"
                          @click="deleteFile"
                        >
                          Batal
                        </div>
                      </div>
                    </div>
                    <div class="form-help">
                      Maksimal total ukuran file : 5 MB,
                      .xls/.xlsx/.docs/.pdf/.jpg/.bmp/.png
                    </div>
                  </div>
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Nama Template Dokumen
                    <span class="text-red-900">*</span></label
                  >
                  <input
                    v-model="dokumen['nama']"
                    class="form-control focus:ring-0"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline">
                  <label class="form-label font-medium w-52"
                    >Keterangan <span class="text-red-900">*</span></label
                  >
                  <input
                    v-model="dokumen['keterangan']"
                    class="form-control focus:ring-0"
                  />
                </div>
                <div class="col-span-12 mt-4 form-inline form-check">
                  <label class="form-label font-medium w-52"
                    >Batasi Hak Akses
                  </label>
                  <input
                    id="vertical-form-3"
                    v-model="dokumen['limited']"
                    class="form-check-input bg-white"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="vertical-form-3"
                    >Hanya Admin SDM</label
                  >
                </div>
              </div>

              <hr />

              <div
                class="flex justify-end flex-col md:flex-row gap-2 mt-6 mx-6"
              >
                <router-link
                  :to="{ name: 'template-surat' }"
                  class="btn py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
                  >Cancel</router-link
                ><button
                  type="submit"
                  class="btn py-3 btn-primary w-full md:w-52"
                >
                  <SaveIcon class="w-4 h-4 mr-2" /> Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <template #rightside></template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { sdm } from "@/services/models";
import moment from "moment";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  components: {
    MainLayout,
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      onUploadProgress: false,
      dokumen: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onChangeFile(event) {
      const fd = new FormData();
      fd.append("file", event.target.files[0]);
      this.onUploadProgress = true;

      sdm.TemplateSurat.postCustomPath("kirimFile", fd)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.dokumen.nama_file = res.data.fileName;
            } else {
              // this.alertFailed = true;
              // Object.keys(res.data.message).forEach((key) => {
              //   this.messageAlert.push(res.data.message[key][0]);
              // });
            }
          }
        })
        .finally(() => {
          if (this.onUploadProgress == true)
            return (this.onUploadProgress = false);
        });
    },
    deleteFile() {
      return (this.dokumen = {});
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = [];

      let formdata = {
        ...this.dokumen,
      };

      // console.log(formdata);
      sdm.TemplateSurat.insertData(formdata).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;

            this.$router.push({
              name: "template-surat",
              params: {
                messageAlert: this.messageAlert,
                alertSuccess: true,
              },
            });
          } else {
            this.alertFailed = true;
            Object.keys(res.data.message).forEach((key) => {
              this.messageAlert.push(res.data.message[key][0]);
            });
          }
        }

        this.isLoading = false;
        this.scrollToTop();
      });
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
