<template>
  <div v-if="isLoading" class="p-5">Loading data...</div>
  <div v-else class="intro-y col-span-12 lg:col-span-6">
    <div
      v-if="isLoading"
      class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
    >
      <div class="opacity-100">
        <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-x-8">
      <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
        <div class="grid grid-cols-12">
          <div class="col-span-12 lg:col-span-12">
            <table class="table table--sm mt-3">
              <thead>
                <tr>
                  <th
                    v-if="status_ajuan.used == 0"
                    class="bg-primary-1 text-white border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                    width="40%"
                  >
                    Data Kepegawaian Lama
                  </th>
                  <th
                    class="bg-primary-1 text-white border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                    width="40%"
                  >
                    Data Kepegawaian Yang Diajukan
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="status_ajuan.used == 0" class="bg-gray-100 border">
                    <table class="w-full">
                      <div class="w-36 lg:h-48 flex-none image-fit relative">
                        <img
                          v-if="pegawai?.foto"
                          alt="profile-pic"
                          :src="
                            profile_pic_url +
                            'storage/sdm/data_pribadi' +
                            '/' +
                            pegawai?.foto
                          "
                        />
                      </div>
                      <tr>
                        <td>Nama</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          {{ pegawai?.NM_PEGAWAI }}
                        </td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          {{ $h.jenisKelamin(pegawai?.JENIS_KEL) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Lahir</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ $h.tanggal(pegawai?.TGL_LAHIR) }}</td>
                      </tr>
                      <tr>
                        <td>Agama</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ $h.agama(pegawai?.AGAMA) }}</td>
                      </tr>
                      <tr>
                        <td>Status Perkawinan</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          {{ $h.statKawin(pegawai?.STATUS_PERKAWINAN) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Golongan Darah</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.gol_darah }}</td>
                      </tr>
                      <tr>
                        <td>Alamat</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.ALAMAT1 }}</td>
                      </tr>
                      <tr>
                        <td>Kelurahan</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.KEL }}</td>
                      </tr>
                      <tr>
                        <td>Kecamatan</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.KEC }}</td>
                      </tr>
                      <tr>
                        <td>Kode Pos</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.KDPOS }}</td>
                      </tr>
                      <tr>
                        <td>HP</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.HP }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.EMAIL }}</td>
                      </tr>
                      <tr>
                        <td>KTP</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.ktp }}</td>
                      </tr>
                      <tr>
                        <td>NPWP</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.npwp }}</td>
                      </tr>
                      <tr>
                        <td>BPJS TK</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.bpjs_tk }}</td>
                      </tr>
                      <tr>
                        <td>BPJS KS</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.bpjs_ks }}</td>
                      </tr>
                      <tr>
                        <td>No. Rekening</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>{{ pegawai?.NOREK }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="bg-gray-50 border">
                    <table class="w-full">
                      <tr>
                        <td colspan="3">
                          <div class="p-5">
                            <div
                              class="w-36 lg:h-48 flex-none image-fit relative"
                            >
                              <img
                                v-if="ajuan_pegawai.profile_pic"
                                alt="profile-pic"
                                :src="
                                  profile_pic_url +
                                  'storage/sdm/data_pribadi' +
                                  '/' +
                                  ajuan_pegawai.profile_pic
                                "
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.NM_PEGAWAI"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai.NM_PEGAWAI }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.JENIS_KEL"
                            class="font-bold text-red-500"
                            >{{
                              $h.jenisKelamin(ajuan_pegawai?.JENIS_KEL)
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Lahir</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.TGL_LAHIR"
                            class="font-bold text-red-500"
                            >{{ $h.tanggal(ajuan_pegawai?.TGL_LAHIR) }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Agama</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.TGL_LAHIR"
                            class="font-bold text-red-500"
                            >{{ $h.agama(ajuan_pegawai?.AGAMA) }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Status Perkawinan</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.TGL_LAHIR"
                            class="font-bold text-red-500"
                            >{{
                              $h.statKawin(ajuan_pegawai?.STATUS_PERKAWINAN)
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Golongan Darah</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.gol_darah"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.gol_darah }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Alamat</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.ALAMAT1"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.ALAMAT1 }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Kelurahan</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.KEL"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.KEL }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Kecamatan</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.KEC"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.KEC }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Kode Pos</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.KDPOS"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.KDPOS }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>HP</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.HP"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.HP }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.EMAIL"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.EMAIL }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>KTP</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.ktp"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.ktp }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>NPWP</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.npwp"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.npwp }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>BPJS TK</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.bpjs_tk"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.bpjs_tk }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>BPJS KS</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.bpjs_ks"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.bpjs_ks }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>No. Rekening</td>
                        <td style="padding-left: 0px; padding-right: 0px">:</td>
                        <td>
                          <span
                            v-if="ajuan_pegawai.NOREK"
                            class="font-bold text-red-500"
                            >{{ ajuan_pegawai?.NOREK }}</span
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sdm } from "@/services/models";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  components: {
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      pegawai: null,
      profile_pic_url: process.env.VUE_APP_API,
      ajuan_pegawai: {},
      ajuan_exist: true,
      status_ajuan: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {},
  mounted() {
    this.getDetailPegawai();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailPegawai() {
      this.isLoading = true;
      sdm.SDM.getCustomPath(`get_ajuan_perubahan_data_pribadi/${this.id}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data) {
              this.ajuan_pegawai = res.data.data;
              this.status_ajuan = res.data.status;
            } else {
              this.ajuan_exist = res.data.data;
            }

            sdm.SDM.getCustomPath(`data_pribadi/${res.data.status.KODE}`).then(
              (res) => {
                this.pegawai = res.data.data;
              }
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
