import { signIn } from "@/services/auth";
import store from "@/store";

const state = () => {
  return {
    isLocked: false,
    isLoading: false,
    errorMessage: "",
    maxDateTime: null,
  };
};

const mutations = {
  isLocked(state, payload) {
    state.isLocked = payload;
  },
  isLoading(state, payload) {
    state.isLoading = payload;
  },
  errorMessage(state, payload) {
    state.errorMessage = payload;
  },
  setMaxDateTime(state, payload) {
    state.maxDateTime = payload;
  },
};

const actions = {
  lockScreen({ commit }) {
    commit("isLocked", true);
  },
  async unlockScreen({ commit }, user) {
    commit("isLoading", true);
    commit("errorMessage", "");
    await signIn(user)
      .then((res) => {
        if (res.status === 200) {
          store.commit("auth/setToken", res.data);
          store.commit("auth/setAbilities", res.data);
          store.commit("auth/setFeatures", res.data);
          store.commit("auth/setAccessToken", res.data.access_token);

          commit("isLocked", false);
          commit("setMaxDateTime", null);
        }
      })
      .catch(() => {
        commit("errorMessage", "Password yang anda masukkan salah");
      })
      .finally(() => {
        commit("isLoading", false);
      });
  },
  setCurrentDateTime({ commit }) {
    // Now + 5 Minutes : 300000
    if (store.getters["auth/isAuthenticated"]) {
      const date = Date.now() + 300000;
      commit("setMaxDateTime", date);
    }
  },
  setCurrentDateTimeNull({ commit }) {
    commit("setMaxDateTime", null);
  },
  clear({ commit }) {
    commit("isLocked", false);
    commit("isLoading", false);
    commit("errorMessage", "");
    commit("setMaxDateTime", null);
  },
};

const getters = {
  isLocked: (state) => state.isLocked,
  errorMessage: (state) => state.errorMessage,
  isGTENow: (state) => {
    if (!state.maxDateTime) return false;
    return state.maxDateTime < Date.now();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
