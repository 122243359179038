<template>
  <div>
    <div v-if="showTitle" class="intro-x flex items-center mb-5">
      <h2 class="text-md font-medium truncate">Server Feeder PDDIKTI</h2>
    </div>
    <div class="flex z-10">
      <div
        class="flex-1 form-inline"
        :class="
          feederconnect
            ? 'border-2 rounded-md border-green-400'
            : 'border-2 rounded-md border-red-400'
        "
      >
        <TailSelect
          v-model="user"
          :options="{
            search: true,
            classNames: 'w-full',
            deselect: false,
          }"
          class="z-10"
        >
          <option
            v-for="(list, key) in userpddikti"
            :key="key"
            :value="key"
            :selected="list.nama === feederserver"
          >
            {{ list.kode + " " + list.nama.toUpperCase() }}
          </option>
        </TailSelect>
      </div>

      <div class="flex-initial">
        <button class="btn btn-success ml-2" @click="connect">
          <BatteryChargingIcon class="h-4" /> Connect
        </button>
      </div>
    </div>

    <AlertSuccess
      v-if="connected === true"
      class="mt-3"
      :alertstyle="'alert-success-soft'"
      :message="connMsg"
      :dismissable="true"
      @closeAndClear="clearAlert"
    ></AlertSuccess>
    <AlertFailed
      v-if="connected === false"
      class="mt-3"
      :alertstyle="'alert-failed-soft'"
      :message="feedererror"
      :dismissable="true"
    ></AlertFailed>
    <AlertSuccess
      v-if="connectedSister === true"
      class="mt-3"
      :alertstyle="'alert-success-soft'"
      :message="connMsgSister"
      :dismissable="true"
      @closeAndClear="clearAlertSister"
    ></AlertSuccess>
    <AlertFailed
      v-if="connectedSister === false"
      class="mt-3"
      :alertstyle="'alert-failed-soft'"
      :message="sistererror"
      :dismissable="true"
    ></AlertFailed>
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script>
import AlertSuccess from "../alerts/AlertSuccess";
import AlertFailed from "../alerts/AlertFailed";
import { wspddikti, wssister } from "../../services/wspddikti";
import store from "../../store";
export default {
  name: "SyncFeeder",
  components: { AlertFailed, AlertSuccess },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user: 0,
      userpddikti: [
        {
          nama: "Pilih Server",
          kode: "",
          username: "",
          password: "",
        },
        {
          nama: "univ",
          kode: "021037",
          username: "dorie.umdp021037",
          password: "PDDIKTI.umdp021037",
        },
      ],
      connected: null,
      connectedSister: null,
      connMsg: null,
      connMsgSister: null,
    };
  },

  computed: {
    feederconnect() {
      return this.$store.state.feederpddikti.feederconnect;
    },
    feederserver() {
      return this.$store.state.feederpddikti.feederserver;
    },
    feedertoken() {
      return this.$store.state.feederpddikti.feedertoken;
    },
    feedererror() {
      return this.$store.state.feederpddikti.feedererror;
    },
    sisterconnect() {
      return this.$store.state.sisterpddikti.sisterconnect;
    },
    sisterserver() {
      return this.$store.state.sisterpddikti.sisterserver;
    },
    sistertoken() {
      return this.$store.state.sisterpddikti.sistertoken;
    },
    sistererror() {
      return this.$store.state.sisterpddikti.sistererror;
    },
  },
  mounted() {
    this.userpddikti.forEach((list, key) => {
      if (list.nama === this.feederserver) this.user = key;
    });
  },
  methods: {
    connect() {
      if (this.user > 0) {
        const username = this.userpddikti[this.user].username;
        const password = this.userpddikti[this.user].password;

        store.dispatch(
          "feederpddikti/feederserver",
          this.userpddikti[this.user].nama
        );
        wspddikti.GetToken(username, password).then((result) => {
          if (result === true) {
            this.connected = true;
            this.connMsg =
              "Connected to Server " +
              this.feederserver.toUpperCase() +
              " PDDIKTI";
          } else {
            this.connected = false;
          }
        });
        wssister.GetToken().then((result) => {
          if (result === true) {
            this.connectedSister = true;
            this.connMsgSister = "Connected to Server SISTER";
          } else {
            this.connectedSister = false;
          }
        });
      }

      //wspddikti.Login();
    },
    clearAlert(m) {
      this.connMsg = m;
    },
    clearAlertSister(m) {
      this.connMsgSister = m;
    },
  },
};
</script>

<style scoped></style>
