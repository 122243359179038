<template>
  <div class="p-5">
    <div v-if="loading">Loading data...</div>
    <table v-else class="table w-100">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-1 text-white">
          <th class="whitespace-nowrap">#</th>
          <th class="whitespace-nowrap">Nama Pangkat Golongan</th>
          <th class="whitespace-nowrap">Nomor SK Inpassing</th>
          <th class="whitespace-nowrap">Tanggal SK</th>
          <th class="whitespace-nowrap">
            SK Inpassing Terhitung Mulai Tanggal
          </th>
          <th class="whitespace-nowrap">#</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in Inpassing" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item?.pangkat_golongan }}</td>
          <td>{{ item?.sk }}</td>
          <td>{{ $h.tanggal(item?.tanggal_sk) }}</td>
          <td>{{ $h.tanggal(item?.tanggal_mulai) }}</td>
          <td>
            <button class="btn btn-sm btn-success" @click="openPanel(item?.id)">
              <eye-icon class="w-4 h-4 mr-2"></eye-icon>
              Detail
            </button>
          </td>
        </tr>
        <tr v-if="Inpassing.length == 0">
          <td colspan="6" class="text-center">Belum ada riwayat</td>
        </tr>
      </tbody>
    </table>

    <SlideOver :open="panel" @close="closePanel">
      <template #title>Inpassing</template>
      <div class="p-3 border-t border-gray-200 dark:border-dark-5"></div>
      <div class="box p-5">
        <div v-for="(item, index) in detailSisterData" :key="index">
          <!--        <DataList :white="true" :dd="item" :dt="index"> {{ item }} </DataList>-->
          <div
            v-if="index !== 'dokumen'"
            class="flex items-center border-b border-gray-200 dark:border-dark-5 pb-2"
          >
            <div>
              <div class="mt-1 text-gray-600">{{ index }}</div>
              <div class="mt-1">{{ item }}</div>
            </div>
          </div>
          <div v-if="index === 'dokumen'">
            <div v-for="(items, indexx) in item" :key="indexx">
              <a
                class="flex items-center cursor-pointer hover:text-blue-400 mt-4"
                :class="
                  downloading[items.id] ? 'text-gray-600' : 'text-blue-600'
                "
                href="javascript:"
                @click="downloadFile(items.id, items.nama_file)"
              >
                <download-icon class="w-4 h-4 mr-2"></download-icon>
                {{
                  downloading[items.id]
                    ? downloading[items.id]
                    : items.nama_file
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </SlideOver>
  </div>
</template>

<script>
import { wssister } from "@/services/wspddikti";
import SlideOver from "@/layouts/main/SlideOver";
// import DataList from "../../components/DataList";

export default {
  components: {
    SlideOver,
    // DataList,
  },
  props: {
    sister: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      Inpassing: [],
      detailSisterData: [],
      loading: true,
      panel: false,
      downloading: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  mounted() {
    this.getSisterData();
  },
  methods: {
    async getSisterData() {
      wssister
        .GetData("inpassing", {
          kd_dosen: this.id,
        })
        .then((res) => {
          if (res.data) {
            this.loading = false;
            this.Inpassing = res.data;
          }
        });
    },
    closePanel(close) {
      this.panel = close;
    },
    openPanel(id) {
      this.detailSisterData = [];

      wssister.GetData("inpassing/" + id, {}).then((res) => {
        if (res.data) {
          // this.loading = true;
          this.detailSisterData = res.data;
        }
      });

      this.panel = true;
    },
    downloadFile(id, fileName) {
      this.downloading[id] = "Downloading...";
      wssister
        .GetDataBlob("dokumen/" + id + "/download", {
          file: true,
        })
        .then((res) => {
          if (res.data) {
            let blobFile = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            var fileURL = URL.createObjectURL(blobFile);
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        })
        .finally(() => {
          this.downloading[id] = "";
        });
    },
  },
};
</script>
