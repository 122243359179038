import { mainmenus } from "@/utils/mainmenus";
import { staffMenus } from "@/utils/staff-menus";
// import { dosenMenus } from "@/utils/menus/dosen-menus";
// import { kaprodiMenus } from "@/utils/menus/kaprodi-menus";
// import { dekanMenus } from "@/utils/menus/dekan-menus";
// import rektorMenus from "@/utils/menus/rektor-menus";

const state = () => {
  return {
    menu: mainmenus,
  };
};

// getters
const getters = {
  menu: (state, getters) => {
    // let canall =
    //   getters.usrAbility?.length > 0
    //     ? getters.usrAbility.find((a) => a === "*")
    //     : undefined;
    //
    // console.log("canall", canall);
    // if (canall === undefined && getters.usrAbility?.length > 0) {
    if (getters.adminsdm.includes(getters.cteam)) {
      //a?.access == "*" || a.access == "pesertadidik";
      let menulist = state.menu.filter((a) => {
        return a?.ability == "*";
      });
      let abl = getters.usrAbility.map((a) => a.split(":", 1)[0]);
      let finalabl = abl.filter(function (item, pos) {
        return abl.indexOf(item) == pos;
      });
      finalabl.forEach((b) => {
        let m = state.menu.find((a) => {
          return a?.ability?.includes(b);
        });
        if (m !== undefined) menulist.push(m);
      });

      return menulist.filter(function (item, pos) {
        return menulist.indexOf(item) == pos;
      });
    } else if (getters.staffsdm.includes(getters.cteam)) {
      return staffMenus;
    } else {
      return state.menu;
    }
  },
  usrAbility: (state, getters, rootState, rootGetters) => {
    return rootGetters["auth/abilities"];
  },
  cteam: (state, getters, rootState, rootGetters) => {
    return rootGetters["userdata/currentTeamId"];
  },
  adminsdm: (state, getters, rootState, rootGetters) => {
    return rootGetters["auth/admteam"];
  },
  staffsdm: (state, getters, rootState, rootGetters) => {
    return rootGetters["auth/staffteam"];
  },
  // isDosen: (state, getters, rootState, rootGetters) => {
  //   let cid = rootGetters["userdata/currentTeamId"];
  //   let ctn = rootGetters["userdata/currentTeamName"];
  //   return cid == 3 || ctn == "Dosen";
  // },
  // isKaprodi: (state, getters, rootState, rootGetters) => {
  //   let cid = rootGetters["userdata/currentTeamId"];
  //   let ctn = rootGetters["userdata/currentTeamName"];
  //   return cid == 8 || ctn == "Kaprodi";
  // },
  // isDekan: (state, getters, rootState, rootGetters) => {
  //   let cid = rootGetters["userdata/currentTeamId"];
  //   let ctn = rootGetters["userdata/currentTeamName"];
  //   return cid == 7 || ctn == "Dekan";
  // },
  // isRektor: (state, getters, rootState, rootGetters) => {
  //   let cid = rootGetters["userdata/currentTeamId"];
  //   let ctn = rootGetters["userdata/currentTeamName"];
  //   return cid == 6 || ctn == "Rektor";
  // },
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
