<template>
  <MainLayout>
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-10">
        <h2 class="text-lg font-medium mr-auto">Kegiatan Rekognisi</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button class="ml-3 mr-1" @click="getItems">
            <RefreshCcwIcon class="w-4 mr-2" /> Reload data
          </button>
        </div>
      </div>

      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form
            id="tabulator-html-filter-form"
            class="xl:flex sm:mr-auto"
            @submit.prevent="onFilter"
          >
            <div class="sm:flex items-center sm:mr-4">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field</label
              >
              <select
                id="tabulator-html-filter-field"
                v-model="filter.field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="nm_rekognisi">Nama Kegiatan</option>
                <option value="tahun">Tahun</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Value</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="filter.value"
                type="text"
                class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-16"
                @click="onFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <!-- BEGIN: Modal Content -->
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="deleteRow"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
    <template #rightside>
      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-4 md:col-span-12 intro-y box p-5 mt-5"></div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import feather from "feather-icons";
import { useStore } from "vuex";
import router from "@/router";
import { tblTabulator } from "@/utils/tabulator";
import { sdm } from "@/services/models";
import MainLayout from "@/layouts/main/MainLayout.vue";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import cash from "cash-dom/dist/cash";
import { helper } from "@/utils/helper";

export default defineComponent({
  components: {
    MainLayout,
    AlertSuccess,
  },
  setup() {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "nm_rekognisi",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    // const uid = store.state.userdata.userData.uid;

    const editRow = (e, cell) => {
      idData.value = cell.getData().id_rekognisi;
      router.push({ name: "edit-rekognisi", params: { id: idData.value } });
    };
    const deleteRow = (e, cell) => {
      //e - the click event object
      cash("#delete-modal").modal("show");
      deletingRow.value = cell.getRow();
      idData.value = cell.getData().id_rekognisi;
    };
    const initTabulator = () => {
      let columns = [
        {
          title: "Nama Kegiatan",
          responsive: true,
          field: "nm_rekognisi",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "Nama Dosen/Tendik",
          responsive: true,
          field: "sdm.nm_sdm",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          width: 90,
          title: "NIK",
          responsive: true,
          field: "KODE",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "Unit Kerja",
          responsive: true,
          field: "sdm_pegawai.unit_kerja",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        },
        {
          title: "Tanggal Kegiatan",
          responsive: true,
          field: "tanggal_mulai",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          formatter(cell) {
            return (
              helper.tanggal(cell.getData().tanggal_mulai) +
              ` - ` +
              helper.tanggal(cell.getData().tanggal_selesai)
            );
          },
        },
        {
          title: "Status Ajuan",
          responsive: true,
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          width: 140,
          formatter(cell) {
            let a = "";
            if (cell.getData().status_ajuan == 0) {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-6 text-white cursor-pointer font-medium">Diajukan</div>`
              );
            } else if (cell.getData().status_ajuan == 1) {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-4 text-white cursor-pointer font-medium">Disetujui</div>`
              );
            } else {
              a = cash(
                `<div class="py-1 px-2 rounded text-xs bg-primary-3 text-white cursor-pointer font-medium">Ditolak</div>`
              );
            }

            return a[0];
          },
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 65,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-success w-18 items-center" href="javascript:;">
                  <i data-feather="archive" class="w-4 h-4 mr-1"></i> Open
                </a>
              </div>`);
            return a[0];
          },
          cellClick: editRow,
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 40,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center text-theme-6" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
              </div>`);
            return a[0];
          },
          cellClick: deleteRow,
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        sdm.Rekognisi.endpoint,
        columns
      );
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "nama";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });

    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      deletingRow,
      idData,
    };
  },
  data() {
    return {
      uid: this.$store.state.userdata.userData.uid,
      deleting: false,
      editing: false,
      dataTable: null,
      dataInfo: [],
      alertSuccess: this.$route.params.alertSuccess,
      messageAlert: this.$route.params.messageAlert,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
  },
  watch: {},
  mounted() {
    this.getInfoRekognisi();
  },
  methods: {
    getItems() {
      this.deleting = false;
      this.editing = false;
      this.tabulator.setData(
        sdm.Rekognisi.endpoint + "/all",
        {},
        tblTabulator.ajaxConfig()
      );
    },
    deleteRow() {
      sdm.Rekognisi.deleteData(this.idData).then((res) => {
        if (res.status === 200) {
          this.getItems();
          cash("#delete-modal").modal("hide");
        }
      });
    },
    getInfoRekognisi() {
      sdm.Rekognisi.getCustomPath("getInfoRekognisi").then((res) => {
        this.dataInfo = res.data.data;
      });
    },
  },
});
</script>
