<template>
  <form action="" @submit.prevent="submit">
    <div>
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <div v-if="alertFailed">
        <AlertFailed
          v-for="(item, index) in messageAlert"
          :key="index"
          :message="item"
          :dismissable="true"
        />
      </div>
    </div>
    <div v-if="isLoading" class="p-5">Loading data...</div>
    <div v-else class="intro-y col-span-12 lg:col-span-6">
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-x-8">
        <div
          class="col-span-12 mb-4 py-6 bg-gray-50 border-2 border-dashed dark:border-dark-5 rounded-md"
        >
          <div class="col-span-12 mt-2 form-inline">
            <label class="form-label font-medium w-52">Nama Dosen/Tendik</label>
            {{
              dataRekognisi.sdm
                ? dataRekognisi.sdm?.nm_sdm
                : dataRekognisi.sdm_pegawai?.NM_PEGAWAI
            }}
          </div>
          <div class="col-span-12 mt-2 form-inline">
            <label class="form-label font-medium w-52">NIK</label>
            {{
              dataRekognisi.sdm
                ? dataRekognisi.sdm?.nip
                : dataRekognisi.sdm_pegawai?.KODE
            }}
          </div>
        </div>
        <div class="col-span-12 mt-4"><hr /></div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Nama Kegiaatan Rekognisi <span class="text-red-900">*</span></label
          >
          <input
            v-model="dataRekognisi['nm_rekognisi']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Penyelenggara <span class="text-red-900">*</span></label
          >
          <input
            v-model="dataRekognisi['penyelenggara']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Jenis Rekognisi <span class="text-red-900">*</span></label
          >
          <TailSelect
            v-model="dataRekognisi['id_jns_rekognisi']"
            :options="{
              search: false,
              classNames: 'form-control',
              deselect: false,
            }"
            @change="getTingkatRekognisi($event)"
          >
            <option
              v-for="list in ref_jns_rekognisi"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tingkat Rekognisi <span class="text-red-900">*</span></label
          >
          <TailSelect
            v-model="dataRekognisi['id_tingkat_rekognisi']"
            :options="{
              classNames: 'form-control',
              deselect: false,
            }"
          >
            <option
              v-for="list in ref_tingkat_rekognisi"
              :key="list.value"
              :value="list.value"
              :selected="list.selected"
            >
              {{ list.text }}
            </option>
          </TailSelect>
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tahun <span class="text-red-900">*</span></label
          >
          <input
            v-model="dataRekognisi['tahun']"
            class="form-control focus:ring-0"
            type="number"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">Tempat</label>
          <input
            v-model="dataRekognisi['lokasi']"
            class="form-control focus:ring-0"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal Mulai <span class="text-red-900">*</span></label
          >
          <input
            v-model="dataRekognisi['tanggal_mulai']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52"
            >Tanggal Selesai <span class="text-red-900">*</span></label
          >
          <input
            v-model="dataRekognisi['tanggal_selesai']"
            type="date"
            class="form-control focus:ring-0 mt-2"
            autocomplete="off"
          />
        </div>
        <div class="col-span-12 mt-4 form-inline">
          <label class="form-label font-medium w-52">Nomor SK Penugasan</label>
          <input
            v-model="dataRekognisi['sk_penugasan']"
            class="form-control focus:ring-0"
          />
        </div>
      </div>

      <hr class="mt-6" />

      <div class="flex justify-end flex-col md:flex-row gap-2 mt-6">
        <button type="submit" class="btn py-3 btn-primary w-full md:w-52">
          <SaveIcon class="w-4 h-4 mr-2" /> Simpan Prubahan
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, sdm } from "@/services/models";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import LoadingSpinner from "@/components/loading-spinner/Main";
import moment from "moment";

export default {
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      messageAlert: [],
      isLoading: false,
      useState: false,
      id: this.$route.params.id,
      dataRekognisi: [],
      onUploadProgress: [],
      ref_jns_rekognisi: [],
      ref_tingkat_rekognisi: [],
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {},
  mounted() {
    this.getJnsRekognisi();
    this.getDetailRekognisi();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getJnsRekognisi() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Jenis Rekognisi",
        selected: true,
      };
      this.ref_jns_rekognisi.push(defaultOpt);
      ref.jenisrekognisi.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_jns_rekognisi.push({
              id: element.id_jns_rekognisi,
              value: element.id_jns_rekognisi,
              text: element.nm_jns_rekognisi,
              selected: false,
            });
          });
        }
      });
    },
    getTingkatRekognisi(event) {
      const tingkat_a = ["1", "2", "3", "4", "99"];
      const tingkat_b = ["1", "2", "3", "4", "99"];
      const tingkat_c = ["6", "7", "99"];
      const tingkat_d = ["5", "6", "7", "99"];
      const tingkat_e = ["5", "6", "7", "99"];

      this.ref_tingkat_rekognisi = [];

      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Tingkat Rekognisi",
        selected: true,
      };
      this.ref_tingkat_rekognisi.push(defaultOpt);
      ref.tingkatrekognisi.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            if (event.target.value == 1100) {
              if (tingkat_a.includes(element.id_tinggkat_rekognisi)) {
                this.ref_tingkat_rekognisi.push({
                  id: element.id_tinggkat_rekognisi,
                  value: element.id_tinggkat_rekognisi,
                  text: element.nm_tinggkat_rekognisi,
                  selected: false,
                });
              }
            }
            if (event.target.value == 1200) {
              if (tingkat_b.includes(element.id_tinggkat_rekognisi)) {
                this.ref_tingkat_rekognisi.push({
                  id: element.id_tinggkat_rekognisi,
                  value: element.id_tinggkat_rekognisi,
                  text: element.nm_tinggkat_rekognisi,
                  selected: false,
                });
              }
            }
            if (event.target.value == 1300) {
              if (tingkat_c.includes(element.id_tinggkat_rekognisi)) {
                this.ref_tingkat_rekognisi.push({
                  id: element.id_tinggkat_rekognisi,
                  value: element.id_tinggkat_rekognisi,
                  text: element.nm_tinggkat_rekognisi,
                  selected: false,
                });
              }
            }
            if (event.target.value == 1400) {
              if (tingkat_d.includes(element.id_tinggkat_rekognisi)) {
                this.ref_tingkat_rekognisi.push({
                  id: element.id_tinggkat_rekognisi,
                  value: element.id_tinggkat_rekognisi,
                  text: element.nm_tinggkat_rekognisi,
                  selected: false,
                });
              }
            }
            if (event.target.value == 1500) {
              if (tingkat_e.includes(element.id_tinggkat_rekognisi)) {
                this.ref_tingkat_rekognisi.push({
                  id: element.id_tinggkat_rekognisi,
                  value: element.id_tinggkat_rekognisi,
                  text: element.nm_tinggkat_rekognisi,
                  selected: false,
                });
              }
            }
          });
        }
      });
    },
    async getDetailRekognisi() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.Rekognisi.getCustomPath(`getDetailRekognisi/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.dataRekognisi = res.data.data;

                this.getTingkatRekognisi(
                  this.dataRekognisi["id_jns_rekognisi"]
                );

                this.$emit("detailDataRekognisi", this.dataRekognisi);
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submit() {
      this.isLoading = true;
      this.messageAlert = [];

      // console.log(fd);
      let formdata = {
        ...this.dataRekognisi,
      };

      if (this.id?.length > 0) {
        sdm.Rekognisi.postCustomPath(
          "update_rekognisi/" + this.id,
          formdata
        ).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.alertSuccess = true;
              this.messageAlert = res.data.message;

              this.$store.dispatch("main/isSuccess", true);
              this.$store.dispatch("main/responseMessage", res.data.message);
            } else {
              Object.keys(res.data.message).forEach((key) => {
                this.messageAlert.push(res.data.message[key][0]);
              });
            }
          }

          this.isLoading = false;
          this.scrollToTop();
        });
      }
    },
  },
};
</script>
