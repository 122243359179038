import Layout from "@/layouts/menu/Main";
import Dosen from "@/views/dosen/listDosen";
import Tendik from "@/views/tendik/listTendik";
import DataPribadi from "@/views/data-pribadi/sdm/listDatapribadi";
import Inpassing from "@/views/inpassing/sdm/listInpassing";
import Jabfung from "@/views/jabfung/sdm/listJabfung";
import Diklat from "@/views/diklat/sdm/listDiklat.vue";
import Rekognisi from "@/views/rekognisi/sdm/listRekognisi.vue";
import TemplateSurat from "@/views/arsip/template-surat/listTemplateSurat";
import MakeAData from "@/views/makeadata/indexMmakeadata";

export const routes = {
  path: "/sdm",
  name: "sdm-route",
  component: Layout,
  children: [
    {
      name: "dosen",
      path: "dosen",
      component: Dosen,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Dosen",
      },
    },
    {
      name: "tendik",
      path: "tendik",
      component: Tendik,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Tenaga Pendidik",
      },
    },
    {
      name: "ajuan-data-pribadi",
      path: "ajuan-data-pribadi",
      component: DataPribadi,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Data Pribadi",
      },
    },
    {
      name: "data-inpassing",
      path: "data-inpassing",
      component: Inpassing,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Inpassing",
      },
    },
    {
      name: "data-jja",
      path: "data-jja",
      component: Jabfung,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Jabfung (JJA)",
      },
    },
    {
      name: "diklat",
      path: "diklat",
      component: Diklat,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Pelatihan & Pengembangan",
      },
    },
    {
      name: "rekognisi",
      path: "rekognisi",
      component: Rekognisi,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Kegiatan Rekognisi",
      },
    },
    {
      name: "template-surat",
      path: "template-surat",
      component: TemplateSurat,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Template Surat",
      },
    },
    {
      name: "makeadata",
      path: "makeadata",
      component: MakeAData,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["*"],
        name: "Make A Data",
      },
    },
  ],
};
