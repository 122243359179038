import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import store from "@/store";

require("dayjs/locale/id");

dayjs.extend(duration);

const helpers = {
  cutText(text, length) {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date, format) {
    return dayjs(date).format(format);
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time) {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(time) {
    const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
      minutes += 1;
    }

    return {
      days: days.toString().length < 2 ? "0" + days : days,
      hours: hours.toString().length < 2 ? "0" + hours : hours,
      minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
      seconds: seconds.toString().length < 2 ? "0" + seconds : seconds,
    };
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from, to, length) {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
  },
  jenisKelamin(jk) {
    jk ? (jk = jk.toString()) : "";
    return jk?.toLowerCase() === "p" || jk === "P"
      ? "Perempuan"
      : jk?.toLowerCase() === "l" || jk === "L"
      ? "Laki-laki"
      : "Tidak Diketahui";
  },
  statKawin(stat_kawin) {
    return parseFloat(stat_kawin) == "0" || stat_kawin == "B"
      ? "Belum Kawin"
      : parseFloat(stat_kawin) == "1" || stat_kawin == "N"
      ? "Kawin"
      : "Tidak Diketahui";
  },
  agama(agm) {
    var str;
    switch (agm) {
      case "1":
        str = "Islam";
        break;
      case "2":
        str = "Kristen";
        break;
      case "3":
        str = "Katolik";
        break;
      case "4":
        str = "Hindu";
        break;
      case "5":
        str = "Budha";
        break;
      case "6":
        str = "Khonghucu";
        break;
      case "98":
        str = "Tidak diisi";
        break;
      case "99":
        str = "Lainnya";
        break;
      default:
        str = "-";
        break;
    }

    return str;
  },
  statTendik(stat_tendik) {
    return stat_tendik == "1"
      ? "Dosen Tetap"
      : stat_tendik == "2"
      ? "Dosen LB"
      : stat_tendik == "3"
      ? "Tenaga Pendidik (Karyawan)"
      : "-";
  },
  fakultas(fakultas) {
    return fakultas == "fikr"
      ? "Fakultas Ilmu Komputer dan Rekayasa"
      : fakultas == "feb"
      ? "Fakultas Ekonomi dan Bisnis"
      : "-";
  },
  tanggal(tgl, format = "DD MMMM YYYY") {
    if (
      tgl !== "0000-00-00" ||
      tgl != "undefined" ||
      tgl !== null ||
      tgl !== ""
    )
      return dayjs(tgl, "YYYY-MM-DD", "id").format(format);
    return "-";
  },
  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age + " Years old";
  },
  closeAndClear() {
    store.commit("main/clearResponse");
  },
};

const install = (app) => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
