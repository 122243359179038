<template>
  <div class="flex flex-col lg:flex-row px-4 py-4">
    <div class="flex-1">
      <div class="relative mt-4 mx-4">
        <!--        <label class="form-label font-semibold">Cari Dosen</label>-->
        <input
          v-model="query"
          class="rounded-t-md rounded-b-none form-control"
          :class="
            listsdm.length > 0 || isLoading
              ? 'rounded-b-none focus:ring-0'
              : 'rounded-b-md focus:ring-2 focus:ring-primary-1'
          "
          placeholder="Cari SDM berdasarkan: Nama, NIK, NIDN atau NITK"
        />
        <search-icon
          class="absolute inset-y-0 right-0 w-5 h-5 my-auto mr-3 text-slate-600 dark:text-slate-500"
        ></search-icon>
        <div
          v-if="listsdm.length > 0 && !isLoading"
          class="overflow-hidden absolute z-100 bg-gray-200 dark:bg-dark-2 w-full shadow-lg border border-t-0 z-50"
        >
          <div
            class="p-3 text-center cursor-pointer hover:bg-white dark:hover:bg-dark-3 font-semibold"
            @click="close"
          >
            Close
          </div>
          <div
            v-for="(item, index) in listsdm"
            :key="index"
            class="p-3 flex justify-between items-center"
            :class="
              item?.Aktif != 'Y'
                ? 'bg-pink-500 text-white font-semibold hover:bg-pink-400'
                : 'hover:bg-white dark:hover:bg-dark-3'
            "
          >
            <div class="flex items-center space-x-3">
              <button
                class="btn btn-sm bg-white text-dark-1 dark:bg-dark-3 dark:text-white"
                @click="copyNik(item?.KODE)"
              >
                Copy
              </button>
              <div
                class="cursor-pointer col-span-5 font-medium"
                @click="detailSDM(item?.KODE, item?.jns_sdm)"
              >
                {{ item?.KODE }} | {{ item?.NM?.toUpperCase() }}
              </div>
            </div>
            <div class="flex space-x-3">
              <p>
                {{ item?.Aktif == "Y" ? "Aktif" : "Non Aktif" }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="isLoading"
          class="overflow-hidden absolute z-100 bg-gray-200 dark:bg-dark-3 w-full shadow-lg border border-t-0"
        >
          <div class="p-3 text-center cursor-pointer font-semibold">
            Sedang Mencari Data...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sdm } from "@/services/models";
import { debounce } from "lodash";
import router from "@/router";

export default {
  name: "LayoutCari",
  components: {},
  data() {
    return {
      query: null,
      listsdm: [],
      isLoading: false,
    };
  },
  watch: {
    query: {
      handler: debounce(function (val) {
        if (val.length < 3) {
          this.listsdm = [];
          return;
        }
        this.searchDosen(val);
      }, 500),
    },
  },
  methods: {
    searchDosen(value) {
      this.isLoading = true;
      this.listsdm = [];
      sdm.SDM.searchData({
        params: {
          q: value,
        },
      })
        .then((res) => {
          this.listsdm = res.data;
          // this.sortListDosen();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    detailSDM(kode, jns_sdm) {
      if (jns_sdm === "DOSEN") {
        router.push({
          name: "view-dosen",
          params: { id: kode },
        });
      } else if (jns_sdm === "TENDIK") {
        router.push({
          name: "view-tendik",
          params: { id: kode },
        });
      }
    },
    close() {
      this.listsdm = [];
    },
    // sortListDosen() {
    //   this.listsdm.sort((a, b) => {
    //     return a?.id_jns_keluar - b?.id_jns_keluar;
    //   });
    // },
    copyNik(kode) {
      navigator.clipboard.writeText(kode);
    },
  },
};
</script>

<style scoped></style>
