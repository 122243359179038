<template>
  <form action="" @submit.prevent="submit">
    <div class="col-span-12 lg:col-span-12 mt-6 grid grid-cols-12 mb-6">
      <div class="col-span-12 lg:col-span-12">
        <h2
          class="font-medium text-base mx-6 mb-3 pb-5 border-b border-gray-200 dark:border-dark-5"
        >
          <file-icon class="w-5 h-5 mr-1"></file-icon> Dokumen Lampiran Kegiatan
          Rekognisi
        </h2>
      </div>

      <div
        v-for="(item, index) in dokumen"
        :key="index"
        class="col-span-12 lg:col-span-12"
      >
        <div class="flex items-center mt-5 px-5">
          <div class="file">
            <div href="" class="w-12 file__icon file__icon--file">
              <div class="file__icon__file-name text-xs">
                {{ item.jenis_file ? item.jenis_file : "File" }}
              </div>
            </div>
          </div>
          <div class="ml-4">
            <div class="font-medium">
              ({{ item.jenis_dokumen }}) {{ item.nama }}
            </div>
            <div class="text-gray-600 text-xs mt-0.5">
              Keterangan: {{ item.keterangan }}
            </div>
            <div class="text-gray-400 text-xs mt-0.5">
              Updated:
              {{ $h.tanggal(item.tanggal_upload, "DD MMMM YYYY hh:mm:ss") }}
            </div>
          </div>
          <div
            class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
          >
            <a
              v-if="item.nama_file"
              class="btn btn-sm btn-primary w-56"
              target="_blank"
              :href="
                download_url.slice(0, download_url.length - 4) +
                'storage/sdm/diklat' +
                '/' +
                item.nama_file
              "
              download
            >
              <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download File
            </a>
            <div class="leading-relaxed text-gray-600 text-xs mt-2">
              {{ item.nama_file ? item.nama_file : "Not found!" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { sdm } from "@/services/models";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      isSaving: false,
      useState: false,
      id: this.$route.params.id,
      download_url: process.env.VUE_APP_API,
      dokumen: [],
    };
  },
  mounted() {
    this.getDetailRekognisi();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailRekognisi() {
      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.Rekognisi.getCustomPath(`getDetailRekognisi/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.dokumen = res.data.data?.sdm_dokumen;
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
