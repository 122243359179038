<template>
  <form action="" @submit.prevent="submit">
    <div class="intro-y">
      <div>
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div v-if="alertFailed">
          <AlertFailed
            v-for="(item, index) in messageAlert"
            :key="index"
            :message="item"
            :dismissable="true"
          />
        </div>
      </div>
      <!--      <div-->
      <!--        v-if="isLoading"-->
      <!--        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"-->
      <!--      >-->
      <!--        <div class="opacity-100">-->
      <!--          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
          <div class="intro-y box">
            <div class="grid grid-cols-12 gap-x-8 p-10 py-20 pb-5">
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">CV</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['cv']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['cv']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload1"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'cv')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    {{ onUploadProgress["cv"] ? "Uploading file..." : "" }}
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['cv']"
                    class="btn btn-sm btn-primary w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['cv']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download CV
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download CV
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["cv"]
                        ? dataRekrutmenfile["cv"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52"
                    >Surat Lamaran</label
                  >
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['surat_lamaran']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['surat_lamaran']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'surat_lamaran')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["surat_lamaran"]
                          ? "Uploading file..."
                          : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['surat_lamaran']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['surat_lamaran']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                    Lamaran
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                    Lamaran
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["surat_lamaran"]
                        ? dataRekrutmenfile["surat_lamaran"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">KTP</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ktp']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ktp']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ktp')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ktp"] ? "Uploading file..." : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ktp']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ktp']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download KTP
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download KTP
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ktp"]
                        ? dataRekrutmenfile["ktp"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">Ijazah SMA</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ijazah_sma']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ijazah_sma']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ijazah_sma')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ijazah_sma"]
                          ? "Uploading file..."
                          : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ijazah_sma']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ijazah_sma']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    D3
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    D3
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ijazah_sma"]
                        ? dataRekrutmenfile["ijazah_sma"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">Ijazah D3</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ijazah_d3']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ijazah_d3']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ijazah_d3')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ijazah_d3"] ? "Uploading file..." : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ijazah_d3']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ijazah_d3']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    D3
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    D3
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ijazah_d3"]
                        ? dataRekrutmenfile["ijazah_d3"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">Ijazah D4</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ijazah_d4']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ijazah_d4']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ijazah_d4')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ijazah_d4"] ? "Uploading file..." : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ijazah_d4']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ijazah_d4']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    D4
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    D4
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ijazah_d4"]
                        ? dataRekrutmenfile["ijazah_d4"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">Ijazah S1</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ijazah_s1']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ijazah_s1']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ijazah_s1')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ijazah_s1"] ? "Uploading file..." : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ijazah_s1']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ijazah_s1']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    S1
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    S1
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ijazah_s1"]
                        ? dataRekrutmenfile["ijazah_s1"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">Ijazah S2</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ijazah_s2']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ijazah_s2']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ijazah_s2')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ijazah_s2"] ? "Uploading file..." : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ijazah_s2']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ijazah_s2']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    S2
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    S2
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ijazah_s2"]
                        ? dataRekrutmenfile["ijazah_s2"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-4"
              >
                <div class="form-label mt-1 sm:!mr-10">
                  <label class="form-label font-medium w-52">Ijazah S3</label>
                </div>
                <div class="flex flex-col items-start">
                  <div
                    class="btn btn-outline-secondary relative cursor-pointer mr-3"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['ijazah_s3']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <FileIcon
                      v-else-if="!onUploadProgress['ijazah_s3']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload File

                    <input
                      id="fileUpload2"
                      type="file"
                      class="inputfile"
                      @change="onChangeFileSoftskill($event, 'ijazah_s3')"
                    />
                  </div>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1">
                    file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />
                    <span class="text-red-800">
                      {{
                        onUploadProgress["ijazah_s3"] ? "Uploading file..." : ""
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <a
                    v-if="dataRekrutmenfile['ijazah_s3']"
                    class="btn btn-primary btn-sm w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['ijazah_s3']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    S3
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Ijazah
                    S3
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["ijazah_s3"]
                        ? dataRekrutmenfile["ijazah_s3"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { sdm } from "@/services/models";
// import LoadingSpinner from "@/components/loading-spinner/Main";
export default {
  components: {
    AlertSuccess,
    AlertFailed,
    // LoadingSpinner,
  },
  data() {
    return {
      id: this.$route.params.id,
      dataRekrutmenfile: {
        cv: null,
        surat_lamaran: null,
        KTP: null,
        ijazah_sma: null,
        ijazah_d3: null,
        ijazah_d4: null,
        ijazah_s1: null,
        ijazah_s2: null,
        ijazah_s3: null,
      },
      file: "",
      // namaFile: "",
      onUploadProgress: {
        cv: false,
        surat_lamaran: false,
        KTP: false,
        ijazah_sma: false,
        ijazah_d3: false,
        ijazah_d4: false,
        ijazah_s1: false,
        ijazah_s2: false,
        ijazah_s3: false,
      },
      download_url: process.env.VUE_APP_API,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      // isLoading: false,
      // useState: false,
    };
  },
  mounted() {
    this.getDetailRekrutmen();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailRekrutmen() {
      if (this.id?.length > 0) {
        // this.isLoading = true;
        sdm.Rekrutmen.getCustomPath(`getDetailRekrutmen/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                if (res.data.data?.sdm_rekrutmen_file !== null) {
                  this.dataRekrutmenfile = res.data.data?.sdm_rekrutmen_file;
                }
              }
            }
          })
          .finally(() => {
            // this.isLoading = false;
          });
      }
    },
    onChangeFileSoftskill(event, category) {
      const fd = new FormData();
      fd.append("file_category", category);
      fd.append("file", event.target.files[0]);
      fd.append("id_rekrutmen", this.id);

      // if (event) {
      //   this.namaFile = event.target.files[0].name;
      // } else {
      //   this.namaFile = "";
      // }

      this.alertFailed = false;
      this.messageAlert = [];

      this.onUploadProgress[category] = true;

      sdm.Rekrutmen.postCustomPath("kirimFile", fd)
        .then((res) => {
          if (res.data.code === 200) {
            // this.namaFile = "";
            this.file = res.data.fileName;
            // this.showImportFileSoftSkill = false;
            // this.download_url[category] =
            //   process.env.VUE_APP_API.slice(
            //     0,
            //     process.env.VUE_APP_API.length - 4
            //   ) +
            //   "uploads/sdm/" +
            //   this.id +
            //   "/" +
            //   this.file;
          } else {
            this.alertFailed = true;
            Object.keys(res.data.message).forEach((key) => {
              this.messageAlert.push(res.data.message[key][0]);
            });
            // console.log(res.data.message.file);
            this.scrollToTop();
          }
        })
        .finally(() => {
          this.getDetailRekrutmen();

          if (this.onUploadProgress[category] == true)
            return (this.onUploadProgress[category] = false);
        });
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
