import store from "@/store";

const mainmenu = [
  {
    icon: "HomeIcon",
    pageName: "dashboard",
    title: "Dashboard",
    ability: "*",
  },
  {
    icon: "usersIcon",
    pageName: "sdm",
    title: "SDM",
    ability: ["*"],
    subMenu: [
      {
        icon: "UserIcon",
        pageName: "dosen",
        ability: ["*"],
        title: "Dosen",
      },
      {
        icon: "UserIcon",
        pageName: "tendik",
        ability: ["*"],
        title: "Tenaga Pendidik",
      },
    ],
  },
  {
    icon: "BookmarkIcon",
    pageName: "reward",
    title: "Reward & Punishment",
    ability: ["*"],
    subMenu: [
      {
        icon: "UserIcon",
        pageName: "dosen",
        ability: ["*"],
        title: "Reward",
      },
      {
        icon: "UserIcon",
        pageName: "tendik",
        ability: ["*"],
        title: "Punishment",
      },
    ],
  },
];

const isSdmTeam = () => {
  let cid = store.getters["userdata/currentTeamId"];
  let adminsdm = store.state.auth.admteam;
  return adminsdm.includes(cid?.toString());
};

const install = (app) => {
  app.config.globalProperties.$ac_menu = mainmenu;
};

export {
  // menuakademik,
  // menuperkuliahan,
  // menupesertadidik,
  // menudosen,
  // menuSdm,
  mainmenu,
  isSdmTeam,
  install as default,
};
