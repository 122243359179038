<template>
  <!-- BEGIN: Account Menu -->
  <div class="intro-x dropdown w-8 h-8">
    <div
      class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      role="button"
      aria-expanded="false"
    >
      <img
        alt="Icewall Tailwind HTML Admin Template"
        :src="store.state.userdata.userData?.profile_photo_url"
      />
    </div>
    <div id="dropdown-menu" class="dropdown-menu w-56">
      <div
        class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
      >
        <div class="p-4 border-b border-theme-27 dark:border-dark-3">
          <div class="font-medium">
            {{ store.state.userdata.userData?.name }}
          </div>
          <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
            {{ store.state.userdata.userData?.uid }}
          </div>
        </div>
        <div class="p-2">
          <a
            class="flex items-center cursor-pointer block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click="profilePage"
          >
            <UserIcon class="w-4 h-4 mr-2" /> Profile
          </a>
        </div>

        <div class="p-2 border-t border-theme-27 dark:border-dark-3">
          <!--          <a-->
          <!--            v-if="isSuperAdmin()"-->
          <!--            class="flex items-center cursor-pointer block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
          <!--            @click="switchRoleTo"-->
          <!--          >-->
          <!--            <UserXIcon class="w-4 h-4 mr-2" /> Switch Role to...-->
          <!--          </a>-->

          <!--          <a-->
          <!--            v-if="loginAs"-->
          <!--            href="#"-->
          <!--            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"-->
          <!--            @click="restoreRole"-->
          <!--          >-->
          <!--            <UserCheckIcon class="w-4 h-4 mr-2" /> Restore Role-->
          <!--          </a>-->
          <a
            href="#"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            @click="logOut"
          >
            <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Account Menu -->
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { mapActions } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "AccountMenu",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
  data() {
    return {
      app_title: this.app_title,
    };
  },
  computed: {
    loginAs() {
      return this.$store.state.auth.loginas;
    },
  },
  methods: {
    ...mapActions({
      LogOut: "auth/LogOut",
      RestoreRole: "auth/RestoreRole",
    }),
    logOut: function () {
      cash("#dropdown-menu").dropdown("hide");
      this.LogOut();
    },
    restoreRole: function () {
      cash("#dropdown-menu").dropdown("hide");
      this.RestoreRole();
    },
    switchRoleTo: function () {
      cash("#dropdown-menu").dropdown("hide");
      router.push({ name: "switchrole" });
    },
    profilePage: function () {
      cash("#dropdown-menu").dropdown("hide");
      router.push({ name: "profile" });
    },
  },
});
</script>

<style scoped></style>
