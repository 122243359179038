<template>
  <div v-if="!loadingStatus" class="intro-y p-0 col-span-12 lg:col-span-6">
    <div class="grid grid-cols-12 gap-2">
      <div v-if="tabon == 'reward'" class="intro-y col-span-12 lg:col-span-12">
        <dataReward />
      </div>
      <div v-if="tabon == 'sp'" class="intro-y col-span-12 lg:col-span-12">
        <dataSp />
      </div>
    </div>
  </div>
</template>

<script>
import dataReward from "./rewardsp/dataReward";
import dataSp from "./rewardsp/dataSp.vue";

export default {
  components: {
    dataReward,
    dataSp,
  },
  props: {
    sdm: {
      type: Object,
      default: null,
    },
    tabon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
};
</script>

<style></style>
