<template>
  <div v-if="!loading" class="intro-y col-span-12 lg:col-span-12">
    <SavingStatus :is-saving="isSaving" :is-success="isSuccess"></SavingStatus>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl
        class="col-span-12 lg:col-span-12 border-2 border-dashed dark:border-dark-5 rounded-md py-6"
      >
        <DataList
          :white="true"
          :dd="inpassing?.pangkat_golongan?.nm_pangkat"
          :dt="'Golongan/Pangkat'"
        ></DataList>
        <DataList
          :white="true"
          :dd="inpassing.sk_pangkat"
          :dt="'Nomor SK Inpassing'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(inpassing.tgl_sk_pangkat)"
          :dt="'Tanggal SK'"
        ></DataList>
        <DataList
          :white="true"
          :dd="$h.tanggal(inpassing.tmt_sk_pangkat)"
          :dt="'Terhitung Mulai Tanggal'"
        ></DataList>
        <DataList
          :white="true"
          :dd="inpassing.angka_kredit"
          :dt="'Angka Kredit'"
        ></DataList>
        <DataList
          :white="true"
          :dd="parseFloat(inpassing.masa_kerja_gol_thn).toFixed(0)"
          :dt="'Masa Kerja (Tahun)'"
        ></DataList>
        <DataList
          :white="true"
          :dd="parseFloat(inpassing.masa_kerja_gol_bln).toFixed(0)"
          :dt="'Masa Kerja (Bulan)'"
        ></DataList>
      </dl>
    </div>
  </div>
</template>

<script>
import DataList from "../components/DataList";
import SavingStatus from "@/components/alerts/SavingStatus.vue";

export default {
  components: {
    DataList,
    SavingStatus,
  },
  props: {
    inpassing: {
      type: Object,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      nm_sdm: null,
      nip: null,
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.main.isSuccess;
    },
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    inpassing: {
      handler: function (data) {
        if (data?.jabfung !== undefined) {
          this.loading = false;
        } else {
          this.loading = this.isLoading;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
