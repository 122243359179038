<template>
  <MainLayout :side-title="'.'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Rincian Kegiatan Rekognisi</h2>
      </div>
      <div class="post intro-y overflow-hidden box mt-5">
        <div
          class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
          role="tablist"
        >
          <TippyTabNav
            :tab-nav-id="'general'"
            :content="'Rincian Kegiatan Rekognisi'"
            :active-tab="
              activeTab == '' || activeTab === 'general' ? 'active' : ''
            "
            :tab-label="'general'"
            @click="activeTab = 'general'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Detail
          </TippyTabNav>
          <TippyTabNav
            :tab-nav-id="'berkas'"
            :content="'Berkas Laporan'"
            :active-tab="activeTab === 'berkas' ? 'active' : ''"
            :tab-label="'berkas'"
            @click="activeTab = 'berkas'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Berkas Laporan
          </TippyTabNav>
          <TippyTabNav
            :tab-nav-id="'status'"
            :content="'Status Ajuan'"
            :active-tab="activeTab === 'status' ? 'active' : ''"
            :tab-label="'status'"
            @click="activeTab = 'status'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Status Ajuan
          </TippyTabNav>
        </div>
        <div class="tab-content">
          <TabContent3
            :tab-id="'general'"
            :active-tab="
              activeTab == '' || activeTab === 'general' ? 'active' : ''
            "
          >
            <editformdataRekognisi
              v-if="activeTab == '' || activeTab === 'general'"
              @detailDataRekognisi="setDetailDataRekognisi"
            ></editformdataRekognisi>
          </TabContent3>
          <TabContent3
            :tab-id="'berkas'"
            :active-tab="activeTab === 'berkas' ? 'active' : ''"
          >
            <editformdataBerkasRekognisi
              v-if="activeTab === 'berkas'"
            ></editformdataBerkasRekognisi>
          </TabContent3>
          <TabContent3
            :tab-id="'status'"
            :active-tab="activeTab === 'status' ? 'active' : ''"
          >
            <editformdataAjuanRekognisi
              v-if="activeTab === 'status'"
            ></editformdataAjuanRekognisi>
          </TabContent3>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout.vue";
import editformdataRekognisi from "@/views/rekognisi/partial/editformdataRekognisi.vue";
import editformdataBerkasRekognisi from "@/views/rekognisi/partial/editformdataBerkasRekognisi.vue";
import editformdataAjuanRekognisi from "@/views/rekognisi/partial/editformdataAjuanRekognisi.vue";
import TippyTabNav from "@/components/tabs/TippyTabNav.vue";
import TabContent3 from "@/components/tabs/TabContent3.vue";

export default {
  components: {
    MainLayout,
    editformdataRekognisi,
    editformdataBerkasRekognisi,
    editformdataAjuanRekognisi,
    TippyTabNav,
    TabContent3,
  },
  data() {
    return {
      detailRekognisi: [],
      activeTab: null,
      tabOnStudy: "general",
    };
  },
  mounted() {
    this.activeTab = this.$route.params?.tab;
    if (this.activeTab === undefined) this.activeTab = "";
  },
  methods: {
    setDetailDataRekognisi(val) {
      this.detailRekognisi = val;
      if (this.detailRekognisi["id_sdm"] == null) return 0;
    },
  },
};
</script>
