import API from "../api";
export const akademik = {
  Kurikulum: new API("kurikulum_sp"),
  MataKuliah: new API("matkul"),
  MataKuliahById: new API("matkul_kurikulum_sp/getMatkulByIdKurikulumSp"),
  MatkulKurikulum: new API("matkul_kurikulum"),
  Ruangan: new API("ruangan"),
  Gedung: new API("gedung"),
  PrasyaratMatkul: new API("prasyaratMatkul"),
  RiwayatPendidikan: new API("riwayatPendidikan"),
  RiwayatPendidikanFormal: new API("riwayatPendidikanFormal"),
  RiwayatSertifikasi: new API("riwayatSertifikasi"),
  RiwayatFungsional: new API("riwayatFungsional"),
  RiwayatKepangkatan: new API("riwayatKepangkatan"),
  RiwayatJabatanStruktural: new API("riwayatJabatanStruktural"),
  RiwayatPengabdian: new API("riwayatPengabdian"),
  RiwayatPenelitian: new API("riwayatPenelitian"),
  Kepanitiaan: new API("kepanitiaan"),
  JenisKepanitiaan: new API("jenisKepanitiaan"),
  AnggotaPanitia: new API("anggotaPanitia"),
  Pembicara: new API("pembicara"),
};
