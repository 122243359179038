<template>
  <div v-if="!loadingStatus" class="intro-y p-0 col-span-12 lg:col-span-6">
    <div class="box">
      <div class="grid grid-cols-12 gap-2">
        <div
          v-if="tabon == 'RwyPendidikanFormal'"
          class="intro-y col-span-12 lg:col-span-12"
        >
          <div
            class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Data Pribadi</h2>
          </div>

          <div class="intro-y p-5 col-span-12 lg:col-span-6">
            <div class="col-span-12 mb-4">
              <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
              <AlertFailed v-if="alertFailed" :message="messageAlert" />
            </div>
            <div
              v-if="isLoading"
              class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
            >
              <div class="opacity-100">
                <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
              </div>
            </div>
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
                <div class="flex items-end justify-end space-x-4 mb-6">
                  <button
                    v-if="model.id_rekrutmen.length"
                    class="btn btn-success w-full md:w-64"
                    @click="addMoreStudy"
                  >
                    <PlusIcon class="w-4 h-4 mr-2" /> Tambah Pendidikan Lainnya
                  </button>
                  <div>
                    <button
                      v-if="model.PendidikanKuliah.length"
                      class="btn btn-primary w-full md:w-52"
                      @click="store"
                    >
                      <SaveIcon class="w-4 h-4 mr-2" /> Simpan
                    </button>
                  </div>
                </div>
                <div class="intro-y">
                  <div
                    v-for="(item, index) in model.PendidikanKuliah"
                    :key="index"
                    class="grid grid-cols-4 gap-2 mb-6 p-5 shadow-lg rounded bg-gray-100"
                  >
                    <div class="col-span-1">
                      <label class="form-label font-medium"
                        >Jenjang Pendidikan</label
                      >
                      <TailSelect
                        v-model="item.id_jenj_didik"
                        :options="{
                          search: true,
                          classNames: 'w-full',
                          deselect: false,
                        }"
                      >
                        <option
                          v-for="itemJendDidik in listJenjangPendidikan"
                          :key="itemJendDidik.id"
                          :value="itemJendDidik.value"
                        >
                          {{ itemJendDidik.text }}
                        </option>
                      </TailSelect>
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Nama Lembaga</label>
                      <input
                        v-model="item.nmLembaga"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Fakultas</label>
                      <input
                        v-model="item.fak"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Tahun Masuk</label>
                      <input
                        v-model="item.tahunMasuk"
                        type="number"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium"
                        >Tanggal Lulus</label
                      >
                      <input
                        v-model="item.tanggalLulus"
                        type="date"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Tahun Lulus</label>
                      <input
                        v-model="item.tahunLulus"
                        type="number"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Sks Lulus</label>
                      <input
                        v-model="item.sksLulus"
                        type="number"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Ipk</label>
                      <input
                        v-model="item.ipk"
                        type="number"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Nomor Ijazah</label>
                      <input
                        v-model="item.no_ijazah"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium"
                        >Nomor Induk Peserta Didik</label
                      >
                      <input
                        v-model="item.nipd"
                        type="number"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium"
                        >Judul Tugas Akhir</label
                      >
                      <input
                        v-model="item.judul_tesis"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                      />
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Bidang Studi</label>
                      <TailSelect
                        v-model="item.id_bid_studi"
                        :options="{
                          search: true,
                          classNames: 'w-full',
                          deselect: false,
                        }"
                      >
                        <option
                          v-for="itemBidStudi in listBidangStudi"
                          :key="itemBidStudi.id"
                          :value="itemBidStudi.value"
                        >
                          {{ itemBidStudi.text }}
                        </option>
                      </TailSelect>
                    </div>
                    <div class="col-span-1">
                      <label class="form-label font-medium">Gelar</label>
                      <TailSelect
                        v-model="item.id_gelar_akad"
                        :options="{
                          search: true,
                          classNames: 'w-full',
                          deselect: false,
                        }"
                      >
                        <option
                          v-for="itemGelarAkademik in listGelarAkademik"
                          :key="itemGelarAkademik.id"
                          :value="itemGelarAkademik.value"
                        >
                          {{ itemGelarAkademik.text }}
                        </option>
                      </TailSelect>
                    </div>

                    <div class="col-span-4 pt-4 text-right">
                      <button
                        class="btn btn-danger w-full"
                        @click="remove(index)"
                      >
                        <Trash2Icon class="w-4 h-4 mr-2" /> Hapus
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <dataRwyPendidikanFormal-->
          <!--            :sdmrekrutmen="sdm"-->
          <!--            @onUpdate="onUpdateRwyPendidikan"-->
          <!--          />-->
        </div>
        <div
          v-if="tabon == 'RwyPendidikanNonFormal'"
          class="intro-y col-span-12 lg:col-span-12"
        >
          <!--        <dataRwyPendidikanFormal />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertFailed from "@/components/alerts/AlertFailed.vue";
import LoadingSpinner from "@/components/loading-spinner/Main.vue";
import { ref, akademik } from "@/services/models";
// import dataRwyPendidikanFormal from "./rwypendidikan/dataRwyPendidikanFormal";

export default {
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
    // dataRwyPendidikanFormal,
  },
  props: {
    sdm: {
      type: Object,
      default: null,
    },
    tabon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      id: this.$route.params.id,
      listJenjangPendidikan: [],
      listGelarAkademik: [],
      listBidangStudi: [],
      pendidikanFormal: [],
      // selectGelar: null,
      model: {
        idSdm: "",
        id_rekrutmen: "",
        PendidikanKuliah: [],
      },

      pendFormal: [],
      alertFailed: false,
      alertSuccess: false,
      messageAlert: "",
      isLoading: false,
      useState: false,
      key: 0,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    sdm: {
      handler: function (data) {
        if (data?.id_sdm !== undefined) {
          this.isLoading = false;

          this.getDetailRekrutmen();
        }
      },
      immediate: true,
    },
    // "model.id_rekrutmen": {
    //   handler: function () {
    //     let search = this.pendFormal.filter(
    //       (item) => item.id_rekrutmen == this.model.id_rekrutmen
    //     )[0];
    //     this.model.PendidikanKuliah = [];
    //     search?.pend_formal.forEach((item) => {
    //       // let gelar = this.listGelarAkademik.filter(
    //       //   (i) => i.id == item?.id_gelar_akad
    //       // );
    //       this.model.PendidikanKuliah.push({
    //         id_rwy_didik_formal: item?.id_rwy_didik_formal,
    //         id_sdm: item?.id_sdm,
    //         fak: item?.fak,
    //         ipk: item?.ipk,
    //         nipd: item?.nipd,
    //         no_ijazah: item?.no_ijazah,
    //         id_bid_studi: item?.id_bid_studi,
    //         id_gelar_akad: item?.id_gelar_akad,
    //         nmLembaga: item?.nm_sp_formal,
    //         judul_tesis: item?.judul_tesis,
    //         sksLulus: parseInt(item?.sks_lulus),
    //         tahunLulus: parseInt(item?.thn_lulus),
    //         tahunMasuk: parseInt(item?.thn_masuk),
    //         tanggalLulus: item?.tgl_lulus,
    //         // selectGelar: null,
    //         id_jenj_didik: item?.id_jenj_didik,
    //       });
    //     });
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.getJenjangPendidikan();
    this.getBidangStudi();
    this.getGelarAkademik();
    // this.getDosen();
  },
  methods: {
    async getDetailRekrutmen() {
      this.isLoading = false;

      this.pendFormal = this.sdm.pend_formal;
      this.model.id_rekrutmen = this.sdm.id_rekrutmen;
      this.model.idSdm = this.sdm.id_sdm;
      this.model.PendidikanKuliah = [];

      this.pendFormal.forEach((item) => {
        // let gelar = this.listGelarAkademik.filter(
        //   (i) => i.id == item?.id_gelar_akad
        // );
        this.model.PendidikanKuliah.push({
          id_rwy_didik_formal: item?.id_rwy_didik_formal,
          id_sdm: item?.id_sdm,
          fak: item?.fak,
          ipk: item?.ipk,
          nipd: item?.nipd,
          no_ijazah: item?.no_ijazah,
          id_bid_studi: item?.id_bid_studi
            ? item?.id_bid_studi.toString()
            : "0",
          id_gelar_akad: item?.id_gelar_akad
            ? item?.id_gelar_akad.toString()
            : "0",
          nmLembaga: item?.nm_sp_formal,
          judul_tesis: item?.judul_tesis,
          sksLulus: parseInt(item?.sks_lulus),
          tahunLulus: parseInt(item?.thn_lulus),
          tahunMasuk: parseInt(item?.thn_masuk),
          tanggalLulus: item?.tgl_lulus,
          // selectGelar: null,
          id_jenj_didik: item?.id_jenj_didik,
        });
      });
    },
    async getJenjangPendidikan() {
      this.listJenjangPendidikan.push({
        id: "0",
        value: "",
        text: "Pilih Jenjang",
        selected: true,
      });
      ref.jenjPend.getAll().then((res) => {
        // console.log(res);
        if (res.status === 200) {
          res.data.data.forEach((item) => {
            this.listJenjangPendidikan.push({
              id: item.id_jenj_didik,
              value: item.id_jenj_didik,
              text: item.nm_jenj_didik,
              selected: false,
            });
          });
        }
      });
    },
    async getBidangStudi() {
      this.listBidangStudi.push({
        id: "0",
        value: "0",
        text: "Pilih Bidang Studi",
        selected: true,
      });
      ref.Bidangstudi.getAll().then((res) => {
        // console.log(res);
        if (res.status === 200) {
          res.data.data.forEach((item) => {
            this.listBidangStudi.push({
              id: item.id_bid_studi,
              value: item.id_bid_studi,
              text: item.nm_bid_studi,
              selected: false,
            });
          });
        }
      });
    },
    async getGelarAkademik() {
      this.listGelarAkademik.push({
        id: "0",
        value: "0",
        text: "Pilih Gelar Akademik",
        selected: true,
      });
      ref.GelarAkademik.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((item) => {
            this.listGelarAkademik.push({
              id: item.id_gelar_akad,
              value: item.id_gelar_akad,
              text: `${item.nm_gelar_akad} | ${item.singkat_gelar}`,
              selected: false,
            });
          });
        }
      });
    },
    addMoreStudy() {
      this.model.PendidikanKuliah.unshift({
        id_rwy_didik_formal: null,
        id_sdm: this.model.idSdm,
        fak: null,
        ipk: null,
        nipd: null,
        no_ijazah: null,
        nmLembaga: null,
        judul_tesis: null,
        id_bid_studi: "0",
        id_gelar_akad: "0",
        sksLulus: null,
        tahunLulus: null,
        tahunMasuk: null,
        tanggalLulus: null,
        // selectGelar: null,
        id_jenj_didik: "0",
      });
    },
    store() {
      this.alertSuccess = false;
      this.alertFailed = false;

      this.isLoading = true;

      akademik.RiwayatPendidikanFormal.insertData(this.model).then((res) => {
        // console.log(res);
        if (res.status === 200) {
          this.alertSuccess = true;
          this.messageAlert = res.data.message;
          this.$emit("onChange", true);
        } else {
          this.alertFailed = true;
          this.messageAlert = res.data.message;
        }
        this.isLoading = false;
      });
    },
    remove(index) {
      this.isLoading = true;

      let idRwyDidik = this.model.PendidikanKuliah[index].id_rwy_didik_formal;

      if (idRwyDidik) {
        akademik.RiwayatPendidikanFormal.deleteData(idRwyDidik).then((res) => {
          if (res.status === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;
            this.model.PendidikanKuliah.splice(index, 1);
            this.$emit("onChange", true);
          } else {
            this.alertFailed = true;
            this.messageAlert = res.data.message;
          }
          this.isLoading = false;
        });
      } else {
        this.model.PendidikanKuliah.splice(index, 1);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
