<template>
  <MainLayout :side-title="'.'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Detail Ajuan Perubahan Data Pribadi
        </h2>
      </div>
      <div class="post intro-y overflow-hidden box mt-5">
        <div
          class="post__tabs nav nav-tabs flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
          role="tablist"
        >
          <TippyTabNav
            :tab-nav-id="'general'"
            :content="'Data Pribadi Yang di Ajukan'"
            :active-tab="
              activeTab == '' || activeTab === 'general' ? 'active' : ''
            "
            :tab-label="'general'"
            @click="activeTab = 'general'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Ajuan Data Pribadi
          </TippyTabNav>
          <TippyTabNav
            :tab-nav-id="'status'"
            :content="'Status Ajuan'"
            :active-tab="activeTab === 'status' ? 'active' : ''"
            :tab-label="'status'"
            @click="activeTab = 'status'"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Status Ajuan
          </TippyTabNav>
        </div>
        <div class="tab-content">
          <TabContent3
            :tab-id="'general'"
            :active-tab="
              activeTab == '' || activeTab === 'general' ? 'active' : ''
            "
          >
            <editformdataDatapribadi
              v-if="activeTab == '' || activeTab === 'general'"
            ></editformdataDatapribadi>
          </TabContent3>
          <TabContent3
            :tab-id="'status'"
            :active-tab="activeTab === 'status' ? 'active' : ''"
          >
            <editformdataAjuanDatapribadi
              v-if="activeTab === 'status'"
            ></editformdataAjuanDatapribadi>
          </TabContent3>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/main/MainLayout";
import editformdataDatapribadi from "@/views/data-pribadi/partial/editformdataDatapribadi";
import editformdataAjuanDatapribadi from "@/views/data-pribadi/partial/editformdataAjuanDatapribadi";
import TippyTabNav from "@/components/tabs/TippyTabNav";
import TabContent3 from "@/components/tabs/TabContent3";

export default {
  components: {
    MainLayout,
    editformdataDatapribadi,
    editformdataAjuanDatapribadi,
    TippyTabNav,
    TabContent3,
  },
  data() {
    return {
      activeTab: null,
      tabOnStudy: "general",
    };
  },
  mounted() {
    this.activeTab = this.$route.params?.tab;
    if (this.activeTab === undefined) this.activeTab = "";
  },
  methods: {},
};
</script>
