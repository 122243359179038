const state = () => {
  return {
    sisterserver: false,
    sistertoken: null,
    sisterconnect: null,
    sitererror: null,
  };
};

// getters
const getters = {
  sisterconnect: (state) => state.sisterconnect,
  sistererror: (state) => state.sistererror,
  sisterserver: (state) => state.sisterserver,
  sistertoken: (state) => state.sistertoken,
};

// actions
const actions = {
  sisterconnect({ commit }, sisterconnect) {
    commit("sisterconnect", { sisterconnect });
  },
  sisterserver({ commit }, sisterserver) {
    commit("sisterserver", { sisterserver });
  },
  sistertoken({ commit }, sistertoken) {
    commit("sistertoken", { sistertoken });
  },
  sistererror({ commit }, sistererror) {
    commit("sistererror", { sistererror });
  },
};

// mutations
const mutations = {
  sisterconnect(state, { sisterconnect }) {
    state.sisterconnect = sisterconnect;
  },
  sistertoken(state, { sistertoken }) {
    state.sistertoken = sistertoken;
  },
  sisterserver(state, { sisterserver }) {
    state.sisterserver = sisterserver;
  },
  sistererror(state, { sistererror }) {
    state.sistererror = sistererror;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
