<template>
  <div class="intro-x relative mr-2 sm:mr-3">
    <div class="dark-mode-switcher cursor-pointer" @click="switchMode">
      <!--      <div-->
      <!--        :class="light === 'light' ? 'text-gray-300' : ''"-->
      <!--        class="mr-4 dark:text-white"-->
      <!--      >-->
      <!--        Dark Mode-->
      <!--      </div>-->
      <div
        :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
        class="dark-mode-switcher__toggle border"
      ></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "DarkSwitcher",
  props: {
    light: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const darkMode = computed(() => store.state.main.darkMode);

    const setDarkModeClass = () => {
      darkMode.value
        ? cash("html").addClass("dark")
        : cash("html").removeClass("dark");
    };

    const switchMode = () => {
      store.dispatch("main/setDarkMode", !darkMode.value);
      setDarkModeClass();
    };

    onMounted(() => {
      setDarkModeClass();
    });

    return {
      switchMode,
      darkMode,
    };
  },
});
</script>

<style scoped></style>
