<template>
  <div v-if="!loadingStatus" class="intro-y p-0 col-span-12 lg:col-span-6">
    <div class="grid grid-cols-12 gap-2">
      <div
        v-if="tabon == 'berkaspendataan'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <dataBerkasPendataan />
      </div>
      <div
        v-if="tabon == 'berkaspribadi'"
        class="intro-y col-span-12 lg:col-span-12"
      >
        <dataBerkasPribadi />
      </div>
    </div>
  </div>
</template>

<script>
import dataBerkasPendataan from "./simarta/dataBerkasPendataan";
import dataBerkasPribadi from "./simarta/dataBerkasPribadi";

export default {
  components: {
    dataBerkasPendataan,
    dataBerkasPribadi,
  },
  props: {
    sdm: {
      type: Object,
      default: null,
    },
    tabon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // loading: true,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
};
</script>

<style></style>
