<template>
  <div class="intro-y box mt-5">
    <div class="p-5">
      <div class="flex flex-col items-center justify-center w-full h-full p-5">
        <umbrella-icon class="h-6 w-6 mb-2"></umbrella-icon>
        <div class="text-lg font-medium">Retirement Reminder</div>
        <div class="text-gray-500 text-center mt-0.5">
          After a lifetime of work, our colleague has hang up his hat in the
          several next year
        </div>
      </div>
      <div class="mx-auto mt-5 w-52 border-t border-dashed sm:w-auto">
        <div v-if="isLoading" class="flex text-center mt-4">
          Loading Data...
        </div>
        <div v-else-if="listRerirement.length > 0">
          <div
            v-for="(item, index) in listRerirement"
            :key="index"
            class="flex items-center mt-4"
          >
            <span class="truncate font-bold"
              >{{ item.nm }}<br />
              <smal class="text-gray-500 text-xs mt-0.5 font-normal">{{
                $h.tanggal(item.tgl_lahir)
              }}</smal></span
            >
            <span class="ml-auto font-medium text-red-500">{{
              $h.getAge(item.tgl_lahir)
            }}</span>
          </div>
        </div>
        <div v-else class="flex text-center mt-4 text-gray-400">
          No result found!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sdm } from "@/services/models";

export default {
  name: "Masa Pensiun",
  components: {},
  data() {
    return {
      listRerirement: [],
      isLoading: false,
    };
  },
  computed: {},
  mounted() {
    this.getBirthday();
  },
  methods: {
    async getBirthday() {
      this.isLoading = true;
      sdm.Rekrutmen.getCustomPath("masapensiun").then((res) => {
        if (res.status === 200) {
          res.data?.result.forEach((element) => {
            if (
              element.age >= "52" &&
              element.sdm_rekrutmen_ref.tenaga_pendidik == "3"
            ) {
              this.listRerirement.push({
                nm: element.nm,
                tgl_lahir: element.tgl_lahir,
              });
            } else if (
              element.age >= "62" &&
              element.sdm_rekrutmen_ref.tenaga_pendidik == "1"
            ) {
              this.listRerirement.push({
                nm: element.nm,
                tgl_lahir: element.tgl_lahir,
              });
            }
          });

          this.isLoading = false;
        }
      });
    },
  },
};
</script>
