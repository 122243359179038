import RefAPI from "../refapi";
export const ref = {
  agama: new RefAPI("agama"),
  transport: new RefAPI("alattransport"),
  jenisdiklat: new RefAPI("jenisdiklat"),
  kategorikegiatan: new RefAPI("kategorikegiatan"),
  jenisrekognisi: new RefAPI("jenisrekognisi"),
  tingkatrekognisi: new RefAPI("tingkatrekognisi"),
  jabfung: new RefAPI("jabfung"), // api endpoint not ready
  jenisdokumensister: new RefAPI("jenisdokumensister"), // api endpoint not ready
  jenissdm: new RefAPI("jenissdm"), // api endpoint not ready
  jenjPend: new RefAPI("jenjPend"),
  jenislembaga: new RefAPI("jenislembaga"),
  jenistinggal: new RefAPI("jenistinggal"),
  kebutuhankhusus: new RefAPI("kebutuhankhusus"),
  lembagapengangkat: new RefAPI("lembagapengangkat"), // api endpoint not ready
  pekerjaan: new RefAPI("pekerjaan"),
  penghasilan: new RefAPI("penghasilan"),
  pangkatgolongan: new RefAPI("pangkatgolongan"), // api endpoint not ready
  smt: new RefAPI("semester"),
  status: new RefAPI("statusmahasiswa"),
  statuskeaktifanpegawai: new RefAPI("statuskeaktifanpegawai"), // api endpoint not ready
  sumbergaji: new RefAPI("sumbergaji"), // api endpoint not ready
  keahlianlab: new RefAPI("keahlianlab"), // api endpoint not ready
  negara: new RefAPI("negara"),
  wilayah: new RefAPI("wilayah"), // need improvement (provinsi/kota/kab/kec/kel belum ada filter),
  tahunAjaran: new RefAPI("tahunAjaran"),
  jurusan: new RefAPI("jurusan"),
  statuskepegawaian: new RefAPI("statusKepegawaian"),
  ikatanKerjaSdm: new RefAPI("ikatanKerjaSdm"),
  jabTugas: new RefAPI("jabTugas"),
  jenisAktMhs: new RefAPI("jenisaktmahasiswa"),
  KategoriCapaianLuar: new RefAPI("kategoriCapaianLuar"),
  Bidangstudi: new RefAPI("bidangstudi"),
  GelarAkademik: new RefAPI("gelarAkademik"),
};
