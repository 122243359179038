<template>
  <form action="" @submit.prevent="submit">
    <div class="intro-y">
      <div>
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div v-if="alertFailed">
          <AlertFailed
            v-for="(item, index) in messageAlert"
            :key="index"
            :message="item"
            :dismissable="true"
          />
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
          <div class="intro-y box pt-5">
            <!--            <div class="alert alert-warning-soft show mb-2 mx-5" role="alert">-->
            <!--              <div class="flex items-center">-->
            <!--                <div class="font-medium text-lg">Awesome alert with icon</div>-->
            <!--              </div>-->
            <!--              <div class="mt-3">-->
            <!--                <ul>-->
            <!--                  <li>aaaa</li>-->
            <!--                  <li>aaaa</li>-->
            <!--                </ul>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="grid grid-cols-12 gap-x-8 p-10 py-15 pb-5">
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4"
              >
                <div class="mt-3 mr-5 sm:!mr-10">
                  <label class="font-medium w-64">Surat Penawaran Kerja</label>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2 mr-5">
                    {{
                      moment(dataRekrutmenref["tgl_mulai_kerja"]).format(
                        "Do MMMM, YYYY"
                      )
                    }}
                  </div>
                  <div class="mr-4 my-4 flex flex-col">
                    <div class="w-100">
                      <CurrencyInput
                        v-model="upah_penawaran_kerja"
                        type="text"
                        class="form-control focus:ring-0 mb-1"
                        placeholder="(RP) Nominal Upah Penawaran Kerja"
                        :options="{ currency: 'IDR' }"
                      />
                      <div
                        class="leading-relaxed text-gray-600 text-xs text-red-500 my-3 mt-0"
                      >
                        *Nominal Upah wajib diisi
                      </div>
                    </div>
                    <div class="form-check mt-1 mb-4 hidden">
                      <input
                        id="check-penawaran-kerja"
                        v-model="buat_nomor_penawaran_kerja"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="check-penawaran-kerja"
                        >Buat/Pakai No. Surat Baru</label
                      >
                    </div>
                    <button
                      v-if="upah_penawaran_kerja"
                      class="btn btn-sm btn-success w-full flex-shrink-0 sm:w-auto"
                      type="button"
                      @click="
                        cetakKontrak(
                          4,
                          upah_penawaran_kerja,
                          buat_nomor_penawaran_kerja
                        )
                      "
                    >
                      <LoadingIcon
                        v-if="downloading"
                        icon="puff"
                        color="white"
                        class="w-4 h-4 mr-2"
                      /><DownloadIcon v-else class="w-4 h-4 mr-2" />

                      Download Surat Kontrak Kerja Baru
                    </button>
                    <button
                      v-else
                      class="btn btn-sm btn-secondary disabled cursor-not-allowed w-full sm:w-auto"
                      type="button"
                    >
                      <DownloadIcon class="w-4 h-4 mr-2" />
                      Download Surat Kontrak Kerja Baru
                    </button>
                  </div>
                </div>
                <div class="flex flex-col items-start"></div>
                <div
                  class="flex flex-col items-end bg-gray-100 py-5 px-3 pr-5 w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <div class="w-100">
                    <input
                      v-model="nomor_penawaran_kerja"
                      type="text"
                      class="form-control focus:ring-0 w-56 mb-1"
                      placeholder="No. Surat (XXX/UMDP/XX/A/XXXX)"
                    />
                  </div>
                  <div
                    v-if="nomor_penawaran_kerja"
                    class="btn btn-sm btn-primary relative cursor-pointer w-56"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['penawaran_kerja']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <UploadIcon
                      v-else-if="!onUploadProgress['penawaran_kerja']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload Surat

                    <input
                      id="fileUpload1"
                      type="file"
                      class="inputfile cursor-pointer"
                      @change="
                        onChangeFileKontrak(
                          $event,
                          'penawaran_kerja',
                          nomor_penawaran_kerja
                        )
                      "
                    />
                  </div>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <UploadIcon class="mr-2 mr-2 w-4 h-4" /> Upload Surat
                  </button>
                  <div class="leading-relaxed text-red-800 text-xs mt-1 mb-4">
                    {{
                      onUploadProgress["penawaran_kerja"]
                        ? "Uploading file..."
                        : ""
                    }}
                  </div>

                  <a
                    v-if="dataRekrutmenfile['penawaran_kerja']"
                    class="btn btn-sm btn-success w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['penawaran_kerja']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["penawaran_kerja"]
                        ? dataRekrutmenfile["penawaran_kerja"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-5"
              >
                <div class="mt-3 mr-5 sm:!mr-10">
                  <label class="font-medium w-64"
                    >Surat Kontrak Kerja (Masa Percobaan)</label
                  >
                  <div class="leading-relaxed text-gray-600 text-xs mt-2 mr-5">
                    {{
                      moment(dataRekrutmenref["tgl_mulai_training"]).format(
                        "Do MMMM, YYYY"
                      ) +
                      " - " +
                      moment(dataRekrutmenref["tgl_berakhir_training"]).format(
                        "Do MMMM, YYYY"
                      )
                    }}
                  </div>
                  <div class="mr-4 my-4 flex flex-col">
                    <div class="w-100">
                      <CurrencyInput
                        v-model="upah_masa_percobaan"
                        type="text"
                        class="form-control focus:ring-0 mb-1"
                        placeholder="Nominal Upah Masa Percobaan"
                        :options="{ currency: 'IDR' }"
                      />
                      <div
                        class="leading-relaxed text-gray-600 text-xs text-red-500 my-3 mt-0"
                      >
                        *Nominal Upah wajib diisi
                      </div>
                    </div>
                    <div class="form-check mt-1 mb-4 hidden">
                      <input
                        id="check-masa-percobaan"
                        v-model="buat_nomor_masa_percobaan"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="check-masa-percobaan"
                        >Buat/Pakai No. Surat Baru</label
                      >
                    </div>
                    <button
                      v-if="upah_masa_percobaan"
                      class="btn btn-sm btn-success w-full sm:w-auto"
                      type="button"
                      @click="
                        cetakKontrak(
                          1,
                          upah_masa_percobaan,
                          buat_nomor_masa_percobaan
                        )
                      "
                    >
                      <LoadingIcon
                        v-if="downloading"
                        icon="puff"
                        color="white"
                        class="w-4 h-4 mr-2"
                      /><DownloadIcon v-else class="w-4 h-4 mr-2" />

                      Download Surat Kontrak Kerja Baru
                    </button>
                    <button
                      v-else
                      class="btn btn-sm btn-secondary disabled cursor-not-allowed w-full sm:w-auto"
                      type="button"
                    >
                      <DownloadIcon class="w-4 h-4 mr-2" />
                      Download Surat Kontrak Kerja Baru
                    </button>
                  </div>
                </div>
                <div class="flex flex-col items-start"></div>
                <div
                  class="flex flex-col items-end bg-gray-100 py-5 px-3 pr-5 w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <div class="w-100">
                    <input
                      v-model="nomor_masa_percobaan"
                      type="text"
                      class="form-control focus:ring-0 w-56 mb-1"
                      placeholder="No. Surat (XXX/UMDP/XX/A/XXXX)"
                    />
                  </div>
                  <div
                    v-if="nomor_masa_percobaan"
                    class="btn btn-sm btn-primary relative cursor-pointer w-56"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['masa_percobaan']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <UploadIcon
                      v-else-if="!onUploadProgress['masa_percobaan']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload Surat

                    <input
                      id="fileUpload1"
                      type="file"
                      class="inputfile cursor-pointer"
                      @change="
                        onChangeFileKontrak(
                          $event,
                          'masa_percobaan',
                          nomor_masa_percobaan
                        )
                      "
                    />
                  </div>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <UploadIcon class="mr-2 mr-2 w-4 h-4" /> Upload Surat
                  </button>
                  <div class="leading-relaxed text-red-800 text-xs mt-1 mb-4">
                    {{
                      onUploadProgress["masa_percobaan"]
                        ? "Uploading file..."
                        : ""
                    }}
                  </div>

                  <a
                    v-if="dataRekrutmenfile['masa_percobaan']"
                    class="btn btn-sm btn-success w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['masa_percobaan']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["masa_percobaan"]
                        ? dataRekrutmenfile["masa_percobaan"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-5"
              >
                <div class="mt-3 mr-5 sm:!mr-10">
                  <label class="font-medium w-64">Surat Kontrak Kerja I</label>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2 mr-5">
                    {{
                      moment(dataRekrutmenref["tgl_mulai_kontrak_1"]).format(
                        "Do MMMM, YYYY"
                      ) +
                      " - " +
                      moment(dataRekrutmenref["tgl_berakhir_kontrak_1"]).format(
                        "Do MMMM, YYYY"
                      )
                    }}
                  </div>
                  <div class="mr-4 my-4 flex flex-col">
                    <div class="form-check mt-1 mb-4 hidden">
                      <input
                        id="check-kontrak-1"
                        v-model="buat_nomor_kontrak_1"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="check-kontrak-1"
                        >Buat/Pakai No. Surat Baru</label
                      >
                    </div>
                    <button
                      v-if="dataRekrutmenref['tgl_mulai_kontrak_1']"
                      class="btn btn-sm btn-success w-full sm:w-auto"
                      type="button"
                      @click="cetakKontrak(2, 0, buat_nomor_kontrak_1)"
                    >
                      <DownloadIcon class="w-4 h-4 mr-2" />
                      Download Surat Kontrak Kerja Baru
                    </button>
                    <button
                      v-else
                      class="btn btn-sm btn-secondary disabled cursor-not-allowed w-full sm:w-auto"
                      type="button"
                    >
                      <DownloadIcon class="w-4 h-4 mr-2" />
                      Download Surat Kontrak Kerja Baru
                    </button>
                  </div>
                </div>
                <div class="flex flex-col items-start"></div>
                <div
                  class="flex flex-col items-end bg-gray-100 py-5 px-3 pr-5 w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <div class="w-100">
                    <input
                      v-model="nomor_kontrak_1"
                      type="text"
                      class="form-control focus:ring-0 w-56 mb-1"
                      placeholder="No. Surat (XXX/UMDP/XX/A/XXXX)"
                    />
                  </div>
                  <div
                    v-if="nomor_kontrak_1"
                    class="btn btn-sm btn-primary relative cursor-pointer w-56"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['kontrak_1']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <UploadIcon
                      v-else-if="!onUploadProgress['kontrak_1']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload Surat

                    <input
                      id="fileUpload1"
                      type="file"
                      class="inputfile cursor-pointer"
                      @change="
                        onChangeFileKontrak(
                          $event,
                          'kontrak_1',
                          nomor_kontrak_1
                        )
                      "
                    />
                  </div>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <UploadIcon class="mr-2 mr-2 w-4 h-4" /> Upload Surat
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1 mb-4">
                    {{
                      onUploadProgress["kontrak_1"] ? "Uploading file..." : ""
                    }}
                  </div>

                  <a
                    v-if="dataRekrutmenfile['kontrak_1']"
                    class="btn btn-sm btn-success w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['kontrak_1']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["kontrak_1"]
                        ? dataRekrutmenfile["kontrak_1"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col-span-12 form-inline items-start flex-col xl:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-4 mt-5"
              >
                <div class="mt-3 mr-5 sm:!mr-10">
                  <label class="font-medium w-64">Surat Kontrak Kerja II</label>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2 mr-5">
                    {{
                      moment(dataRekrutmenref["tgl_mulai_kontrak_2"]).format(
                        "Do MMMM, YYYY"
                      ) +
                      " - " +
                      moment(dataRekrutmenref["tgl_berakhir_kontrak_2"]).format(
                        "Do MMMM, YYYY"
                      )
                    }}
                  </div>
                  <div class="mr-4 my-4 flex flex-col">
                    <div class="form-check mt-1 mb-4 hidden">
                      <input
                        id="check-kontrak-2"
                        v-model="buat_nomor_kontrak_2"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="check-kontrak-2"
                        >Buat/Pakai No. Surat Baru</label
                      >
                    </div>
                    <button
                      v-if="dataRekrutmenref['tgl_mulai_kontrak_2']"
                      class="btn btn-sm btn-success w-full sm:w-auto"
                      type="button"
                      @click="cetakKontrak(3, 0, buat_nomor_kontrak_2)"
                    >
                      <DownloadIcon class="w-4 h-4 mr-2" />
                      Download Surat Kontrak Kerja Baru
                    </button>
                    <button
                      v-else
                      class="btn btn-sm btn-secondary disabled cursor-not-allowed w-full sm:w-auto"
                      type="button"
                    >
                      <DownloadIcon class="w-4 h-4 mr-2" />
                      Download Surat Kontrak Kerja Baru
                    </button>
                  </div>
                </div>
                <div class="flex flex-col items-start"></div>
                <div
                  class="flex flex-col items-end bg-gray-100 py-5 px-3 pr-5 w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"
                >
                  <div class="w-100">
                    <input
                      v-model="nomor_kontrak_2"
                      type="text"
                      class="form-control focus:ring-0 w-56 mb-1"
                      placeholder="No. Surat (XXX/UMDP/XX/A/XXXX)"
                    />
                  </div>
                  <div
                    v-if="nomor_kontrak_2"
                    class="btn btn-sm btn-primary relative cursor-pointer w-56"
                  >
                    <LoadingIcon
                      v-if="onUploadProgress['kontrak_2']"
                      icon="oval"
                      class="w-4 h-4 mr-2"
                    />
                    <UploadIcon
                      v-else-if="!onUploadProgress['kontrak_2']"
                      class="w-4 h-4 mr-2"
                    />
                    Upload Surat

                    <input
                      id="fileUpload1"
                      type="file"
                      class="inputfile cursor-pointer"
                      @change="
                        onChangeFileKontrak(
                          $event,
                          'kontrak_2',
                          nomor_kontrak_2
                        )
                      "
                    />
                  </div>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <UploadIcon class="mr-2 mr-2 w-4 h-4" /> Upload Surat
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-1 mb-4">
                    {{
                      onUploadProgress["kontrak_2"] ? "Uploading file..." : ""
                    }}
                  </div>

                  <a
                    v-if="dataRekrutmenfile['kontrak_2']"
                    class="btn btn-sm btn-success w-56"
                    target="_blank"
                    :href="
                      download_url.slice(0, download_url.length - 4) +
                      'uploads/sdm/' +
                      id +
                      '/' +
                      dataRekrutmenfile['kontrak_2']
                    "
                    download
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </a>
                  <button
                    v-else
                    class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"
                  >
                    <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download Surat
                  </button>
                  <div class="leading-relaxed text-gray-600 text-xs mt-2">
                    File:
                    {{
                      dataRekrutmenfile["kontrak_2"]
                        ? dataRekrutmenfile["kontrak_2"]
                        : "Not found!"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { sdm } from "@/services/models";
import moment from "moment";
// import LoadingSpinner from "@/components/loading-spinner/Main.vue";
import CurrencyInput from "@/components/currency-input/Main.vue";

export default {
  components: {
    CurrencyInput,
    AlertSuccess,
    AlertFailed,
  },
  data() {
    return {
      id: this.$route.params.id,
      // downloadKontrak: "Download Surat Kontrak Kerja Baru",
      // downloadKontrak1: "Download Surat Kontrak Kerja Baru",
      // downloadKontrak2: "Download Surat Kontrak Kerja Baru",
      upah_penawaran_kerja: null,
      upah_masa_percobaan: null,
      buat_nomor_penawaran_kerja: null,
      buat_nomor_masa_percobaan: null,
      buat_nomor_kontrak_1: null,
      buat_nomor_kontrak_2: null,
      nomor_masa_percobaan: null,
      nomor_kontrak_1: null,
      nomor_kontrak_2: null,
      nomor_penawaran_kerja: null,
      dataRekrutmen: {
        KODE: null,
      },
      dataRekrutmenref: {
        tgl_wawancara: null,
        tgl_tes: null,
        tenaga_pendidik: null,
        id_rekrutmen: null,
        nilai_tpa: null,
        tes_tpa: null,
        tes_wawancara: null,
        micro_teaching: null,
        tes_psikologi: null,
        status_rekrutmen: null,
        id_sms: null,
        tgl_mulai_training: null,
        tgl_berakhir_training: null,
        tgl_mulai_kontrak_1: null,
        tgl_berakhir_kontrak_1: null,
        tgl_mulai_kontrak_2: null,
        tgl_berakhir_kontrak_2: null,
        tgl_mulai_kerja: null,
      },
      dataRekrutmenfile: {
        masa_percobaan: null,
        kontrak_1: null,
        kontrak_2: null,
        penawaran_kerja: null,
      },
      file: "",
      // namaFile: "",
      onUploadProgress: {
        masa_percobaan: false,
        kontrak_1: false,
        kontrak_2: false,
        penawaran_kerja: false,
      },
      download_url: process.env.VUE_APP_API,
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      downloading: false,
      // isLoading: false,
      // useState: false,
    };
  },
  mounted() {
    moment.locale("id");
    this.getDetailRekrutmen();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailRekrutmen() {
      if (this.id?.length > 0) {
        // this.isLoading = true;
        sdm.Rekrutmen.getCustomPath(`getDetailRekrutmen/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.dataRekrutmen = res.data.data;

                this.dataRekrutmenref = res.data.data?.sdm_rekrutmen_ref;
                this.dataRekrutmenref = res.data.data?.sdm_rekrutmen_ref;

                if (res.data.data?.sdm_rekrutmen_file !== null) {
                  this.dataRekrutmenfile = res.data.data?.sdm_rekrutmen_file;
                }
              }
            }
          })
          .finally(() => {
            // this.isLoading = false;
          });
      }
    },
    cetakKontrak(jenis_surat, upah, nomor) {
      this.downloading = true;

      let postData = {
        id_rekrutmen: this.dataRekrutmenref.id_rekrutmen,
        jenis_surat: jenis_surat,
        upah: upah,
        buat_nomor: nomor,
      };
      sdm.RekrutmenRef.postCustomPathBlob("generateKontrak", postData).then(
        (res) => {
          // console.log(res);
          let blobFile = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          var fileURL = URL.createObjectURL(blobFile);
          // var fileLink = document.createElement("a");

          window.open(fileURL);
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;

          var filename =
            jenis_surat == 4
              ? "surat_penawaran_"
              : jenis_surat == 3
              ? "kontrak_2_"
              : jenis_surat == 2
              ? "kontrak_1_"
              : jenis_surat == 1
              ? "masa_percobaan_"
              : "";
          fileLink.setAttribute("download", filename + this.id + ".docx");
          document.body.appendChild(fileLink);

          fileLink.click();
          this.downloading = false;
        }
      );
    },
    onChangeFileKontrak(event, category, filename) {
      const fd = new FormData();
      fd.append("file_category", category);
      fd.append("file_name", filename);
      fd.append("file", event.target.files[0]);
      fd.append("id_rekrutmen", this.id);
      fd.append("nik", this.dataRekrutmen.KODE);

      // if (event) {
      //   this.namaFile = event.target.files[0].name;
      // } else {
      //   this.namaFile = "";
      // }

      this.alertFailed = false;
      this.messageAlert = [];

      this.onUploadProgress[category] = true;

      sdm.Rekrutmen.postCustomPath("kirimFile", fd)
        .then((res) => {
          if (res.data.code === 200) {
            // this.namaFile = "";
            this.file = res.data.fileName;
            // this.showImportFileSoftSkill = false;
            // this.download_url[category] =
            //   process.env.VUE_APP_API.slice(
            //     0,
            //     process.env.VUE_APP_API.length - 4
            //   ) +
            //   "uploads/sdm/" +
            //   this.id +
            //   "/" +
            //   this.file;
          } else {
            this.alertFailed = true;
            Object.keys(res.data.message).forEach((key) => {
              this.messageAlert.push(res.data.message[key][0]);
            });
            // console.log(res.data.message.file);
            this.scrollToTop();
          }
        })
        .finally(() => {
          this.getDetailRekrutmen();

          if (this.onUploadProgress[category] == true)
            return (this.onUploadProgress[category] = false);
        });
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
