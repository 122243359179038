<template>
  <form @submit.prevent="submit">
    <div class="intro-y">
      <div>
        <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
        <div v-if="alertFailed">
          <AlertFailed
            v-for="(item, index) in messageAlert"
            :key="index"
            :message="item"
            :dismissable="true"
          />
        </div>
      </div>
      <div
        v-if="isLoading"
        class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
      >
        <div class="opacity-100">
          <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
          <div class="intro-y">
            <div class="grid grid-cols-12 gap-x-8 p-10 py-10">
              <!--              <div class="col-span-12 form-inline">-->
              <!--                <label class="form-label font-medium w-52">ID Rekrutmen</label>-->
              <!--                <input-->
              <!--                  v-model="dataRekrutmenref['id_rekrutmen']"-->
              <!--                  type="text"-->
              <!--                  class="form-control focus:ring-0 w-48"-->
              <!--                  readonly="readonly"-->
              <!--                />-->
              <!--              </div>-->
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-52"
                  >Tanggal Wawancara</label
                >
                <div class="mr-4 w-64">
                  <input
                    v-model="dataRekrutmenref['tgl_wawancara']"
                    type="date"
                    class="form-control focus:ring-0 mt-2"
                    autocomplete="off"
                  />
                </div>
                <div class="leading-relaxed text-gray-600 text-xs mt-2">
                  {{
                    moment(dataRekrutmenref["tgl_wawancara"]).format(
                      "Do MMMM, YYYY"
                    )
                  }}
                </div>
              </div>
              <div class="col-span-12 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Tanggal Tes</label
                >
                <div class="mr-4 w-64">
                  <input
                    v-model="dataRekrutmenref['tgl_tes']"
                    type="date"
                    class="form-control focus:ring-0 mt-2"
                    autocomplete="off"
                  />
                </div>
                <div class="leading-relaxed text-gray-600 text-xs mt-2">
                  {{
                    moment(dataRekrutmenref["tgl_tes"]).format("Do MMMM, YYYY")
                  }}
                </div>
              </div>
              <div class="col-span-12 mt-5 form-inline">
                <label class="form-label font-medium font-medium w-52 mt-2"
                  >Jenis SDM</label
                >
                <TailSelect
                  v-model="dataRekrutmenref['id_jns_sdm']"
                  :options="{
                    search: true,
                    classNames: 'form-control',
                    deselect: false,
                  }"
                >
                  <option
                    v-for="list in ref_jenis_sdm"
                    :key="list.value"
                    :value="list.value"
                    :selected="list.selected"
                  >
                    {{ list.text }}
                  </option>
                </TailSelect>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52">Penempatan / Unit</label>
                <input
                  v-model="dataRekrutmenref['penempatan']"
                  type="text"
                  class="form-control focus:ring-0 w-48"
                  placeholder="Penempatan"
                />
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52"
                  >Atasan Langsung</label
                >
                <input
                  v-model="dataRekrutmenref['atasan_langsung']"
                  type="text"
                  class="form-control focus:ring-0 w-48"
                  placeholder="Atasan Langsung"
                />
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52"
                  >Jabatan</label
                >
                <input
                  v-model="dataRekrutmenref['jabatan']"
                  type="text"
                  class="form-control focus:ring-0 w-48"
                  placeholder="Jabatan"
                />
              </div>
              <div class="col-span-12 mt-2 form-inline">
                <div
                  class="w-full border-t border-slate-200/60 dark:border-darkmode-400 mt-5"
                ></div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52">Nilai TPA</label>
                <input
                  v-model="dataRekrutmenref['nilai_tpa']"
                  type="number"
                  class="form-control focus:ring-0 w-48"
                  placeholder="Nilai TPA"
                />
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2">TPA</label>
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-1"
                      v-model="dataRekrutmenref['tes_tpa']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-1"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-2"
                      v-model="dataRekrutmenref['tes_tpa']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-2"
                      >Tidak Lulus</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Tes Wawancara</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-3"
                      v-model="dataRekrutmenref['tes_wawancara']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-3"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-4"
                      v-model="dataRekrutmenref['tes_wawancara']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-4"
                      >Tidak Lulus</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Micro Teaching (Dosen)</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-5"
                      v-model="dataRekrutmenref['micro_teaching']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-5"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-6"
                      v-model="dataRekrutmenref['micro_teaching']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-6"
                      >Tidak Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-61"
                      v-model="dataRekrutmenref['micro_teaching']"
                      class="form-check-input"
                      type="radio"
                      value="null"
                    />
                    <label class="form-check-label" for="radio-switch-61"
                      >Tidak Ada</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-6 form-inline">
                <label class="form-label font-medium w-52 mt-2"
                  >Tes Psikologi</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-8"
                      v-model="dataRekrutmenref['tes_psikologi']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-8"
                      >Lulus</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-9"
                      v-model="dataRekrutmenref['tes_psikologi']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-9"
                      >Tidak Lulus</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-2 form-inline">
                <div
                  class="w-full border-t border-slate-200/60 dark:border-darkmode-400 mt-5"
                ></div>
              </div>
              <div class="col-span-12 mt-5 form-inline">
                <label class="form-label font-medium font-medium w-52 mt-2"
                  >Status Rekrutmen</label
                >
                <div class="flex flex-col sm:flex-row mt-2">
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-10"
                      v-model="dataRekrutmenref['status_rekrutmen']"
                      class="form-check-input"
                      type="radio"
                      value="1"
                    />
                    <label class="form-check-label" for="radio-switch-10"
                      >Diterima</label
                    >
                  </div>
                  <div class="form-check mr-3">
                    <input
                      id="radio-switch-11"
                      v-model="dataRekrutmenref['status_rekrutmen']"
                      class="form-check-input"
                      type="radio"
                      value="0"
                    />
                    <label class="form-check-label" for="radio-switch-11"
                      >Ditolak</label
                    >
                  </div>
                </div>
              </div>
              <!--              <div class="col-span-12 mt-5 form-inline">-->
              <!--                <label class="form-label font-medium font-medium w-52 mt-2"-->
              <!--                  >Penempatan</label-->
              <!--                >-->
              <!--                <TailSelect-->
              <!--                  v-model="dataRekrutmenref['id_sms']"-->
              <!--                  :options="{-->
              <!--                    search: true,-->
              <!--                    classNames: 'form-control',-->
              <!--                    deselect: false,-->
              <!--                  }"-->
              <!--                >-->
              <!--                  <option-->
              <!--                    v-for="list in ref_sms"-->
              <!--                    :key="list.value"-->
              <!--                    :value="list.value"-->
              <!--                    :selected="list.selected"-->
              <!--                  >-->
              <!--                    {{ list.text }}-->
              <!--                  </option>-->
              <!--                </TailSelect>-->
              <!--              </div>-->
              <div class="col-span-12 mt-2 form-inline">
                <div
                  class="w-full border-t border-slate-200/60 dark:border-darkmode-400 mt-5"
                ></div>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium font-medium w-52"
                  >Tanggal Mulai Kerja</label
                >
                <div class="flex flex-col sm:flex-row">
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_mulai_kerja']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Mulai Bekerja ({{
                        moment(dataRekrutmenref["tgl_mulai_kerja"]).format(
                          "Do MMMM, YYYY"
                        )
                      }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-3 form-inline">
                <label class="form-label font-medium font-medium w-52"
                  >Masa Training</label
                >
                <div class="flex flex-col sm:flex-row">
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_mulai_training']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Mulai OJT ({{
                        moment(dataRekrutmenref["tgl_mulai_training"]).format(
                          "Do MMMM, YYYY"
                        )
                      }})
                    </div>
                  </div>
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_berakhir_training']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Berakhir OJT ({{
                        moment(
                          dataRekrutmenref["tgl_berakhir_training"]
                        ).format("Do MMMM, YYYY")
                      }})
                    </div>
                  </div>
                  <!--                  <div class="mr-4 w-64 mt-3">-->
                  <!--                    <button-->
                  <!--                      class="btn btn-warning w-full sm:w-auto mt-2"-->
                  <!--                      aria-expanded="false"-->
                  <!--                      type="button"-->
                  <!--                      @click="cetakKontrak"-->
                  <!--                    >-->
                  <!--                      <DownloadIcon class="w-4 h-4 mr-2" />-->
                  <!--                      {{ downloadKontrak }}-->
                  <!--                    </button>-->
                  <!--                  </div>-->
                </div>
              </div>

              <!--              <div-->
              <!--                class="col-span-12 mt-3 form-inline border-b border-slate-200/60 dark:border-darkmode-400 pb-4"-->
              <!--              >-->
              <!--                <label class="form-label font-medium w-52"-->
              <!--                  >Surat Kontrak Masa Percobaan</label-->
              <!--                >-->
              <!--                <div class="flex flex-col sm:flex-row">-->
              <!--                  <div class="mr-4">-->
              <!--                    <div-->
              <!--                      class="btn btn-outline-secondary relative cursor-pointer mr-3"-->
              <!--                    >-->
              <!--                      <LoadingIcon-->
              <!--                        v-if="onUploadProgress['masa_percobaan']"-->
              <!--                        icon="oval"-->
              <!--                        class="w-4 h-4 mr-2"-->
              <!--                      />-->
              <!--                      <FileIcon-->
              <!--                        v-else-if="!onUploadProgress['masa_percobaan']"-->
              <!--                        class="w-4 h-4 mr-2"-->
              <!--                      />-->
              <!--                      Upload File-->

              <!--                      <input-->
              <!--                        id="fileUpload1"-->
              <!--                        type="file"-->
              <!--                        class="inputfile"-->
              <!--                        @change="-->
              <!--                          onChangeFileSoftskill($event, 'masa_percobaan')-->
              <!--                        "-->
              <!--                      />-->
              <!--                    </div>-->
              <!--                    <div class="leading-relaxed text-gray-600 text-xs mt-1">-->
              <!--                      file extension: xls,xlsx,docs,pdf,jpg,bmp,png <br />-->
              <!--                      {{-->
              <!--                        onUploadProgress["masa_percobaan"]-->
              <!--                          ? "Uploading file..."-->
              <!--                          : ""-->
              <!--                      }}-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="mr-4">-->
              <!--                    <div-->
              <!--                      class="flex flex-col items-end w-full h-full align-middle mt-3 xl:mt-0 flex-1 xl:text-right"-->
              <!--                    >-->
              <!--                      <a-->
              <!--                        v-if="dataRekrutmenfile['masa_percobaan']"-->
              <!--                        class="btn btn-sm btn-primary w-56"-->
              <!--                        :href="-->
              <!--                          download_url.slice(0, download_url.length - 4) +-->
              <!--                          'uploads/sdm/' +-->
              <!--                          id +-->
              <!--                          '/' +-->
              <!--                          dataRekrutmenfile['masa_percobaan']-->
              <!--                        "-->
              <!--                        download-->
              <!--                      >-->
              <!--                        <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download CV-->
              <!--                      </a>-->
              <!--                      <button-->
              <!--                        v-else-->
              <!--                        class="btn btn-sm btn-secondary disabled cursor-not-allowed w-56"-->
              <!--                      >-->
              <!--                        <DownloadIcon class="mr-2 mr-2 w-4 h-4" /> Download CV-->
              <!--                      </button>-->
              <!--                      <div class="leading-relaxed text-gray-600 text-xs mt-2">-->
              <!--                        File:-->
              <!--                        {{-->
              <!--                          dataRekrutmenfile["masa_percobaan"]-->
              <!--                            ? dataRekrutmenfile["masa_percobaan"]-->
              <!--                            : "Not found!"-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-span-12 mt-3 form-inline">
                <label class="form-label font-medium font-medium w-52"
                  >Kontrak Ke-1</label
                >
                <div class="flex flex-col sm:flex-row">
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_mulai_kontrak_1']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Mulai Kontrak Ke-1 ({{
                        moment(dataRekrutmenref["tgl_mulai_kontrak_1"]).format(
                          "Do MMMM, YYYY"
                        )
                      }})
                    </div>
                  </div>
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_berakhir_kontrak_1']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Berakhir Kontrak Ke-1 ({{
                        moment(
                          dataRekrutmenref["tgl_berakhir_kontrak_1"]
                        ).format("Do MMMM, YYYY")
                      }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-3 form-inline">
                <label class="form-label font-medium font-medium w-52"
                  >Kontrak Ke-2</label
                >
                <div class="flex flex-col sm:flex-row">
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_mulai_kontrak_2']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Mulai Kontrak Ke-2 ({{
                        moment(dataRekrutmenref["tgl_mulai_kontrak_2"]).format(
                          "Do MMMM, YYYY"
                        )
                      }})
                    </div>
                  </div>
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_berakhir_kontrak_2']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Berakhir Kontrak Ke-2 ({{
                        moment(
                          dataRekrutmenref["tgl_berakhir_kontrak_2"]
                        ).format("Do MMMM, YYYY")
                      }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-3 form-inline">
                <label class="form-label font-medium font-medium w-52"
                  >Tanggal Pengangkatan Tetap</label
                >
                <div class="flex flex-col sm:flex-row">
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmenref['tgl_pengangkatan']"
                      type="date"
                      class="form-control focus:ring-0 mt-2"
                      autocomplete="off"
                    />
                    <div class="leading-relaxed text-gray-600 text-xs mt-2">
                      Tanggal Pengangkatan Tetap ({{
                        moment(dataRekrutmenref["tgl_pengangkatan"]).format(
                          "Do MMMM, YYYY"
                        )
                      }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-4 form-inline">
                <label class="form-label font-medium font-medium mt-5 w-52"
                  >NIK Pegawai</label
                >
                <div class="flex flex-col sm:flex-row">
                  <div v-if="!dataRekrutmen['KODE']" class="mr-4 w-52 mt-5">
                    <TailSelect
                      v-model="dataRekrutmenref['tenaga_pendidik']"
                      :options="{
                        search: true,
                        classNames: 'form-control',
                        deselect: false,
                      }"
                    >
                      <option disabled selected>Pilih Posisi</option>
                      <option value="1">Dosen Tetap</option>
                      <option value="2">Dosen LB</option>
                      <option value="3">Tenaga Pendidik (Karyawan)</option>
                    </TailSelect>
                  </div>
                  <div v-else class="mr-4 w-52 mt-3">
                    <input
                      type="text"
                      class="form-control focus:ring-0 mt-2 border-0 bg-opacity-0 border-0"
                      placeholder="NIK"
                      readonly="readonly"
                      :value="
                        $h.statTendik(dataRekrutmenref['tenaga_pendidik'])
                      "
                    />
                  </div>
                  <div class="mr-4 w-64 mt-3">
                    <input
                      v-model="dataRekrutmen['KODE']"
                      type="text"
                      class="form-control focus:ring-0 mt-2 border-0 bg-opacity-0 border-0"
                      placeholder="NIK"
                      readonly="readonly"
                    />
                  </div>
                  <div class="leading-relaxed text-green-800 text-xs mt-7">
                    {{ nikcreated }}
                  </div>
                  <div
                    v-if="!dataRekrutmen['KODE']"
                    class="mr-4 w-64 mt-3 w-1/2 sm:w-auto"
                  >
                    <button
                      type="button"
                      class="btn btn-primary mt-2 w-full sm:w-auto"
                      @click="generateNik()"
                    >
                      <RepeatIcon class="mr-2 mr-2 w-4 h-4" /> BUAT NIK
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-2 form-inline">
                <div
                  class="w-full border-t border-slate-200/60 dark:border-darkmode-400 mt-5"
                ></div>
              </div>
            </div>
          </div>

          <div class="flex justify-end flex-col md:flex-row gap-2 mt-5">
            <button
              type="button"
              class="btn py-3 border-slate-300 dark:border-darkmode-400 text-slate-500 w-full md:w-52"
              @click="getDetailRekrutmen()"
            >
              Reset</button
            ><button type="submit" class="btn py-3 btn-primary w-full md:w-52">
              <SaveIcon class="w-4 h-4 mr-2" /> Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import { sdm } from "@/services/models";
import { ref } from "@/services/models";
// import { userdata, getuserdata } from "@/utils/userdata";
import moment from "moment";
import LoadingSpinner from "@/components/loading-spinner/Main";

export default {
  name: "FormTeswawancara",
  components: {
    AlertSuccess,
    AlertFailed,
    LoadingSpinner,
  },
  data() {
    return {
      id: this.$route.params.id,
      idrekrutmenref: null,
      // detailDataRekrutmen: [],
      dataRekrutmen: {
        KODE: null,
      },
      dataRekrutmenref: {
        tgl_wawancara: null,
        tgl_tes: null,
        tenaga_pendidik: null,
        penempatan: null,
        atasan_langsung: null,
        jabatan: null,
        id_rekrutmen: null,
        nilai_tpa: null,
        tes_tpa: null,
        tes_wawancara: null,
        micro_teaching: null,
        tes_psikologi: null,
        status_rekrutmen: null,
        id_sms: null,
        tgl_mulai_training: null,
        tgl_berakhir_training: null,
        tgl_mulai_kontrak_1: null,
        tgl_berakhir_kontrak_1: null,
        tgl_mulai_kontrak_2: null,
        tgl_berakhir_kontrak_2: null,
        tgl_mulai_kerja: null,
      },
      dataRekrutmenfile: {
        masa_percobaan: null,
        kontrak_1: null,
        kontrak_2: null,
      },
      file: "",
      // namaFile: "",
      onUploadProgress: {
        masa_percobaan: null,
        kontrak_1: null,
        kontrak_2: null,
      },
      ref_sms: [],
      ref_jenis_sdm: [],
      nikcreated: "",
      downloadKontrak: "Download Kontrak Kerja",
      alertSuccess: false,
      alertFailed: false,
      messageAlert: [],
      isLoading: false,
      useState: false,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    "dataRekrutmenref.tgl_mulai_training": {
      handler: function () {
        if (this.dataRekrutmenref.tgl_mulai_training !== null) {
          let d = new Date(this.dataRekrutmenref.tgl_mulai_training);
          d.setMonth(d.getMonth() + 3);
          if (d !== "Invalid Date" && !isNaN(d)) {
            this.dataRekrutmenref.tgl_berakhir_training = d
              .toISOString()
              .slice(0, 10);
          } else {
            this.dataRekrutmenref.tgl_berakhir_training = "";
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    moment.locale("id");
    this.getDetailRekrutmen();
    this.getSms();
    this.getJenisSdm();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getDetailRekrutmen() {
      this.dataRekrutmenref.id_rekrutmen = this.id;

      if (this.id?.length > 0) {
        this.isLoading = true;
        sdm.Rekrutmen.getCustomPath(`getDetailRekrutmen/${this.id}`)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.dataRekrutmen.KODE = res.data.data.KODE;

                this.idrekrutmenref =
                  res.data.data?.sdm_rekrutmen_ref.id_rekrutmen_ref;
                this.dataRekrutmenref = res.data.data?.sdm_rekrutmen_ref;

                if (res.data.data?.sdm_rekrutmen_file !== null) {
                  this.dataRekrutmenfile = res.data.data?.sdm_rekrutmen_file;
                }

                this.$emit("detailDataRekrutmen", res.data.data);
              }
            }
          })
          .finally(() => {
            this.nikcreated = "";
            this.isLoading = false;
            this.scrollToTop();
          });
      }
    },
    onChangeFileSoftskill(event, category) {
      const fd = new FormData();
      fd.append("file_category", category);
      fd.append("file", event.target.files[0]);
      fd.append("id_rekrutmen", this.id);

      // if (event) {
      //   this.namaFile = event.target.files[0].name;
      // } else {
      //   this.namaFile = "";
      // }

      this.alertFailed = false;
      this.messageAlert = [];

      this.onUploadProgress[category] = true;

      sdm.Rekrutmen.postCustomPath("kirimFile", fd)
        .then((res) => {
          if (res.data.code === 200) {
            // this.namaFile = "";
            this.file = res.data.fileName;
            // this.showImportFileSoftSkill = false;
            // this.download_url[category] =
            //   process.env.VUE_APP_API.slice(
            //     0,
            //     process.env.VUE_APP_API.length - 4
            //   ) +
            //   "uploads/sdm/" +
            //   this.id +
            //   "/" +
            //   this.file;
          } else {
            this.alertFailed = true;
            Object.keys(res.data.message).forEach((key) => {
              this.messageAlert.push(res.data.message[key][0]);
            });
            // console.log(res.data.message.file);
            this.scrollToTop();
          }
        })
        .finally(() => {
          this.getDetailRekrutmen();

          if (this.onUploadProgress[category] == true)
            return (this.onUploadProgress[category] = false);
        });
    },
    async getSms() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Program Studi",
        selected: true,
      };
      this.ref_sms.push(defaultOpt);
      sdm.MasterProdi.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_sms.push({
              id: element.id,
              value: element.id_sms,
              text:
                element.nama +
                " " +
                element.jenjang +
                " (" +
                element.induk.nama_alias +
                ")",
              selected: false,
            });
          });
        }
      });
    },
    async getJenisSdm() {
      let defaultOpt = {
        id: 0,
        value: "",
        text: "Pilih Jenis SDM",
        selected: true,
      };
      this.ref_jenis_sdm.push(defaultOpt);
      ref.jenissdm.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_jenis_sdm.push({
              id: element.id,
              value: element.id_jns_sdm,
              text: element.nm_jns_sdm,
              selected: false,
            });
          });
        }
      });
    },
    generateNik() {
      sdm.RekrutmenRef.getCustomPath(
        `generateNik/${this.dataRekrutmenref["tenaga_pendidik"]}`
      ).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            this.dataRekrutmen["KODE"] = res.data.result;
            this.nikcreated = "NIK Pegawai berhasil dibuat!";
          }
        }
      });
    },
    cetakKontrak() {
      this.downloadKontrak = "Tunggu Sebentar...";
      let postData = {
        id_rekrutmen: this.dataRekrutmenref.id_rekrutmen,
      };
      sdm.RekrutmenRef.postCustomPathBlob("generateKontrak", postData).then(
        (res) => {
          // console.log(res);
          let blobFile = new Blob([res.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blobFile);
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `${this.kodeMK}-${this.kodeDosen}-${this.kelas}.pdf`
          );
          document.body.appendChild(fileLink);

          this.downloadKontrak = "Download Kontrak Kerja";

          fileLink.click();
          // window.open(fileURL);
        }
      );
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = [];
      this.isLoading = true;

      if (this.idrekrutmenref?.length > 0) {
        // let formdata = {
        //   ...this.dataRekrutmen.KODE,
        //   ...this.dataRekrutmenref,
        // };

        this.dataRekrutmenref.KODE = this.dataRekrutmen.KODE;

        sdm.RekrutmenRef.updateData(
          this.idrekrutmenref,
          this.dataRekrutmenref
        ).then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.alertSuccess = true;
              this.messageAlert = res.data.message;
            } else {
              this.alertFailed = true;
              Object.keys(res.data.message).forEach((key) => {
                this.messageAlert.push(res.data.message[key][0]);
              });
            }
          }
          this.isLoading = false;
          this.scrollToTop();
        });
      }
      // else {
      //   sdm.RekrutmenRef.insertData(this.dataRekrutmenref).then((res) => {
      //     if (res.status === 200) {
      //       if (res.data.code === 200) {
      //         this.alertSuccess = true;
      //         this.messageAlert = res.data.message;
      //       } else {
      //         this.alertFailed = true;
      //         Object.keys(res.data.message).forEach((key) => {
      //           this.messageAlert.push(res.data.message[key][0]);
      //         });
      //       }
      //     }
      //     this.isLoading = false;
      //     this.scrollToTop();
      //   });
      // }
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
