<template>
  <div
    v-if="message?.length > 0"
    :class="alertclass"
    class="alert alert-success show flex items-center mb-4"
    role="alert"
  >
    <CheckCircleIcon class="w-6 h-6 mr-2" />
    {{ message }}
    <button
      v-if="dismissable"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="closeAndClear"
    >
      <XIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
export default {
  name: "AlertSuccess",
  props: {
    message: {
      type: String,
      default: "Successfully ...",
    },
    alertstyle: {
      type: String,
      default: "alert-success", //alert-outline-success | alert-success-soft
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alertclass() {
      return this.dismissable === true
        ? this.alertstyle + " alert-dismissible"
        : this.alertstyle;
    },
  },
  watch: {
    message: {
      handler: function () {},
      immediate: true,
    },
  },
  methods: {
    closeAndClear() {
      this.$emit("closeAndClear", null);
    },
  },
};
</script>
