<template>
  <MainLayout :show-sync="true" :side-title="'Tenaga Pendidik (Dosen)'">
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Dosen</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <div class="form-check mr-5">
            <input
              id="vertical-form-3"
              v-model="is_aktif"
              class="form-check-input bg-white"
              type="checkbox"
              @change="getItems(is_aktif ? 2 : 1)"
            />
            <label class="form-check-label" for="vertical-form-3"
              >Tampilkan Semua Status Keaktifan</label
            >
          </div>

          <router-link
            :to="{ name: 'create-recruitment' }"
            class="btn btn-primary shadow-md mr-2"
            >Rekrutmen Baru</router-link
          >

          <button class="ml-3 mr-1" @click="getItems(is_aktif)">
            <RefreshCcwIcon class="w-4 mr-2" /> Reload data
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form
            id="tabulator-html-filter-form"
            class="xl:flex sm:mr-auto"
            @submit.prevent="onFilter"
          >
            <div class="sm:flex items-center sm:mr-4">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Field</label
              >
              <select
                id="tabulator-html-filter-field"
                v-model="filter.field"
                class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="NM_DOSEN">NAMA</option>
                <option value="KODE">NIK DOSEN</option>
                <option value="NIDN">NIDN</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
            </div>
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                >Value</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="filter.value"
                type="text"
                class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                type="button"
                class="btn btn-primary w-full sm:w-16"
                @click="onFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </form>
          <div class="flex mt-5 sm:mt-0">
            <a
              id="tabulator-export-xlsx"
              href="javascript:"
              class="btn btn-outline-secondary w-full sm:w-auto flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              @click="onExportXlsx"
            >
              <FileTextIcon class="w-4 h-4 mr-2" />
              {{ !downloading ? "Export XLSX" : "Downloading..." }}
            </a>
          </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

    <!-- BEGIN: Modal Content -->
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="deleteRow"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->

    <template #rightside>
      <div class="grid grid-cols-12 gap-2 mt-3">
        <div
          class="col-span-4 md:col-span-12 bg-white dark:bg-gray-800 rounded-md p-5"
        >
          <ListDosenChart
            :active-employee="dataInfo.active"
            :non-active-employee="dataInfo.nonActive"
          ></ListDosenChart>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import feather from "feather-icons";
import { useStore } from "vuex";
import router from "@/router";
import { tblTabulator } from "@/utils/tabulator";
import { sdm } from "@/services/models";
import MainLayout from "@/layouts/main/MainLayout";
import ListDosenChart from "@/views/dosen/components/listDosenChart.vue";

export default defineComponent({
  components: {
    MainLayout,
    ListDosenChart,
  },
  setup() {
    let deletingRow = ref();
    const idData = ref();
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const filter = reactive({
      field: "NM_DOSEN",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];
    const editRow = (e, cell) => {
      idData.value = cell.getData().id;
      router.push({ name: "edit-dosen", params: { id: idData.value } });
    };
    const deleteRow = (e, cell) => {
      //e - the click event object
      cash("#delete-modal").modal("show");
      deletingRow.value = cell.getRow();
      idData.value = cell.getData().id;
    };
    /* const dataSDM = (e, cell) => {
      idData.value = cell.getData().id_sdm;
      router.push({ name: "edit-sdm", params: { id: idData.value } });
    };*/
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "NIK Dosen",
          field: "KODE",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: false,
        },

        {
          title: "Nama",
          field: "NM_DOSEN",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: false,
        },
        // For HTML table
        {
          title: "NIDN",
          field: "NIDN",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: false,
        },
        {
          title: "Jenis SDM",
          width: 150,
          field: "STATUS_DOSEN",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().STATUS_DOSEN == "1") {
              return `<div class="text-purple-700 mr-1">Dosen Tetap</div>`;
            } else if (cell.getData().STATUS_DOSEN == "2") {
              return `<div class="text-blue-900 mr-1">Dosen LB</div>`;
            } else if (cell.getData().STATUS_DOSEN == "3") {
              return `<div class="text-blue-500 mr-1">Tendik</div>`;
            } else {
              return "-";
            }
          },
        },
        {
          title: "Status Keaktifan",
          field: "Aktif",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: false,
          formatter(cell) {
            if (cell.getData().Aktif === "Y") {
              return `<div class="flex items-center lg:justify-center text-green-500">
                            <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Aktif
                        </div>`;
            } else {
              return `<div class="flex items-center lg:justify-center text-red-500">
                            <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Tidak Aktif
                        </div>`;
            }
          },
        },
        {
          title: "Jabatan Fungsional",
          field: "jab_fung",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: false,
          formatter(cell) {
            if (cell.getData().jab_fung === null) {
              return `<div>
                <i data-feather="x-square" class="w-4 h-4 mr-1"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-nowrap">Tidak Terhubung</div>
              </div>`;
            } else {
              let jab_fung = cell.getData().jab_fung;
              let lbljabfung = "";
              let tmt_sk_jabfung = new Date(
                Math.max.apply(
                  null,
                  jab_fung.map(function (e) {
                    return new Date(e.tmt_sk_jabfung);
                  })
                )
              );

              jab_fung.forEach((jabfung) => {
                if (
                  tmt_sk_jabfung.toISOString().split("T")[0] ==
                  jabfung.tmt_sk_jabfung
                ) {
                  lbljabfung +=
                    jabfung?.jabfung?.nm_jabfung +
                    " (" +
                    jabfung?.jabfung.angka_kredit +
                    ")";
                }
              });
              return `<div class="flex items-center lg:justify-center">${lbljabfung}</div>`;
            }
          },
        },
        {
          title: "PTK (PDDikti)",
          field: "NM_DOSEN",
          headerHozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: false,
          headerSort: false,
          formatter(cell) {
            if (cell.getData().id_sdm === null) {
              return `<div>
                <i data-feather="x-square" class="w-4 h-4 mr-1"></i>
                </div>
                <div class="text-gray-600 text-xs whitespace-nowrap">Tidak Terhubung</div>
              </div>`;
            } else {
              let reg_ptk = cell.getData().regptk;
              let lblptk = "";
              reg_ptk.forEach((ptk) => {
                lblptk +=
                  '<div class="text-gray-500 text-xs whitespace-normal mt-2">' +
                  ptk?.ikatan_kerja_sdm?.nm_ikatan_kerja +
                  " " +
                  ptk?.status_kepegawaian.nm_stat_pegawai;
                ("</div>");
                let prodi =
                  ptk.id_sms !== null
                    ? ptk?.sms?.nm_lemb +
                      " (" +
                      ptk?.sms?.jenjang_pendidikan?.nm_jenj_didik +
                      ") "
                    : "";
                lblptk +=
                  '<div class="text-gray-600 text-md whitespace-normal">' +
                  prodi +
                  ptk?.satuan_pendidikan?.nm_lemb +
                  " ";
                ("</div>");
              });
              return `<div>
                <div class="text-blue-600 text-xs whitespace-normal">PTK ${
                  cell.getData()?.sdm?.status_keaktifan_pegawai?.nm_stat_aktif
                }</div>
                <div class="font-medium whitespace-nowrap">
                  <span class="text-gray-600"> ${lblptk}</span>
                </div>
                </div>
              </div>`;
            }
          },
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 85,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex btn btn-sm btn-success w-18 items-center" href="javascript:;">
                  <i data-feather="archive" class="w-4 h-4 mr-1"></i> Open
                </a>
              </div>`);
            return a[0];
          },
          cellClick: editRow,
        },
        {
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          width: 40,
          formatter() {
            const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center text-theme-6" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
              </div>`);
            return a[0];
          },
          cellClick: deleteRow,
        },
      ];

      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        sdm.Dosen.endpoint + "?is_aktif=1",
        columns
      );
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };

    // On reset filter
    const onResetFilter = () => {
      filter.field = "NM_DOSEN";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };
    // Export
    var downloading = ref(0);

    const onExportXlsx = () => {
      downloading.value = 1;

      let postData = {
        // id_rekrutmen: this.dataRekrutmenref.id_rekrutmen,
        jenis_surat: "",
        upah_masa_percobaan: "",
      };
      sdm.Dosen.postCustomPathBlob("export_dosen", postData)
        .then((res) => {
          let blobFile = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          var fileURL = URL.createObjectURL(blobFile);
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Dosen.xlsx`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .finally(() => {
          downloading.value = 0;
        });
    };

    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });

    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      // onExportCsv,
      // onExportJson,
      downloading,
      onExportXlsx,
      // onExportHtml,
      // onPrint,
      accessToken,
      deletingRow,
      idData,
    };
  },
  data() {
    return {
      syncOldDataConfig: process.env?.VUE_APP_SYNC_DATA_LAMA,
      deleting: false,
      editing: false,
      dataTable: null,
      dataInfo: [],
      is_aktif: null,
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
    deletingStatus() {
      return this.deleting;
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    resetFilter() {
      this.filterData?.prodi.data.splice(0);
    },
    getItems(is_aktif) {
      this.deleting = false;
      this.editing = false;
      this.tabulator.setData(
        sdm.Dosen.endpoint,
        { is_aktif: is_aktif ? is_aktif : 1 },
        tblTabulator.ajaxConfig()
      );
    },
    deleteRow() {
      sdm.Dosen.deleteData(this.idData).then((res) => {
        if (res.status === 200) {
          this.getItems();
          cash("#delete-modal").modal("hide");
        }
      });
    },
    getInfo() {
      sdm.Dosen.getCustomPath("getInfoDosen").then((res) => {
        this.dataInfo = res.data.data;
      });
    },
  },
});
</script>
